import { PayloadAction } from "@reduxjs/toolkit";
import { IDivisionState } from "./division.types";

function setDivisionState(
  state: IDivisionState,
  { payload: newState }: PayloadAction<IDivisionState>
) {
  return newState;
}

function setFetched(
  state: IDivisionState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const divisionReducers = {
  setDivisionState,
  setFetched
};
