import React from 'react';
import './MobileDivisionItem.css';

export const MobileDivisionItem = ({type, content}) => {
  return (
    <>
      <div className='m-division-item'>
        <div className='m-division-bg' style={{left: type === 'left' ? '-14vw' : '', right: type === 'right' ? '-14vw' : ''}}></div>
        <div className='m-division-image'>
          <img src={content.iconSrc}/>
        </div>
        <div className='m-division-text'>
          <p>{content.text}</p>
        </div>
      </div>
    </>
  )
}
