import { IServicesState } from "./Services.types.ts";

export const ServicesInitialState: IServicesState = {
  servicePageDescription: {
    tag: "",
    title_color: "",
    title_dark: "",
    contact: "",
  },
  serviceDescriptions: {
    auto: {
      name: "",
      title: "",
      text: "",
    },
    air: {
      name: "",
      title: "",
      text: "",
    },
    ship: {
      name: "",
      title: "",
      text: "",
    },
    rail: {
      name: "",
      title: "",
      text: "",
    },
    multi: {
      name: "",
      title: "",
      text: "",
    },
    projects: {
      name: "",
      title: "",
      text: "",
    },
    customs: {
      name: "",
      title: "",
      text: "",
    },
    warehouse: {
      name: "",
      title: "",
      text: "",
    },
  },
  isShowFeedback: false
};
