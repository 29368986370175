import {React, useRef} from 'react';
import './AdminLogin.css';

export const AdminLogin = ({login}) => {
    const pass = useRef();


  return (
    <div className='a-login'>
        <div>
            <input type='password' ref={pass} onKeyDown={({keyCode})=>{if (keyCode === 13) login(pass.current.value)}}/>
            <button onClick={() => {login(pass.current.value)}}>Войти</button>
        </div>
    </div>

  )
}
