import React from 'react';
import './MobileContainer.css';

export const MobileContainer = ({children}) => {

  return (
    <>
    <div className='m-container-padding'></div>
    <div className='m-container' id='container'>{children}</div>
    </>
  )
}
