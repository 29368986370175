import {React, useState} from 'react'
import { AdminMain } from './AdminMain'
import './AdminPanel.css'
import { AdminKey } from './AdminKey';
import { AdminTeam } from './AdminTeam';
import { AdminAreas } from './AdminAreas';
import { AdminGeo } from './AdminGeo';
import { AdminProjects } from './AdminProjects';
import { AdminClearance } from './AdminClearance';
import { AdminAgency } from './AdminAgency';
import { AdminDivision } from './AdminDivision';
import { AdminChina } from './AdminChina';
import { AdminContacts } from './AdminContacts';
import { AdminEmail } from './AdminEmail';
import { AdminLogin } from './AdminLogin';
import { AdminAcc } from './AdminAcc';
import { AdminHeader } from './AdminHeader';
import { AdminFeedbackModal } from './AdminFeedbackModal';
import axios from 'axios';
import { AdminImages } from './AdminImages';

export const AdminPanel = () => { 

  const loginFunc = (pass) => {
    
    
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/login?pass=${pass}`).then((res) => {
        if (res.data === true) setLogin(true)
        else setLogin(false)
    });


    // if (pass === '123') {
    //     setSelected('main');
    //     setLogin(true);
    // } else {
    //     setLogin(false);
    // }
  }

  const logout = () => {
    setLogin(false);
  }

  const [selected, setSelected] = useState('main');

    const [login, setLogin] = useState(false);
  return (
    <div className='admin'>
        {!login && <AdminLogin login={loginFunc}/>}
        {login && <div className="admin-layout">
            <div className="admin-menu">
                <div className="admin-menu-list">
                    <div className="admin-header">
                    </div>
                    <div className="admin-title">
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'main' ? '#777777' : ''}} onClick={() => {setSelected('main')}}>
                        <p>Главная</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'key' ? '#777777' : ''}} onClick={() => {setSelected('key')}}>
                        <p>Ключевые услуги</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'team' ? '#777777' : ''}} onClick={() => {setSelected('team')}}>
                        <p>Наша команда</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'areas' ? '#777777' : ''}} onClick={() => {setSelected('areas')}}>
                        <p>Отраслевая направленность</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'geo' ? '#777777' : ''}} onClick={() => {setSelected('geo')}}>
                        <p>География деятельности</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'images' ? '#777777' : ''}} onClick={() => {setSelected('images')}}>
                        <p>Изображения</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'projects' ? '#777777' : ''}} onClick={() => {setSelected('projects')}}>
                        <p>Наши проекты</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'clearance' ? '#777777' : ''}} onClick={() => {setSelected('clearance')}}>
                        <p>Таможенное оформление</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'agency' ? '#777777' : ''}} onClick={() => {setSelected('agency')}}>
                        <p>Торговое агентирование</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'division' ? '#777777' : ''}} onClick={() => {setSelected('division')}}>
                        <p>Обособленное подразделение</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'china' ? '#777777' : ''}} onClick={() => {setSelected('china')}}>
                        <p>Компания в Китае</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'contacts' ? '#777777' : ''}} onClick={() => {setSelected('contacts')}}>
                        <p>Наши Контакты</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'header' ? '#777777' : ''}} onClick={() => {setSelected('header')}}>
                        <p>Шапка сайта</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'feedbackModal' ? '#777777' : ''}} onClick={() => {setSelected('feedbackModal')}}>
                        <p>Окна обратной связи</p>
                    </div>
                    <div className="admin-title">
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'acc' ? '#777777' : ''}} onClick={() => {setSelected('acc')}}>
                        <p>Аккаунт</p>
                    </div>
                    <div className="admin-item" style={{backgroundColor: selected === 'email' ? '#777777' : ''}} onClick={() => {setSelected('email')}}>
                        <p>Почтовый сервер</p>
                    </div>
                    <div className="admin-title">
                        
                    </div>
                    <div className="admin-item" onClick={logout}>
                        <p>Выйти</p>
                    </div>
                    <div className="admin-title">
                        
                    </div>
                </div>
            </div>
            <div className="admin-content">
                { selected === 'main' && <AdminMain/>}
                { selected === 'key' && <AdminKey/>}
                { selected === 'team' && <AdminTeam/>}
                { selected === 'areas' && <AdminAreas/>}
                { selected === 'geo' && <AdminGeo/>}
                { selected === 'images' && <AdminImages/>}
                { selected === 'projects' && <AdminProjects/>}
                { selected === 'clearance' && <AdminClearance/>}
                { selected === 'agency' && <AdminAgency/>}
                { selected === 'division' && <AdminDivision/>}
                { selected === 'china' && <AdminChina/>}
                { selected === 'contacts' && <AdminContacts/>}
                { selected === 'acc' && <AdminAcc/>}
                { selected === 'header' && <AdminHeader/>}
                { selected === 'feedbackModal' && <AdminFeedbackModal/>}
                { selected === 'email' && <AdminEmail/>}
            </div>
        </div>}

    </div>
  )
}
