import React, { useEffect, useMemo, useState } from 'react';
import { MobileTitle } from '../Title/MobileTitle';
import './MobileDivision.css';
import axios from 'axios';
import { MobileDivisionItem } from './MobileDivisionItem';

export const MobileDivision = () => {
  const [DBInfo, setDBInfo] = useState({});

  useEffect(()=>{
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Division`)
    .then(res => {
      const info = {};
      if (!res?.data?.[0]) return;

      Object.keys(res?.data?.[0]).forEach(el=>{
        if (el === '_id') return;
        info[el] = res.data[0][el];
      })
      setDBInfo(info);
    })

  },[])

  const items = useMemo(()=>{
    return [
      {iconSrc: 'Mobile/Division/icon_1.svg',title:DBInfo.title_1,text:DBInfo.description_1},
      {iconSrc: 'Mobile/Division/icon_2.svg',title:DBInfo.title_2,text:DBInfo.description_2},
      {iconSrc: 'Mobile/Division/icon_3.svg',title:DBInfo.title_3,text:DBInfo.description_3},
      {iconSrc: 'Mobile/Division/icon_4.svg',title:DBInfo.title_4,text:DBInfo.description_4},
    ]
  },[DBInfo])

  return (
    <>
    <div className='m-division-content'>
      <img className='m-division-bg-img' src='Mobile/Division/bg.svg'/>
      <MobileTitle type='three_lines' first={{text: 'ОБОСОБЛЕННОЕ', color: '#62929E'}} second={{text: 'ПОДРАЗДЕЛЕНИЕ', color: '#49525e'}} third={{text: 'На Границе С Китаем', color: "#ADBFCC"}}/>
      
      <div className='m-division-items'>
        <MobileDivisionItem type='right' content={items[0]}/>
        <MobileDivisionItem type='left' content={items[1]}/>
        <MobileDivisionItem type='right' content={items[2]}/>
        <MobileDivisionItem type='left' content={items[3]}/>
      </div>
    </div>
    </>
  )
}
