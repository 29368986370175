import { PayloadAction } from "@reduxjs/toolkit";
import {
  IServiceDescriptions,
  IServicePageDescription,
  IServicesState,
} from "./Services.types";

function setServicePageDescription(
  state: IServicesState,
  { payload: servicePageDescription }: PayloadAction<IServicePageDescription>
) {
  state.servicePageDescription = servicePageDescription;
}
function setServiceDescriptions(
  state: IServicesState,
  { payload: serviceDescriptions }: PayloadAction<IServiceDescriptions>
) {
  state.serviceDescriptions = serviceDescriptions;
}
function setIsShowFeedback(
  state: IServicesState,
  { payload: isShowFeedback }: PayloadAction<boolean>
) {
  state.isShowFeedback = isShowFeedback;
}

function setFetched(
  state: IServicesState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const ServicesReducers = {
  setServicePageDescription,
  setServiceDescriptions,
  setIsShowFeedback,
  setFetched,
};
