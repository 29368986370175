import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import { IWarningState } from "../state/warning.types.js";
import { warningActions } from "../state/warning.slice.js";
import { DBResponse } from "../../../../store/store.types.js";

export const warningExtraReducers = {
};

export const warningThunksCallbacksAsync = {
};
