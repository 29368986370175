import React, { useEffect, useMemo, useState } from 'react';
import './MobileProjects.css';
import { MobileTitle } from '../Title/MobileTitle';
import { MobileProjectsItem } from './MobileProjectsItem';
import axios from 'axios';
import { MobileModal } from '../Modal/MobileModal';
import { MobileFeedback } from '../MobileFeedback/MobileFeedback';
import { MobileProject } from './MobileProject';

export const MobileProjects = () => {

  const [DBInfo, setDBInfo] = useState({});

  useEffect(()=>{
    
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Projects`)
    .then(res => {
      const info = {};
      // console.log(res?.data?.[0])
      if (!res?.data?.[0]) return;

      Object.keys(res?.data?.[0]).forEach(el=>{
        if (el === '_id') return;
        info[el] = res.data[0][el];
      })
      setDBInfo(info);
    })

  },[])

  const projects = useMemo(()=>{
    return DBInfo.projects || [];
  }, [DBInfo])
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowProjects, setIsShowProjects] = useState(false);

  const openProject = (title, description) => {
    setIsShowModal(true);
    setProjectInfo({
      title, description
    })
  }
  const [projectInfo, setProjectInfo] = useState({});

  return (
    <>
    <div className='m-projects'>
        <img className='m-projects-bg' src='Mobile/Projects/bg.svg'/>
        <MobileTitle first={{text: 'НАШИ', color: '#fff'}} second={{text: 'ПРОЕКТЫ', color: '#49525e'}}/>
        {projects.length === 0 ? <p className='m-projects-loading'>Проекты загружаются...</p> : <></>}
        <div className='m-projects-items'>
          {projects.map((project,i)=>(<MobileProjectsItem key={project.title} content={project} openText={DBInfo.openText} openProject={()=>{openProject(project.title, project.description)}}/>))}
          <div className='m-projects-fader'></div>
        </div>
        <div className='m-projects-footer'>
          <div className='m-projects-button' onClick={()=>{setIsShowProjects(true)}}>
            {DBInfo.buttonText ?? 'Показать Все'}
          </div>
        </div>
    </div>
    {isShowProjects && <MobileModal>
      <div className='m-projects-all'>
        {projects.map((project,i)=>(<MobileProjectsItem key={project.title} content={project} openText={DBInfo.openText} openProject={()=>{openProject(project.title, project.description)}}/>))}
        <img className="m-projects-close" src="close.svg" alt="X" onClick={()=>{setIsShowProjects(false)}}/>
      </div>
    </MobileModal>}
    {isShowModal && <MobileModal>
      <MobileProject toggleProject={()=>{setIsShowModal(false)}} projectTitle={projectInfo.title} projectContent={projectInfo.description}/>
    </MobileModal>}
    </>
  )
}
