import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminEmail.css";
import { LamaText } from "../components/Inputs/LamaText";
import { AdminContentPage } from "./AdminContentPage";

export const AdminEmail = () => {
  const host = useRef();
  const port = useRef();
  const user = useRef();
  const pass = useRef();
  const from = useRef();


  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Email`).then((res) => {
      host.current.value = res?.data?.[0]?.host;
      port.current.value = res?.data?.[0]?.port;
      user.current.value = res?.data?.[0]?.user;
      pass.current.value = res?.data?.[0]?.pass;
      from.current.value = res?.data?.[0]?.from;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Email",
        content: JSON.stringify({
          host: host.current.value,
          port: port.current.value,
          user: user.current.value,
          pass: pass.current.value,
          from: from.current.value,
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  return (
    <AdminContentPage
      title="Настройка почтового сервера"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={host} l_title={"host"} />
      <LamaText l_ref={port} l_title={"port"} />
      <LamaText l_ref={user} l_title={"user"} />
      <LamaText l_ref={pass} l_title={"pass"} l_type={"password"}/>
      <LamaText l_ref={from} l_title={"from"} />

    </AdminContentPage>
  );
};