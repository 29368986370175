import Lottie from 'lottie-react';
import React, { useRef } from 'react';
import './MobileCustomsItem.css';

export const MobileCustomsItem = ({content, animData}) => {
  const anim = useRef();

  return (
    <div className='m-customs-item-card'>
    <div className='m-customs-item-icon'>
      <Lottie lottieRef={anim} autoplay={true} loop={true} style={{width: '60vw'}} animationData={animData}/>
    </div>
    <div className='m-customs-item-main'>
      <p className='m-customs-item-subtitle'>{content.title}</p>
      <p className='m-customs-item-subtitle-second'>{content.subtitle}</p>
    </div>
    <div className='m-customs-item-sub'>
      <p>{content?.description?.slice(0,120)}</p>
    </div>
  </div>
  )
}
