import { PayloadAction } from "@reduxjs/toolkit";
import { IContactsState } from "./contacts.types";

function setContactsState(
  state: IContactsState,
  { payload: newState }: PayloadAction<IContactsState>
) {
  return newState;
}

function setFetched(
  state: IContactsState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const contactsReducers = {
  setContactsState,
  setFetched
};
