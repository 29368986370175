import Lottie from "lottie-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./MobileKeyServices.css";
import { MobileKeyServicesItem } from "./MobileKeyServicesItem";
import axios from "axios";
import { MobileTitle } from "../Title/MobileTitle";
import { MobileModal } from "../Modal/MobileModal";
import { MobileFeedback } from "../MobileFeedback/MobileFeedback";
import animationDataTrain from "./services-view-train.json";

export const MobileKeyServices = () => {
  const lottieRefTrain = useRef();
  const styleTrain = { zIndex: "1500", width: "200vw", maxWidth: "1000px" };
  const [DBInfo, setDBInfo] = useState({});

  useEffect(() => {
    axios
      .get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Service`)
      .then((res) => {
        const info = {};
        // console.log(res?.data?.[0])
        if (!res?.data?.[0]) return;

        Object.keys(res?.data?.[0]).forEach((el) => {
          if (el === "_id") return;
          info[el] = res.data[0][el];
        });

        // console.log({info})
        setDBInfo(info);
      });
  }, []);

  const services = useMemo(() => {
    return [
      {
        name: DBInfo.name_1,
        title: DBInfo.title_1,
        text: DBInfo.description_1,
      },
      {
        name: DBInfo.name_2,
        title: DBInfo.title_2,
        text: DBInfo.description_2,
      },
      {
        name: DBInfo.name_3,
        title: DBInfo.title_3,
        text: DBInfo.description_3,
      },
      {
        name: DBInfo.name_4,
        title: DBInfo.title_4,
        text: DBInfo.description_4,
      },
      {
        name: DBInfo.name_5,
        title: DBInfo.title_5,
        text: DBInfo.description_5,
      },
      {
        name: DBInfo.name_6,
        title: DBInfo.title_6,
        text: DBInfo.description_6,
      },
      {
        name: DBInfo.name_7,
        title: DBInfo.title_7,
        text: DBInfo.description_7,
      },
      {
        name: DBInfo.name_8,
        title: DBInfo.title_8,
        text: DBInfo.description_8,
      },
    ];
  }, [DBInfo]);

  const [isShowModal, setIsShowModal] = useState(false);

  const [modalItem, setModalItem] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <div id="key" className="m-key">
        <div className="m-key-bg">
          <img src="Mobile/Key/bg.svg" alt="" />
          <div className="m-key-title">
            <MobileTitle
              first={{ text: "КЛЮЧЕВЫЕ", color: "#62929E" }}
              second={{ text: "УСЛУГИ", color: "#49525e" }}
            />
          </div>
          <div className="m-key-items">
            {services.map((service) => (
              <MobileKeyServicesItem
                key={Math.random()}
                name={service.name}
                click={() => {
                  toggleModal();
                  setModalItem(service);
                }}
              />
            ))}
          </div>
        </div>
        <div className="m-key-ship">
          <img src="Mobile/Key/ship.svg" alt="" />
        </div>

        <div className="m-key-footer">
          <button className="m-key-button" onClick={() => setIsShowModal(true)}>
            {DBInfo.contact}
          </button>
          {/* <img className='m-key-tree-back' src='Mobile/Key/trees.svg' />
          <img className='m-key-tree-back-2' src='Mobile/Key/trees.svg' /> */}
          <div className="m-key-train">
            <Lottie
              lottieRef={lottieRefTrain}
              autoplay={false}
              loop={true}
              style={styleTrain}
              animationData={animationDataTrain}
            />
            {/* <img src='Mobile/Key/train.svg'/> */}
          </div>
          {/* <img className='m-key-tree-front' src='Mobile/Key/trees-2.svg' /> */}
          {/* <img className='m-key-tree-front-2' src='Mobile/Key/trees-2.svg' /> */}
          <img className="m-key-rails" src="Mobile/Key/rails.svg" />
        </div>

        {isModalVisible && (
          <div className="m-key-modal">
            <div className="m-key-close">
              <img
                src="Mobile/Key/close.svg"
                alt="Закрыть"
                onClick={toggleModal}
              />
            </div>
            <h2>{modalItem.title}</h2>
            <p>{modalItem.text}</p>
            <button onClick={() => setIsShowModal(true)}>
              {DBInfo.contact}
            </button>
          </div>
        )}
      </div>
      {isShowModal && (
        <MobileModal>
          <MobileFeedback
            toggleMobileFeedback={() => {
              setIsShowModal(false);
            }}
          />
        </MobileModal>
      )}
    </>
  );
};
