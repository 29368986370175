import React from 'react'
import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import './AdminKey.css'
import { AdminContentPage } from './AdminContentPage';
import { LamaText } from '../components/Inputs/LamaText';
import { LamaTextarea } from '../components/Inputs/LamaTextarea';

export const AdminKey = () => {

    const tag = useRef();
    const title_color = useRef();
    const title_dark = useRef();

    const contact = useRef();

    const name_1 = useRef();
    const title_1 = useRef();
    const description_1 = useRef();
    const name_2 = useRef();
    const title_2 = useRef();
    const description_2 = useRef();
    const name_3 = useRef();
    const title_3 = useRef();
    const description_3 = useRef();
    const name_4 = useRef();
    const title_4 = useRef();
    const description_4 = useRef();
    const name_5 = useRef();
    const title_5 = useRef();
    const description_5 = useRef();
    const name_6 = useRef();
    const title_6 = useRef();
    const description_6 = useRef();
    const name_7 = useRef();
    const title_7 = useRef();
    const description_7 = useRef();
    const name_8 = useRef();
    const title_8 = useRef();
    const description_8 = useRef();

    useEffect(()=>{

    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Service`)
    .then(res => {
        tag.current.value = res?.data?.[0]?.tag;
        title_color.current.value = res?.data?.[0]?.title_color;
        title_dark.current.value = res?.data?.[0]?.title_dark;

        name_1.current.value = res?.data?.[0]?.name_1;
        title_1.current.value = res?.data?.[0]?.title_1;
        description_1.current.value = res?.data?.[0]?.description_1;
        name_2.current.value = res?.data?.[0]?.name_2;
        title_2.current.value = res?.data?.[0]?.title_2;
        description_2.current.value = res?.data?.[0]?.description_2;
        name_3.current.value = res?.data?.[0]?.name_3;
        title_3.current.value = res?.data?.[0]?.title_3;
        description_3.current.value = res?.data?.[0]?.description_3;
        name_4.current.value = res?.data?.[0]?.name_4;
        title_4.current.value = res?.data?.[0]?.title_4;
        description_4.current.value = res?.data?.[0]?.description_4;
        name_5.current.value = res?.data?.[0]?.name_5;
        title_5.current.value = res?.data?.[0]?.title_5;
        description_5.current.value = res?.data?.[0]?.description_5;
        name_6.current.value = res?.data?.[0]?.name_6;
        title_6.current.value = res?.data?.[0]?.title_6;
        description_6.current.value = res?.data?.[0]?.description_6;
        name_7.current.value = res?.data?.[0]?.name_7;
        title_7.current.value = res?.data?.[0]?.title_7;
        description_7.current.value = res?.data?.[0]?.description_7;
        name_8.current.value = res?.data?.[0]?.name_8;
        title_8.current.value = res?.data?.[0]?.title_8;
        description_8.current.value = res?.data?.[0]?.description_8;
        contact.current.value = res?.data?.[0]?.contact;
    })
    },[])

    const [progress, setProgress] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [warning, setWarning] = useState(false);
    const showSuccess = () => {
        setProgress(false);
        if (success) return;
        setSuccess(true)
        setTimeout(()=>{
            setSuccess(false)
        },3000);
    }

    const showError = () => {
        setProgress(false);
        if (error) return;
        setError(true)
        setTimeout(()=>{
            setError(false)
        },5000);
    }

    const save = () => {
            setWarning(false);
            setProgress(true);
            const data = {
                tag: tag.current.value,
                title_color: title_color.current.value,
                title_dark: title_dark.current.value,          
                name_1: name_1.current.value,
                title_1: title_1.current.value,
                description_1: description_1.current.value,
                name_2: name_2.current.value,
                title_2: title_2.current.value,
                description_2: description_2.current.value,
                name_3: name_3.current.value,
                title_3: title_3.current.value,
                description_3: description_3.current.value,
                name_4: name_4.current.value,
                title_4: title_4.current.value,
                description_4: description_4.current.value,
                name_5: name_5.current.value,
                title_5: title_5.current.value,
                description_5: description_5.current.value,
                name_6: name_6.current.value,
                title_6: title_6.current.value,
                description_6: description_6.current.value,
                name_7: name_7.current.value,
                title_7: title_7.current.value,
                description_7: description_7.current.value,
                name_8: name_8.current.value,
                title_8: title_8.current.value,
                description_8: description_8.current.value,
                contact: contact.current.value,
            };
            // console.log({data});
            axios.post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
                id: 'Service',
                content: JSON.stringify(data)
            })
            .then(({data})=>{
                // console.log(data);
                if (data.acknowledged) {
                    showSuccess();
                } else {
                    showError();
                }
            })
            .catch(e=>{
                showError();
            })
    }

  return (
    <AdminContentPage
      title="Ключевые услуги"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={tag} l_title={"Тэг над заголовком"} />
      <LamaText l_ref={title_color} l_title={"Цветная часть заголовка"} />
      <LamaText l_ref={title_dark} l_title={"Темная часть заголовка"} />
      <LamaText l_ref={contact} l_title={"Название кнопки обратной связи"} />

      <LamaText l_ref={name_1} l_title={"Кнопка 1"} />
      <LamaText l_ref={title_1} l_title={"Заголовок 1"} />
      <LamaTextarea l_ref={description_1} l_title={"Описание 1"} />

      <LamaText l_ref={name_2} l_title={"Кнопка 2"} />
      <LamaText l_ref={title_2} l_title={"Заголовок 2"} />
      <LamaTextarea l_ref={description_2} l_title={"Описание 2"} />

      <LamaText l_ref={name_3} l_title={"Кнопка 3"} />
      <LamaText l_ref={title_3} l_title={"Заголовок 3"} />
      <LamaTextarea l_ref={description_3} l_title={"Описание 3"} />

      <LamaText l_ref={name_4} l_title={"Кнопка 4"} />
      <LamaText l_ref={title_4} l_title={"Заголовок 4"} />
      <LamaTextarea l_ref={description_4} l_title={"Описание 4"} />

      <LamaText l_ref={name_5} l_title={"Кнопка 5"} />
      <LamaText l_ref={title_5} l_title={"Заголовок 5"} />
      <LamaTextarea l_ref={description_5} l_title={"Описание 5"} />

      <LamaText l_ref={name_6} l_title={"Кнопка 6"} />
      <LamaText l_ref={title_6} l_title={"Заголовок 6"} />
      <LamaTextarea l_ref={description_6} l_title={"Описание 6"} />

      <LamaText l_ref={name_7} l_title={"Кнопка 7"} />
      <LamaText l_ref={title_7} l_title={"Заголовок 7"} />
      <LamaTextarea l_ref={description_7} l_title={"Описание 7"} />

      <LamaText l_ref={name_8} l_title={"Кнопка 8"} />
      <LamaText l_ref={title_8} l_title={"Заголовок 8"} />
      <LamaTextarea l_ref={description_8} l_title={"Описание 8"} />

    </AdminContentPage>
  );
    


//     <div className='a-main-inputs'>
//         <p className="a-main-title">
//             Тэг над заголовком
//         </p>
//         <input type="text" ref={tag} className="a-main-input"/>

//         <p className="a-main-title">
//             Заголовок
//         </p>
//         <input type="text" ref={title} className="a-main-input"/>


//         <p className="a-main-title">
//             Название кнопки обратной связи
//         </p>
//         <input type="text" ref={contact} className="a-main-input"/>


//         <p className="a-main-title">
//             Кнопка 1
//         </p>
//         <input type="text" ref={name_1} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 1
//         </p>
//         <input type="text" ref={title_1} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 1
//         </p>
//         <textarea type="textarea" ref={description_1} className="a-main-textarea">
//         </textarea>


//         <p className="a-main-title">
//             Кнопка 2
//         </p>
//         <input type="text" ref={name_2} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 2
//         </p>
//         <input type="text" ref={title_2} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 2
//         </p>
//         <textarea type="textarea" ref={description_2} className="a-main-textarea">
//         </textarea>


//         <p className="a-main-title">
//             Кнопка 3
//         </p>
//         <input type="text" ref={name_3} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 3
//         </p>
//         <input type="text" ref={title_3} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 3
//         </p>
//         <textarea type="textarea" ref={description_3} className="a-main-textarea">
//         </textarea>


//         <p className="a-main-title">
//             Кнопка 4
//         </p>
//         <input type="text" ref={name_4} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 4
//         </p>
//         <input type="text" ref={title_4} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 4
//         </p>
//         <textarea type="textarea" ref={description_4} className="a-main-textarea">
//         </textarea>


//         <p className="a-main-title">
//             Кнопка 5
//         </p>
//         <input type="text" ref={name_5} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 5
//         </p>
//         <input type="text" ref={title_5} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 5
//         </p>
//         <textarea type="textarea" ref={description_5} className="a-main-textarea">
//         </textarea>


//         <p className="a-main-title">
//             Кнопка 6
//         </p>
//         <input type="text" ref={name_6} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 6
//         </p>
//         <input type="text" ref={title_6} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 6
//         </p>
//         <textarea type="textarea" ref={description_6} className="a-main-textarea">
//         </textarea>


//         <p className="a-main-title">
//             Кнопка 7
//         </p>
//         <input type="text" ref={name_7} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 7
//         </p>
//         <input type="text" ref={title_7} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 7
//         </p>
//         <textarea type="textarea" ref={description_7} className="a-main-textarea">
//         </textarea>


//         <p className="a-main-title">
//             Кнопка 8
//         </p>
//         <input type="text" ref={name_8} className="a-main-input"/>
//         <p className="a-main-title">
//             Заголовок 8
//         </p>
//         <input type="text" ref={title_8} className="a-main-input"/>
//         <p className="a-main-title">
//             Описание 8
//         </p>
//         <textarea type="textarea" ref={description_8} className="a-main-textarea">
//         </textarea>
//   )
}
