import { createSlice } from "@reduxjs/toolkit";
import { divisionReducers } from "./division.actions.ts";
import { divisionInitialState } from "./division.state.ts";
import { divisionThunksCallbacksAsync } from "../logic/division.thunks.async.ts";
import { divisionThunksCallbacksSync } from "../logic/division.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const divisionThunks: any = createThunksForExport({
  async: divisionThunksCallbacksAsync,
  sync: divisionThunksCallbacksSync,
});

export const divisionDataSlice = createSlice({
  name: "divisionData",
  initialState: divisionInitialState,
  reducers: divisionReducers,
});

export const divisionStaticData = (state: RootState) => state.divisionData;

export const divisionActions = divisionDataSlice.actions;

export default divisionDataSlice.reducer;
