import React from 'react';
import './MobileProjectsItem.css';

export const MobileProjectsItem = ({content, openText, openProject}) => {
  return (
    <div className='m-projects-item' onClick={openProject}>
      <h3>{content.title}</h3>
      <p>{content.description.slice(0,60)}...</p>
      <button>{openText}</button>
    </div>
  )
}
