import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import {
  IGeoConfig,
  IGeoState,
} from "../state/geo.types";
import { geoActions } from "../state/geo.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let geoConfig: IGeoConfig = require("./../state/geo.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().geoData.fetched) return;
  thunkApi.dispatch(geoActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(geoConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  console.log({ info });

  const geoState: IGeoState = {
    title_color: info.title_color,
    title_dark: info.title_dark,
    subtitle: info.subtitle,
    selectedSectionName: "train",
    fetched: true,
    items: {
      auto: {
        title: info.title_4,
        src: info.src_4,
        description: info.description_4,
        points: info.points_4,
      },
      fly: {
        title: info.title_3,
        src: info.src_3,
        description: info.description_3,
        points: info.points_3,
      },
      train: {
        title: info.title_1,
        src: info.src_1,
        description: info.description_1,
        points: info.points_1,
      },
      ship: {
        title: info.title_2,
        src: info.src_2,
        description: info.description_2,
        points: info.points_2,
      },
    },
  };

  thunkApi.dispatch(geoActions.setGeoState(geoState));
};

const emitSlideNext = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(geoActions.setSlideNextEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(geoActions.setSlideNextEvent(false));
  // }, 500);
};

const emitSlidePrev = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(geoActions.setSlidePrevEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(geoActions.setSlidePrevEvent(false));
  // }, 500);
};

export const geoExtraReducers = {
  "thunk/fetchInfo/pending": (state: IGeoState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: IGeoState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: IGeoState) => {
    console.log("rejected");
  },
};

export const geoThunksCallbacksAsync = {
  fetchInfo,
  emitSlideNext,
  emitSlidePrev,
};
