import { createSlice } from "@reduxjs/toolkit";
import { contactsReducers } from "./contacts.actions.ts";
import { contactsInitialState } from "./contacts.state.ts";
import { contactsThunksCallbacksAsync } from "../logic/contacts.thunks.async.ts";
import { contactsThunksCallbacksSync } from "../logic/contacts.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const contactsThunks: any = createThunksForExport({
  async: contactsThunksCallbacksAsync,
  sync: contactsThunksCallbacksSync,
});

export const contactsDataSlice = createSlice({
  name: "contactsData",
  initialState: contactsInitialState,
  reducers: contactsReducers,
});

export const contactsStaticData = (state: RootState) => state.contactsData;

export const contactsActions = contactsDataSlice.actions;

export default contactsDataSlice.reducer;
