import Lottie from 'lottie-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MobileTitle } from '../Title/MobileTitle';
import './MobileGeo.css';
import axios from 'axios';
import { MobileGeoItem } from './MobileGeoItem';
import animationDataCar from "./services-view-car.json";

export const MobileGeo = () => {
  const lottieRefCar = useRef();  
  const styleCar = {zIndex: '2000', width: '80vw', transform: 'scaleX(-1)', maxWidth: '300px'};

  const [DBInfo, setDBInfo] = useState({});

  useEffect(()=>{
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Geo`)
    .then(res => {
      const info = {};
      if (!res?.data?.[0]) return;

      Object.keys(res?.data?.[0]).forEach(el=>{
        if (el === '_id') return;
        info[el] = res.data[0][el];
      })
      setDBInfo(info);
    })

  },[])

  const items = useMemo(()=>{
    return [
      {iconSrc: 'Geography/rail-icon.svg',title:DBInfo.title_1,text:DBInfo.description_1},
      {iconSrc: 'Geography/ship-icon.svg',title:DBInfo.title_2,text:DBInfo.description_2},
      {iconSrc: 'Geography/avia-icon.svg',title:DBInfo.title_3,text:DBInfo.description_3},
      {iconSrc: 'Geography/auto-icon.svg',title:DBInfo.title_4,text:DBInfo.description_4},
    ]
  },[DBInfo])

  return (
    <>
    <div className='m-geo-road'>
      <img className='m-geo-road-lines' src='Mobile/Geo/road-lines.svg'/>
      <div className='m-geo-car'>
          <Lottie lottieRef={lottieRefCar} autoplay={false} loop={true} style={styleCar} animationData={animationDataCar}/>
      </div>
      {/* <img className='m-geo-car' src='Mobile/Geo/car.svg'/> */}
    </div>
    <div className='m-geo-content'>
      <img className='m-geo-bg-img' src='Mobile/Geo/bg.svg'/>
      <MobileTitle type='three_lines' first={{text: 'ГЕОГРАФИЯ', color: '#62929E'}} second={{text: 'ДЕЯТЕЛЬНОСТИ', color: '#49525e'}} third={{text: 'Российская Федерация', color: "#ADBFCC"}}/>
      
      <div className='m-geo-items'>
        <MobileGeoItem type='right' content={items[0]}/>
        <MobileGeoItem type='left' content={items[1]}/>
        <MobileGeoItem type='right' content={items[2]}/>
        <MobileGeoItem type='left' content={items[3]}/>
      </div>
    </div>
    </>
  )
}
