import React from 'react';
import styles from './agencyHeader.module.css';
import { useAppSelector } from '../../../../hooks/hooksRedux.ts';

export const AgencyHeader = () => {
    const { title_color, title_dark, subtitle, description } = useAppSelector(state => state.agencyData);
    return (
        <>
            <div className={styles.title}>
                <p>{title_color}</p>
                <p className={styles.dark}>{title_dark}</p>
            </div>
            <div className={styles.description}>
                {
                    description?.split("\n").map((el, i) => {
                        if (el === '') {
                            return <br key={Math.random()} />
                        }
                        return <p key={i + Math.random()}>{el}</p>;
                    })
                }
            </div>
        </>
    )
}
