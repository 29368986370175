import {React, useRef, useEffect, useState} from 'react'
import './LamaSliderItem.css'
import { LamaText } from './LamaText';
import { LamaTextarea } from './LamaTextarea';

export const LamaSliderItem = ({remove, values, title, text, position, image, id}) => {
    const titleRef = useRef();
    const textRef = useRef();
    const imageRef = useRef();
    const positionRef = useRef();

    useEffect(()=>{
        // console.log(imageRef);
        values.current.push(() => {
            if (!titleRef.current || !textRef.current || !imageRef.current || !positionRef.current) return null;
            return {
              title: titleRef.current.value,
              text: textRef.current.value,
              position: positionRef.current.value,
              image: imageRef.current.src,
            }
        })
        titleRef.current.value = title ? title : '';
        textRef.current.value = text ? text : '';
        positionRef.current.value = position ? position : '';
        setImg(image ? {src: image} : {src: ''});

    },[])

    const onload = (e) => {
      const file = e.target.files[0];
      if (file.type !== 'image/png') return;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setImg({src: reader.result});
      };
      reader.onerror = function (error) {
        // console.log('Error: ', error);
      };
    }

    const [img, setImg] = useState({src: ''});
  return (
    <div className='l-project'>
        <LamaText l_ref={titleRef} l_title={`Имя`} />
        <LamaText l_ref={positionRef} l_title={`Должность`} />
        <LamaTextarea l_ref={textRef} l_title={`Текст`} />
        {/* <LamaText l_ref={imageRef} l_title={`Изображение`} /> */}

        <label className='l-file-input' htmlFor={'l-file-input_' + id}>
          <div>
            <input style={{position: 'absolute', width: '0', height: '0', opacity: '0'}} id={'l-file-input_' + id} type="file" onChange={onload}/>
            {img.src === '' && <p>Загрузить фотографию сотрудника (только .PNG)</p>}
            <img ref={imageRef} src={img.src} alt="" />
          </div>
        </label>
        <button onClick={remove}>Удалить</button>
    </div>
  )
}
