import axios from "axios";
import { areasThunks } from "../../../views/desktop/areas/state/areas.slice.ts";
import { projectsThunks } from "../../../views/desktop/projects/state/projects.slice.ts";
import { agencyThunks } from "../../../views/desktop/agency/state/agency.slice.ts";
import { feedbackThunks } from "../state/feedback.slice.ts";
import { feedbackActions } from "../state/feedback.slice.ts";
import { IFeedbackState, IFeedbackRule } from "../state/feedback.types.ts";
import { IFeedbackConfig } from "../state/feedback.types";
import { fetchDataFromDB } from "../../../api/api";
import { DBResponse } from "./../../../store/store.types.ts";

let feedbackConfig: IFeedbackConfig = require("./../state/feedback.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().feedbackData.fetched) return;
  thunkApi.dispatch(feedbackActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(feedbackConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  console.log({ info });

  const feedbackState: IFeedbackState = { ...info, ...{ fetched: true } };

  thunkApi.dispatch(feedbackActions.setFeedbackState(feedbackState));
};

export const feedbackThunksCallbacksAsync = {
  fetchInfo,
};
