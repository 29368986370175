import { ISliderRule, ISliderRules, ISliderState } from "./slider.types";

export const sliderInitialState: ISliderState = {
  previousKey: "",
  currentKey: "",
  nextKey: "",
  keys: [
    "main",
    "key",
    // "team",
    "areas",
    "geo",
    "projects",
    "customs",
    "agency",
    "division",
    "china",
    "contacts",
    "warning"
  ] as string[],
  thunkNameMap: {
    main: "mainThunks",
    key: "keyThunks",
    team: "teamThunks",
    areas: "areasThunks",
    geo: "geoThunks",
    projects: "projectsThunks",
    customs: "customsThunks",
    agency: "agencyThunks",
    division: "divisionThunks",
    china: "chinaThunks",
    contacts: "contactsThunks",
    warning: "contactsThunks",
  },
  rules: {
    main: {
      incomeDirection: {
        next: "fromBottom",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    key: {
      incomeDirection: {
        next: "fromBottom",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    // team: {
    //   incomeDirection: {
    //     next: "fromBottom",
    //     prev: "fromLeft",
    //   },
    //   durationMS: 400,
    //   blockMS: 1200,
    // },
    areas: {
      incomeDirection: {
        next: "fromRight",
        prev: "fromLeft",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    geo: {
      incomeDirection: {
        next: "fromRight",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    projects: {
      incomeDirection: {
        next: "fromBottom",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    customs: {
      incomeDirection: {
        next: "fromBottom",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    agency: {
      incomeDirection: {
        next: "fromBottom",
        prev: "fromLeft",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    division: {
      incomeDirection: {
        next: "fromRight",
        prev: "fromLeft",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    china: {
      incomeDirection: {
        next: "fromRight",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    contacts: {
      incomeDirection: {
        next: "fromBottom",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    warning: {
      incomeDirection: {
        next: "fromBottom",
        prev: "fromTop",
      },
      durationMS: 400,
      blockMS: 1200,
    },
    // ["test-map"]: {
    //   incomeDirection: {
    //     next: "fromBottom",
    //     prev: "fromTop",
    //   },
    //   durationMS: 400,
    //   blockMS: 0,
    // },
  } as ISliderRules,
  getInKey: "",
  readyToEnter: false,
  transitionStart: false,
  transitionEnd: false,
  direction: "",
  blocked: false,
  progress: false,
  nextBlocked: false,
  previousBlocked: false,
  enteredFromContacts: false,
};
