import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './agency.module.css';
import { AgencyHeader } from './AgencyHeader';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { agencyThunks } from '../state/agency.slice.ts';
import Rive from '@rive-app/react-canvas';
import { AgencySlider } from './AgencySlider';

export const Agency = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { agencyData } = useAppSelector(state => state);
    useEffect(() => {
        if (!agencyData.fetched) {
            dispatch(agencyThunks.fetchInfo());
        }
    }, []);

    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                <AgencyHeader />

                <div className={styles.plane_income_container}>
                    <div className={styles.plane_container}>
                        <div className={styles.wings_container}>
                            <div className={styles.wings}>
                                <Rive src="/desktop/agency/plane-wings.riv" />
                            </div>
                        </div>

                        <div className={styles.body_container}>
                            <div className={styles.body}>
                                <Rive src="/desktop/agency/plane-body.riv" />
                            </div>
                        </div>
                    </div>
                </div>
                <AgencySlider />
            </div>

            <img className={styles.cloud_3} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_4} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_1} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_2} src='./desktop/services/cloud.svg' />


            <img className={styles.cloud_6} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_7} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_4} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_5} src='./desktop/services/cloud.svg' />
        </div>
    )
}
