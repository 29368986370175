import { AppThunk } from "../../../../store/store.ts";
import { customsActions } from "../state/customs.slice.ts";
import { ICustomsState } from "../state/customs.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(customsActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(customsActions.setSlidePrevEvent(false));
};

export const customsThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
};
