import { createSlice } from "@reduxjs/toolkit";
import { chinaReducers } from "./china.actions.ts";
import { chinaInitialState } from "./china.state.ts";
import { chinaThunksCallbacksAsync } from "../logic/china.thunks.async.ts";
import { chinaThunksCallbacksSync } from "../logic/china.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const chinaThunks: any = createThunksForExport({
  async: chinaThunksCallbacksAsync,
  sync: chinaThunksCallbacksSync,
});

export const chinaDataSlice = createSlice({
  name: "chinaData",
  initialState: chinaInitialState,
  reducers: chinaReducers,
});

export const chinaStaticData = (state: RootState) => state.chinaData;

export const chinaActions = chinaDataSlice.actions;

export default chinaDataSlice.reducer;
