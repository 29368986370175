import React from 'react';
import './MobileHeaderItem.css';

const scrollTo = (scroll, setIsShowMenu) => {
    console.log('scrolling to scroll... ', scroll);
    document.getElementById('container').scrollTop = scroll;
    setIsShowMenu(false);
};

export const MobileHeaderItem = ({title, scroll, setIsShowMenu}) => {
  return (
    <div className='m-header-item' onClick={()=>{scrollTo(scroll, setIsShowMenu)}}>
        <p>{title}</p>
    </div>
  )
}
