import { IAreasState, ISliderItem } from "./areas.types.ts";

export const areasInitialState: IAreasState = {
  areasPageDescription: {
    title_color: "КОМАНДА",
    title_dark: "ПРОФЕССИОНАЛОВ",
    description: `Наши специалисты являются главной причиной почему нам\n доверяют грузы и рекомендуют нашу компанию`,
    contact: "Запрос / Предложение",
    slider: [] as ISliderItem[],
    slideNextEvent: false,
    slidePrevEvent: false
  },
};
