import React, { useMemo } from 'react';
import './MobileProject.css';

export const MobileProject = ({ toggleProject, projectTitle, projectContent }) => {


  const content = useMemo(() => {
    return projectContent.split('\n').map(el => {
      if (!el) return;
      return <>
        {
          el?.split("\n").map(line => {
            let element = <></>;
            if (line === '') {
              element = <br />
            } else if (line.slice(0, 4) === 'img[') {
              const images = line.slice(5, -2).split('/').map(image => {
                return <img src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image} />
              })
              element = <div className='project-prod-images'>
                {images}
              </div>
            } else if (line.slice(0, 3) === 'img') {
              const image = line.slice(4, -1);
              element = <div className='project-prod-image m-project-prod-image'><img src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image} /></div>
            } else {
              element = <p key={Math.random()}>{line}</p>
            }
            return element
          })}
      </>
    });
  }, [projectContent])

  return (
    <div className='m-project-modal' onWheel={onwheel} onClick={(e) => { if (e.target !== e.currentTarget) return; toggleProject() }}>
      <div className='m-project-background'>
        <p className='m-project-title'>{projectTitle}</p>
        <div className='m-project-text'>
          {content.map(el => {
            return el
          })}
        </div>
        <img className="m-project-close" src="close.svg" alt="X" onClick={toggleProject} />
      </div>
    </div>
  )
}
