import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminChina.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";

export const AdminChina = () => {
  const title_color = useRef();
  const title_dark = useRef();

  const subtitle = useRef();
  const address_1 = useRef();
  const address_2 = useRef();
  
  const src_1 = useRef();
  const description_1 = useRef();
  const src_2 = useRef();
  const description_2 = useRef();
  const src_3 = useRef();
  const description_3 = useRef();
  const src_4 = useRef();
  const description_4 = useRef();


  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=China`).then((res) => {
      title_color.current.value = res?.data?.[0]?.title_color;
      title_dark.current.value = res?.data?.[0]?.title_dark;
      subtitle.current.value = res?.data?.[0]?.subtitle;
      address_1.current.value = res?.data?.[0]?.address_1;
      address_2.current.value = res?.data?.[0]?.address_2;

      src_1.current.value = res?.data?.[0]?.src_1;
      description_1.current.value = res?.data?.[0]?.description_1;

      src_2.current.value = res?.data?.[0]?.src_2;
      description_2.current.value = res?.data?.[0]?.description_2;

      src_3.current.value = res?.data?.[0]?.src_3;
      description_3.current.value = res?.data?.[0]?.description_3;

      src_4.current.value = res?.data?.[0]?.src_4;
      description_4.current.value = res?.data?.[0]?.description_4;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "China",
        content: JSON.stringify({
          title_color: title_color.current.value,
          title_dark: title_dark.current.value,
          subtitle: subtitle.current.value,
          address_1: address_1.current.value,
          address_2: address_2.current.value,
    
          src_1: src_1.current.value,
          description_1: description_1.current.value,
    
          src_2: src_2.current.value,
          description_2: description_2.current.value,
    
          src_3: src_3.current.value,
          description_3: description_3.current.value ,
    
          src_4: src_4.current.value,
          description_4: description_4.current.value
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  return (
    <AdminContentPage
      title="Торговая компания в Китае"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={title_color} l_title={"Цветная часть заголовка"} />
      <LamaText l_ref={title_dark} l_title={"Темная часть заголовка"} />

      <LamaText l_ref={subtitle} l_title={"Подзаголовок"} />
      <LamaText l_ref={address_1} l_title={"Цветная часть адреса"} />
      <LamaText l_ref={address_2} l_title={"Темная часть адреса"} />

      <LamaTextarea l_ref={description_1} l_title={"Описание 1"} />
      <LamaText l_ref={src_1} l_title={"Путь к файлу 1"} l_disabled={true}/>

      <LamaTextarea l_ref={description_2} l_title={"Описание 2"} />
      <LamaText l_ref={src_2} l_title={"Путь к файлу 2"} l_disabled={true}/>

      <LamaTextarea l_ref={description_3} l_title={"Описание 3"} />
      <LamaText l_ref={src_3} l_title={"Путь к файлу 3"} l_disabled={true}/>

      <LamaTextarea l_ref={description_4} l_title={"Описание 4"} />
      <LamaText l_ref={src_4} l_title={"Путь к файлу 4"} l_disabled={true}/>

    </AdminContentPage>
  );
};