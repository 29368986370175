import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminProjects.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";
import { LamaList } from "../components/Inputs/LamaList";

export const AdminProjects = () => {
  const title_color = useRef();
  const title_dark = useRef();
  const projects = useRef([]);
  const buttonText = useRef();
  const openText = useRef();

  const setProjects = useRef();

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Projects`).then((res) => {
      title_color.current.value = res?.data?.[0]?.title_color;
      title_dark.current.value = res?.data?.[0]?.title_dark;
      projects.current = res?.data?.[0]?.projects;
      setProjects.current();
      buttonText.current.value = res?.data?.[0]?.buttonText;
      openText.current.value = res?.data?.[0]?.openText;
      // sliderInfo.current.value = res?.data?.[0]?.sliderInfo;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    // console.log({res: getResults.current()});
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Projects",
        content: JSON.stringify({
          title_color: title_color.current.value,
          title_dark: title_dark.current.value,
          projects: getResults.current(),
          buttonText: buttonText.current.value,
          openText: openText.current.value,
          // sliderInfo: sliderInfo.current.value
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const getResults = useRef({current: null});

  return (
    <AdminContentPage
      title="Наши проекты"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={title_color} l_title={"Цветная часть заголовка"} />
      <LamaText l_ref={title_dark} l_title={"Темная часть заголовка"} />
      <LamaText l_ref={openText} l_title={"Текст на кнопках карточек"} />
      <LamaText l_ref={buttonText} l_title={"Текст на главной кнопке"} />
      <LamaList getResults={getResults} projects={projects} setProjects={setProjects}/>
    </AdminContentPage>
  );
};