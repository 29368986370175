import { AppThunk } from "../../../../store/store.ts";
import { chinaActions } from "../state/china.slice.ts";
import { IChinaState } from "../state/china.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(chinaActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(chinaActions.setSlidePrevEvent(false));
};

export const chinaThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
};
