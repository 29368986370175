import React, { useMemo, useState, useEffect } from 'react';
import styles from './agencySlider.module.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { sliderThunks } from './../../../../plugins/slider/state/slider.slice.ts';
import { agencyThunks } from '../state/agency.slice.ts';

const SHIFT = [
    '0px',
    '-300px',
    '-615px',
    '-941px'
]

export const AgencySlider = () => {

    const dispatch = useAppDispatch();
    const { slideNextEvent, slidePrevEvent } = useAppSelector(state => state.agencyData);

    useEffect(() => {
        if (!slideNextEvent) return;
        dispatch(agencyThunks.resetSlideNextEvent())
        goNext();
    }, [slideNextEvent])

    useEffect(() => {
        if (!slidePrevEvent) return;
        if (shiftIndex === 0) {
            setLineStyles({ transform: 'translateY(120px)' });
        }
        setTimeout(() => { dispatch(agencyThunks.resetSlidePrevEvent()) }, 500)
        goPrev();
    }, [slidePrevEvent])

    useEffect(() => {
        setTimeout(() => { setProgress(false) }, 1000);
        return () => {
            dispatch(sliderThunks.unblockPreviousSlide());
            dispatch(sliderThunks.unblockNextSlide());
        }
    }, [])


    const [progress, setProgress] = useState(true);
    const [shiftIndex, setShiftIndex] = useState(0);

    const [lineStyles, setLineStyles] = useState({});
    useEffect(() => {
        if (shiftIndex === 0) {
            dispatch(sliderThunks.blockNextSlide());
            setTimeout(() => {
                dispatch(sliderThunks.unblockPreviousSlide());
            }, 500);
        } else if (shiftIndex === 3) {
            dispatch(sliderThunks.blockPreviousSlide());
            setTimeout(() => {
                dispatch(sliderThunks.unblockNextSlide());
            }, 500);
        } else {
            dispatch(sliderThunks.blockPreviousSlide());
            dispatch(sliderThunks.blockNextSlide());
        }
        setLineStyles({ transform: `translateY(${SHIFT[shiftIndex]})` });
    }, [shiftIndex])


    const goNext = () => {
        console.log(progress);
        if (progress) return;
        setProgress(true);
        if (shiftIndex === 3) {
            return;
        }
        setShiftIndex(shiftIndex + 1);
        setSelectedIndex(shiftIndex + 1);
        setTimeout(() => { setProgress(false) }, 500);
    }

    const goPrev = () => {
        if (progress) return;
        setProgress(true);
        if (shiftIndex === 0) {
            return;
        }
        setShiftIndex(shiftIndex - 1);
        setSelectedIndex(shiftIndex - 1);
        setTimeout(() => { setProgress(false) }, 500);
    }

    const { items } = useAppSelector(state => state.agencyData);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const selected = useMemo(() => {
        return {
            title: items[selectedIndex]?.title,
            subtitle: items[selectedIndex]?.subtitle,
            description: items[selectedIndex]?.description
        }
    }, [selectedIndex, items])

    return (
        <>
            <div className={styles.line} style={lineStyles}>
                <img src="desktop/agency/line.svg" />
                {items ? items.map(({ title }, index) => (
                    <div className={styles[`item_${index}`]} key={index}>
                        <div className={styles.circle} style={{ filter: title !== selected.title ? 'opacity(0.2)' : 'opacity(1)' }}>
                            <img src={`desktop/agency/icon_${index}.svg`} className={styles.icon} />
                        </div>
                    </div>
                )
                ) : null}
            </div>
            <div className={styles.rectangle}>
                <p className={styles.title}>{selected.title}</p>
                <p className={styles.subtitle}>{selected.subtitle}</p>
                <p className={styles.description}>{selected.description}</p>
            </div>
        </>
    )
}
