import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/plugins/store/store.ts";
import { createThunksForExport } from "./../../../store/store.services.ts";
import { adaptiveThunksCallbacksAsync } from "../logic/adaptive.thunks.async.ts";
import { adaptiveThunksCallbacksSync } from "../logic/adaptive.thunks.sync.ts";
import { adaptiveReducers } from "./adaptive.actions.ts";
import { adaptiveInitialState } from "./adaptive.state.ts";

export const adaptiveThunks: any = createThunksForExport({
  async: adaptiveThunksCallbacksAsync,
  sync: adaptiveThunksCallbacksSync,
});

export const adaptiveSlice = createSlice({
  name: "adaptiveData",
  initialState: adaptiveInitialState,
  reducers: adaptiveReducers,
});

export const adaptiveActions = adaptiveSlice.actions;

export const selectAdaptiveData = (state: RootState) => state.adaptiveData;

export default adaptiveSlice.reducer;
