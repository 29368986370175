import React, { useEffect, useState, useRef, useMemo } from 'react';
import styles from './projectList.module.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { sliderThunks } from './../../../../plugins/slider/state/slider.slice.ts';
import { projectsThunks } from './../state/projects.slice.ts';
import { classes } from '../../../../libs/classname';
import { Image } from 'antd';
import './projectList.css';

let timeoutId;

export const ProjectList = () => {
    const { button_text, open_text, slidePrevEvent, slideNextEvent, openProject, selectedItem } = useAppSelector(state => state.projectsData);
    const projects = useAppSelector(state => state.projectsData.items);

    const dispatch = useAppDispatch();

    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (!slideNextEvent) return;
        dispatch(projectsThunks.resetSlideNextEvent())
        scroll(14);
    }, [slideNextEvent])

    useEffect(() => {
        if (!slidePrevEvent) return;
        dispatch(projectsThunks.resetSlidePrevEvent())
        scroll(-14);
    }, [slidePrevEvent])

    useEffect(() => {
        return () => {
            dispatch(sliderThunks.unblockPreviousSlide());
            dispatch(sliderThunks.unblockNextSlide());
        }
    }, [])

    useEffect(() => {
        changePreviewsText()
    }, [selectedItem])

    const changePreviewsText = () => {
        const elements = document.getElementsByClassName('ant-image-mask-info');
        [...elements].forEach((el) => {
            console.log(el.innerHTML);
            el.innerHTML = 'Нажмите для просмотра';
        })
    }

    const show = () => {
        setShowAll(true);
        dispatch(sliderThunks.blockNextSlide());
    }
    const projectRef = useRef();
    const sliderRef = useRef();
    // const [sliderTimeoutId, setSliderTimeoutId] = useState(null);
    const scroll = (value) => {
        if (openProject) {
            projectRef.current.scrollTop += value;
        } else if (showAll) {
            clearTimeout(timeoutId);
            if (value > 0) {
                dispatch(sliderThunks.blockPreviousSlide());
            } else {
                dispatch(sliderThunks.blockNextSlide());
            }
            if (sliderRef.current.scrollTop === 0 && value < 0) {
                timeoutId = setTimeout(() => {
                    dispatch(sliderThunks.unblockPreviousSlide());
                }, 200);
            } else if (sliderRef.current.scrollHeight - sliderRef.current.offsetHeight === sliderRef.current.scrollTop && value > 0) {
                timeoutId = setTimeout(() => {
                    dispatch(sliderThunks.unblockNextSlide());
                }, 200);
            }
            sliderRef.current.scrollTop += value;
            if (sliderRef.current.scrollTop > 0) {
                dispatch(sliderThunks.blockPreviousSlide());
            } else if (sliderRef.current.scrollHeight - sliderRef.current.offsetHeight !== sliderRef.current.scrollTop) {
                dispatch(sliderThunks.blockNextSlide());
            }
        }
    }
    const content = useMemo(() => {
        if (!selectedItem?.description) return []
        return selectedItem.description.split('\n').map(el => {
            if (!el) return;

            return <>
                {
                    el?.split("\n").map(line => {
                        let element = <></>;
                        if (line === '') {
                            element = <br />
                        } else if (line.slice(0, 4) === 'img[') {
                            const images = line.slice(5, -2).split('/').map(image => {
                                return (
                                    <Image
                                        width={555}
                                        height={320}
                                        className={styles.image}
                                        style={{ padding: '16px', display: 'block', objectFit: 'cover' }}
                                        src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image}
                                    // placeholder={
                                    //     <Image
                                    //         preview={false}
                                    //         src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image}
                                    //         width={555}
                                    //     />
                                    // }
                                    />
                                )
                            })
                            element = <div key={Math.random()} className={styles.images}>
                                {images}
                            </div>
                        } else if (line.slice(0, 3) === 'img') {
                            const image = line.slice(4, -1);
                            element = <Image key={Math.random()}
                                width={555}
                                height={320}
                                className={styles.image}
                                style={{ padding: '16px', display: 'block', objectFit: 'cover' }}
                                src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image}
                                preview={'Посмотреть'}
                            // placeholder={
                            //     <Image
                            //         preview={false}
                            //         src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image}
                            //         width={555}

                            //     />
                            // }
                            />
                        } else {
                            element = <p key={Math.random()}>{line}</p>
                        }
                        return element
                    })}
            </>
        })
    }, [selectedItem])

    const open = (i) => {
        dispatch(sliderThunks.blockPreviousSlide());
        dispatch(sliderThunks.blockNextSlide());
        dispatch(projectsThunks.openProject({ title: projects[i].title, description: projects[i].description }));
    }

    const close = () => {
        dispatch(projectsThunks.closeProject());
        if (!showAll) {
            dispatch(sliderThunks.unblockPreviousSlide());
            dispatch(sliderThunks.unblockNextSlide());
        }
    }

    return (<>

        <div className={styles.container}>
            {!openProject ? <div className={classes([styles.projects, !showAll ? styles.transparent : ''])} ref={sliderRef} style={{ overflowY: showAll ? 'scroll' : 'hidden', overflowX: showAll ? 'hidden' : 'visible', height: showAll ? 'calc(100vh - 240px)' : 'calc(100vh - 240px)' }}>
                {projects && projects.map(({ title, description }, i) => (
                    <div className={styles.project} key={Math.random()}>
                        <p className={styles.title}>{title}</p>
                        {/* <p className={styles.description}>{description.slice(0, 120)}</p> */}
                        <button className={styles.open} onClick={() => { open(i) }}>{open_text}</button>
                    </div>
                ))}
            </div> : null}
            {!showAll && !openProject ? <button className={styles.show} onClick={show}>{button_text}</button> : null}
            {openProject ? <div className={styles.selected_container}>

                <div className={styles.selected} ref={projectRef}>
                    <h3 className={styles.selected_title}>{selectedItem.title}</h3>
                    {content.map(el => el)}
                </div>

                <button className={styles.close} onClick={close}>
                    <img src="desktop/projects/close.svg" alt="X" />
                </button>



            </div> : null}
        </div>

    </>)
}
