import React from 'react';
import styles from './areasHeader.module.css';
import { useAppSelector } from '../../../../hooks/hooksRedux.ts';

const DBInfo = {
    description: `Наши специалисты являются главной причиной почему нам
    доверяют грузы и рекомендуют нашу компанию`
}
export const AreasHeader = () => {
    const { title_color, title_dark, description } = useAppSelector(state => state.areasData.areasPageDescription);
    return (
        <>
            <div className={styles.title}>
                <p>{title_color}</p>
                {/* <p>Команда</p> */}
                <p className={styles.dark}>{title_dark}</p>
                {/* <p className={styles.dark}>Профессионалов</p> */}
            </div>
        </>
    )
}
