import { PayloadAction } from "@reduxjs/toolkit";
import { BreakpointsEnum, IAdaptiveState, IMaxWidths } from "./adaptive.types.ts";

function setWidthBreakpoint(
  state: IAdaptiveState,
  { payload: breakpoint }: PayloadAction<BreakpointsEnum>
) {
  state.widthBreakpoint = breakpoint;
}

function setHeightBreakpoint(
  state: IAdaptiveState,
  { payload: breakpoint }: PayloadAction<BreakpointsEnum>
) {
  state.heightBreakpoint = breakpoint;
}

export const adaptiveReducers = {
  setWidthBreakpoint,
  setHeightBreakpoint,
};
