import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import { IProjectsConfig, IProjectsState } from "../state/projects.types.ts";
import { projectsActions } from "../state/projects.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let projectsConfig: IProjectsConfig = require("./../state/projects.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().projectsData.fetched) return;
  thunkApi.dispatch(projectsActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(projectsConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  console.log({ info });

  const projectsState: IProjectsState = {
    title_color: info.title_color,
    title_dark: info.title_dark,
    button_text: info.buttonText,
    open_text: info.openText,
    items: info.projects,
  };

  thunkApi.dispatch(projectsActions.setProjectsState(projectsState));
};

const emitSlideNext = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(projectsActions.setSlideNextEvent(true));
};

const emitSlidePrev = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(projectsActions.setSlidePrevEvent(true));
};

export const projectsExtraReducers = {
  "thunk/fetchInfo/pending": (state: IProjectsState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: IProjectsState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: IProjectsState) => {
    console.log("rejected");
  },
};

export const projectsThunksCallbacksAsync = {
  fetchInfo,
  emitSlideNext,
  emitSlidePrev,
};
