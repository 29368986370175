import { React } from "react";
import "./LamaTextarea.css";

export const LamaTextarea = (
  { l_ref, l_style, l_title, l_placeholder, l_type, values, id, prevValue } = {
    l_ref: {current: null},
    l_style: {},
    l_title: "",
    l_placeholder: "",
    l_type: "text",
    values: {current: ''},
    id: '1',
    prevValue: '',
  }
) => {
  return (
    <main className="l-textarea">
      <p>{l_title}:</p>
      <textarea
        type={l_type}
        ref={l_ref}
        style={l_style}
        placeholder={l_placeholder}
        defaultValue={prevValue}
        onChange={(e)=>{values.current[id] = e.target.value}}
      ></textarea>
    </main>
  );
};
