import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom/client";
import { classes } from '../../../../libs/classname';
import styles from './services.module.css';
import Rive from '@rive-app/react-canvas';
import { ServicesList } from './ServicesList';
import { ServiceHeader } from './ServiceHeader';
import { Feedback } from '../../../../components/Feedback/Feedback';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { ServicesThunks } from '../state/Services.slice.ts';
import { sliderThunks } from './../../../../plugins/slider/state/slider.slice.ts';
import { feedbackThunks } from '../../../../components/Feedback/state/feedback.slice.ts';


export const Services = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { servicePageDescription } = useAppSelector(state => state.ServicesData);
    useEffect(() => {
        if (!servicePageDescription.title_color) {
            dispatch(ServicesThunks.fetchInfo());
        }
        return () => {
            dispatch(feedbackThunks.closeFeedback());
        }
    }, []);

    return (
        <>
            <div s-id={sid} className={styles.container}>


                <div className={styles.outer__sea_line} />
                <div className={styles.outer__ground_line} />
                <div className={styles.outer__rails_top_line} />
                <div className={styles.outer__rails_bottom_line} />
                <div className={styles.outer__road_line} />


                <div className={styles.background_layout}>
                    <div className={styles.circle}>
                        <div className={styles.inner__sea_line} />
                        <div className={styles.inner__rails_top_line} />
                        <div className={styles.inner__rails_bottom_line} />
                        <div className={styles.inner__road_line} />
                    </div>

                    <img className={styles.background} src='./desktop/services/background.svg' />

                    <div className={styles.crane}>
                        <Rive src="/desktop/services/crane.riv" />
                    </div>

                    <img className={styles.ship} src='./desktop/services/ship.svg' />

                    <img className={styles.cloud_3} src='./desktop/services/cloud.svg' />
                    <img className={styles.cloud_4} src='./desktop/services/cloud.svg' />
                    <img className={styles.plane} src='./desktop/services/plane.svg' />
                    <img className={styles.cloud_1} src='./desktop/services/cloud.svg' />
                    <img className={styles.cloud_2} src='./desktop/services/cloud.svg' />


                    <img className={styles.circle_mask} src='./desktop/services/circle-mask.svg' />


                    <div className={styles.mask}>
                        <img className={styles.tree_1} src='./desktop/services/tree.svg' />
                        <img className={styles.tree_2} src='./desktop/services/tree.svg' />
                        <div className={styles.train_container}>
                            <div className={styles.train}>
                                <Rive src="/desktop/services/train.riv" />
                            </div>
                        </div>

                        <img className={styles.tree_3} src='./desktop/services/tree.svg' />
                        <img className={styles.tree_4} src='./desktop/services/tree.svg' />

                        <div className={styles.road_paints}>
                            <div className={classes(styles.road_paint, styles.road_paint_1)} />
                            <div className={classes(styles.road_paint, styles.road_paint_2)} />
                            <div className={classes(styles.road_paint, styles.road_paint_3)} />
                            <div className={classes(styles.road_paint, styles.road_paint_4)} />
                            <div className={classes(styles.road_paint, styles.road_paint_5)} />
                            <div className={classes(styles.road_paint, styles.road_paint_6)} />
                            <div className={classes(styles.road_paint, styles.road_paint_7)} />
                            <div className={classes(styles.road_paint, styles.road_paint_8)} />
                            <div className={classes(styles.road_paint, styles.road_paint_9)} />
                            <div className={classes(styles.road_paint, styles.road_paint_10)} />
                            <div className={classes(styles.road_paint, styles.road_paint_11)} />
                            <div className={classes(styles.road_paint, styles.road_paint_12)} />

                            <div className={classes(styles.road_paint, styles.road_paint_bottom_1)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_2)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_3)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_4)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_5)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_6)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_7)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_8)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_9)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_10)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_11)} />
                            <div className={classes(styles.road_paint, styles.road_paint_bottom_12)} />
                        </div>

                    </div>

                    <div className={styles.car_container}>
                        <div className={styles.car}>
                            <Rive src="/desktop/services/car.riv" />
                        </div>
                    </div>

                    <ServiceHeader />
                    <ServicesList />
                </div>


            </div>

            {/* {isShowFeedback && <div className={styles.feedback}><PortaledFeedback toggleFeedback={toggleFeedback} /></div>} */}
        </>
    )
}
