import { createSlice } from "@reduxjs/toolkit";
import { projectsReducers } from "./projects.actions.ts";
import { projectsInitialState } from "./projects.state.ts";
import { projectsThunksCallbacksAsync } from "../logic/projects.thunks.async.ts";
import { projectsThunksCallbacksSync } from "../logic/projects.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const projectsThunks: any = createThunksForExport({
  async: projectsThunksCallbacksAsync,
  sync: projectsThunksCallbacksSync,
});

export const projectsDataSlice = createSlice({
  name: "projectsData",
  initialState: projectsInitialState,
  reducers: projectsReducers,
});

export const projectsStaticData = (state: RootState) => state.projectsData;

export const projectsActions = projectsDataSlice.actions;

export default projectsDataSlice.reducer;
