import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminAreas.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";

export const AdminAreas = () => {
  const title_color = useRef();
  const title_dark = useRef();
  
  const title_1 = useRef();
  const src_1 = useRef();
  const description_1 = useRef();
  const title_2 = useRef();
  const src_2 = useRef();
  const description_2 = useRef();
  const title_3 = useRef();
  const src_3 = useRef();
  const description_3 = useRef();
  const title_4 = useRef();
  const src_4 = useRef();
  const description_4 = useRef();
  const title_5 = useRef();
  const src_5 = useRef();
  const description_5 = useRef();
  const title_6 = useRef();
  const src_6 = useRef();
  const description_6 = useRef();
  const title_7 = useRef();
  const src_7 = useRef();
  const description_7 = useRef();
  const title_8 = useRef();
  const src_8 = useRef();
  const description_8 = useRef();
  const title_9 = useRef();
  const src_9 = useRef();
  const description_9 = useRef();
  const title_10 = useRef();
  const src_10 = useRef();
  const description_10 = useRef();




  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Areas`).then((res) => {
      title_color.current.value = res?.data?.[0]?.title_color;
      title_dark.current.value = res?.data?.[0]?.title_dark;

      title_1.current.value = res?.data?.[0]?.title_1;
      src_1.current.value = res?.data?.[0]?.src_1;
      description_1.current.value = res?.data?.[0]?.description_1;

      title_2.current.value = res?.data?.[0]?.title_2;
      src_2.current.value = res?.data?.[0]?.src_2;
      description_2.current.value = res?.data?.[0]?.description_2;

      title_3.current.value = res?.data?.[0]?.title_3;
      src_3.current.value = res?.data?.[0]?.src_3;
      description_3.current.value = res?.data?.[0]?.description_3;

      title_4.current.value = res?.data?.[0]?.title_4;
      src_4.current.value = res?.data?.[0]?.src_4;
      description_4.current.value = res?.data?.[0]?.description_4;

      title_5.current.value = res?.data?.[0]?.title_5;
      src_5.current.value = res?.data?.[0]?.src_5;
      description_5.current.value = res?.data?.[0]?.description_5;

      title_6.current.value = res?.data?.[0]?.title_6;
      src_6.current.value = res?.data?.[0]?.src_6;
      description_6.current.value = res?.data?.[0]?.description_6;

      title_7.current.value = res?.data?.[0]?.title_7;
      src_7.current.value = res?.data?.[0]?.src_7;
      description_7.current.value = res?.data?.[0]?.description_7;

      title_8.current.value = res?.data?.[0]?.title_8;
      src_8.current.value = res?.data?.[0]?.src_8;
      description_8.current.value = res?.data?.[0]?.description_8;

      title_9.current.value = res?.data?.[0]?.title_9;
      src_9.current.value = res?.data?.[0]?.src_9;
      description_9.current.value = res?.data?.[0]?.description_9;

      title_10.current.value = res?.data?.[0]?.title_10;
      src_10.current.value = res?.data?.[0]?.src_10;
      description_10.current.value = res?.data?.[0]?.description_10;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Areas",
        content: JSON.stringify({
          title_color: title_color.current.value,
          title_dark: title_dark.current.value,
    
          title_1: title_1.current.value,
          src_1: src_1.current.value,
          description_1: description_1.current.value,
    
          title_2: title_2.current.value,
          src_2: src_2.current.value,
          description_2: description_2.current.value,
    
          title_3: title_3.current.value,
          src_3: src_3.current.value,
          description_3: description_3.current.value ,
    
          title_4: title_4.current.value,
          src_4: src_4.current.value,
          description_4: description_4.current.value,
    
          title_5: title_5.current.value ,
          src_5: src_5.current.value,
          description_5: description_5.current.value,
    
          title_6: title_6.current.value,
          src_6: src_6.current.value,
          description_6: description_6.current.value,
    
          title_7: title_7.current.value,
          src_7: src_7.current.value,
          description_7: description_7.current.value,
    
          title_8: title_8.current.value,
          src_8: src_8.current.value,
          description_8: description_8.current.value,

          title_9: title_9.current.value,
          src_9: src_9.current.value,
          description_9: description_9.current.value,

          title_10: title_10.current.value,
          src_10: src_10.current.value,
          description_10: description_10.current.value,
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  return (
    <AdminContentPage
      title="Отраслевая направленность"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={title_color} l_title={"Цветная часть заголовка"} />
      <LamaText l_ref={title_dark} l_title={"Темная часть заголовка"} />

      <LamaText l_ref={title_1} l_title={"Название 1"} />
      <LamaTextarea l_ref={description_1} l_title={"Описание 1"} />
      <LamaText l_ref={src_1} l_title={"Путь к файлу 1"} l_disabled={true}/>

      <LamaText l_ref={title_2} l_title={"Название 2"} />
      <LamaTextarea l_ref={description_2} l_title={"Описание 2"} />
      <LamaText l_ref={src_2} l_title={"Путь к файлу 2"} l_disabled={true} />

      <LamaText l_ref={title_3} l_title={"Название 3"} />
      <LamaTextarea l_ref={description_3} l_title={"Описание 3"} />
      <LamaText l_ref={src_3} l_title={"Путь к файлу 3"} l_disabled={true} />

      <LamaText l_ref={title_4} l_title={"Название 4"} />
      <LamaTextarea l_ref={description_4} l_title={"Описание 4"} />
      <LamaText l_ref={src_4} l_title={"Путь к файлу 4"} l_disabled={true} />

      <LamaText l_ref={title_5} l_title={"Название 5"} />
      <LamaTextarea l_ref={description_5} l_title={"Описание 5"} />
      <LamaText l_ref={src_5} l_title={"Путь к файлу 5"} l_disabled={true} />

      <LamaText l_ref={title_6} l_title={"Название 6"} />
      <LamaTextarea l_ref={description_6} l_title={"Описание 6"} />
      <LamaText l_ref={src_6} l_title={"Путь к файлу 6"} l_disabled={true} />

      <LamaText l_ref={title_7} l_title={"Название 7"} />
      <LamaTextarea l_ref={description_7} l_title={"Описание 7"} />
      <LamaText l_ref={src_7} l_title={"Путь к файлу 7"} l_disabled={true} />

      <LamaText l_ref={title_8} l_title={"Название 8"} />
      <LamaTextarea l_ref={description_8} l_title={"Описание 8"} />
      <LamaText l_ref={src_8} l_title={"Путь к файлу 8"} l_disabled={true} />

      <LamaText l_ref={title_9} l_title={"Название 9"} />
      <LamaTextarea l_ref={description_9} l_title={"Описание 9"} />
      <LamaText l_ref={src_9} l_title={"Путь к файлу 9"} l_disabled={true} />

      <LamaText l_ref={title_10} l_title={"Название 10"} />
      <LamaTextarea l_ref={description_10} l_title={"Описание 10"} />
      <LamaText l_ref={src_10} l_title={"Путь к файлу 10"} l_disabled={true} />

    </AdminContentPage>
  );
};
