import React from 'react';
import './MobileTitle.css';

export const MobileTitle = ({type, first, second, third}) => {
  return (
    <div className={`m-title${type === 'two_lines' || type === 'three_lines'? ' m-title-two-lines' : ''}`} style={{height: type==='three_lines' ? '120px' : '80px'}}>
        <p className={type === 'two_lines' || type === 'three_lines' ? 'm-title-first-two-lines' : 'm-title-first'} style={{color: first?.color}}>{first?.text}</p>
        <p className='m-title-second' style={{color: second?.color}}>{second?.text}</p>
        {type==='three_lines' && <p className='m-title-third' style={{color: third?.color}}>{third?.text}</p>}
    </div>
  )
}
