import { ITeamState, ISliderItem } from "./team.types.ts";

export const teamInitialState: ITeamState = {
  teamPageDescription: {
    title_color: "КОМАНДА",
    title_dark: "ПРОФЕССИОНАЛОВ",
    description: `Наши специалисты являются главной причиной почему нам\n доверяют грузы и рекомендуют нашу компанию`,
    contact: "Запрос / Предложение",
    slider: [] as ISliderItem[],
  },
};
