import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { YMaps, Map, Placemark } from "react-yandex-maps";

import "./AdminContacts.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";

export const AdminContacts = () => {
  const title_color = useRef();
  const title_dark = useRef();

  const text = useRef();
  const contact = useRef();


  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Contacts`).then((res) => {
      title_color.current.value = res?.data?.[0]?.title_color;
      title_dark.current.value = res?.data?.[0]?.title_dark;
      text.current.value = res?.data?.[0]?.text;
      contact.current.value = res?.data?.[0]?.contact;
      coordsRef.current.value = res?.data?.[0]?.coordinates;
      zoomRef.current.value = res?.data?.[0]?.zoom;
      pointCoordsRef.current.value = res?.data?.[0]?.point_coordinates;
      pointTitleRef.current.value = res?.data?.[0]?.point_title;
      setCoords(res?.data?.[0]?.coordinates);
      setZoom(res?.data?.[0]?.zoom);
      setPointCoords(res?.data?.[0]?.point_coordinates);
      setPointTitle(res?.data?.[0]?.point_title);
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    // console.log({coords});
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Contacts",
        content: JSON.stringify({
          title_color: title_color.current.value,
          title_dark: title_dark.current.value,
          text: text.current.value,
          contact: contact.current.value,
          coordinates: coords,
          zoom: zoom,
          point_coordinates: pointCoords,
          point_title: pointTitle,
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  
  const coordsRef = useRef();
  const zoomRef = useRef();
  const pointTitleRef = useRef();
  const pointCoordsRef = useRef();
  const [coords, setCoords] = useState([0,0]);
  const [zoom, setZoom] = useState(12);
  const [pointCoords, setPointCoords] = useState([0,0]);
  const [pointTitle, setPointTitle] = useState('');

  useEffect(()=>{
    setRefreshMaps(null)
  },[coords, zoom, pointCoords, pointTitle])
  const [refreshMaps, setRefreshMaps] = useState(false);
 
  useEffect(()=>{
    if(refreshMaps === null) {
      setRefreshMaps(true)
    }
  },[refreshMaps])
  return (
    <AdminContentPage
      title="Наши контакты"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={title_color} l_title={"Цветная часть заголовка"} />
      <LamaText l_ref={title_dark} l_title={"Темная часть заголовка"} />
      <LamaTextarea l_ref={text} l_title={"Текст над картой"} />
      <LamaText l_ref={contact} l_title={"Надпись на кнопке"} />

      <div className='l-yandex'>
        <p>Центр карты:</p>
        <input
          type='text'
          ref={coordsRef}
          onKeyDown={()=>{
            setCoords(coordsRef.current.value.split(','))
          }}
          onChange={()=>{
            setCoords(coordsRef.current.value.split(','))
          }}
        />
        <p>Приближение карты:</p>
        <input
          type='number'
          ref={zoomRef}
          onKeyDown={()=>{setZoom(zoomRef.current.value)}}
          onChange={()=>{setZoom(zoomRef.current.value)}}
        />
        
        <p>Координаты точки:</p>
        <input
          type='text'
          ref={pointCoordsRef}
          onKeyDown={()=>{
            setPointCoords(pointCoordsRef.current.value.split(','))
          }}
          onChange={()=>{
            setPointCoords(pointCoordsRef.current.value.split(','))
          }}
        />

        <p>Надпись на точке:</p>
        <input
          type='text'
          ref={pointTitleRef}
          onKeyDown={()=>{setPointTitle(pointTitleRef.current.value)}}
          onChange={()=>{setPointTitle(pointTitleRef.current.value)}}
        />
        
        <div className='l-yandex-map'>
          <p>Загрузка карты...</p>
        {
          refreshMaps &&
          <YMaps>
            <div>
              <Map defaultState={{ center: coords, zoom: zoom}} width='452px' height='258px'>
                <Placemark geometry={{
                      type: "Point",
                      coordinates: pointCoords
                  }} properties={{
                    preset: 'islands#icon',
                    iconCaption: pointTitle
                }}></Placemark>
              </Map>
              
            </div>
          </YMaps>
        }
        </div>
      </div>

    </AdminContentPage>
  );
};