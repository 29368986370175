import { AppThunk } from "../../../../store/store.ts";
import { divisionActions } from "../state/division.slice.ts";
import { IDivisionState } from "../state/division.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(divisionActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(divisionActions.setSlidePrevEvent(false));
};

export const divisionThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
};
