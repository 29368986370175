import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './projects.module.css';
import { ProjectsHeader } from './ProjectsHeader';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { projectsThunks } from '../state/projects.slice.ts';
import { ProjectList } from './ProjectList';

export const Projects = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { projectsData } = useAppSelector(state => state);
    useEffect(() => {
        if (!projectsData?.items?.fly) {
            dispatch(projectsThunks.fetchInfo());
        }
    }, []);

    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                {!projectsData.openProject ? <ProjectsHeader /> : null}
                <ProjectList />
                {/* <img src="desktop/projects/transparent-mask.svg
                " className={styles.transparent_mask} /> */}
            </div>



        </div>
    )
}
