import { React, useState, useEffect, useRef, useMemo } from 'react';
import './Feedback.css';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from "./../../hooks/hooksRedux.ts";
import { feedbackThunks } from './state/feedback.slice.ts';
import { sliderThunks } from './../../plugins/slider/state/slider.slice.ts';

export const Feedback = () => {

  const info = useAppSelector(state => state.feedbackData);
  const variant = useAppSelector(state => state.feedbackData.variant)
  const isTeamFeedback = useMemo(() => {
    return variant === 'team'
  }, [variant]);
  const dispatch = useAppDispatch()

  const name = useRef();
  const phone = useRef();
  const company = useRef();
  const email = useRef();
  const message = useRef();
  const to = useRef();
  const from = useRef();
  const contacts = useRef();

  const [emailInfo, setEmailInfo] = useState({});

  const toggleFeedback = () => {
    dispatch(feedbackThunks.closeFeedback());
  }

  useEffect(() => {
    dispatch(sliderThunks.blockPreviousSlide());
    dispatch(sliderThunks.blockNextSlide());
    return () => {
      dispatch(sliderThunks.unblockPreviousSlide());
      dispatch(sliderThunks.unblockNextSlide());
    }
  }, [])

  useEffect(() => {

    // axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=FeedbackModal`)
    // .then(res => {
    //   const info = {};
    //   if (!res?.data?.[0]) return;

    //   Object.keys(res?.data?.[0]).forEach(el=>{
    //     if (el === '_id') return;
    //     info[el] = res.data[0][el];
    //   })
    // //   console.log(info);
    // })

    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Email`)
      .then(res => {
        const info = {};
        if (!res?.data?.[0]) return;

        Object.keys(res?.data?.[0]).forEach(el => {
          if (el === '_id') return;
          info[el] = res.data[0][el];
        })
        setEmailInfo(info);
      })

  }, [])

  const send = (type) => {

    if (type === 'normal' && (
      name.current.value === '' ||
      phone.current.value === ''
    )) {
      showValidationError();
      return;
    }

    if (type === 'team' && (
      to.current.value === '' ||
      from.current.value === '' ||
      contacts.current.value === ''
    )) {
      showValidationError();
      return;
    }
    setValidateError(false);


    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/mail`, {
        data: JSON.stringify({
          host: emailInfo.host,
          port: emailInfo.port,
          from: emailInfo.from,
          type,
          client: type === 'normal' ?
            {
              name: name.current.value,
              phone: phone.current.value,
              company: company.current.value,
              email: email.current.value,
              message: message.current.value,
            } :
            {
              to: to.current.value,
              from: from.current.value,
              contacts: contacts.current.value,
              message: message.current.value,
            }
        }),
      })
      .then(({ data }) => {
        if (data === true) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  }

  const showSuccess = () => {
    setModal(true);
  }
  const showError = () => {
    setError(true);
    setModal(true);
  }

  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);


  const showValidationError = () => {
    setValidateError(true);
  }

  const [validateError, setValidateError] = useState(false);

  return (
    <div className='feedback-modal' onClick={(e) => { if (e.target !== e.currentTarget) return; toggleFeedback() }}>
      {modal && <div className='feedback-success-background'>

        {!error && <h3>{info.success_title}</h3>}
        {!error && <p>{info.success_text}</p>}
        {error && <h3>{info.error_title}</h3>}
        {error && <p>{info.error_text}</p>}
        <button onClick={() => { setModal(false); toggleFeedback() }}>{info.modal_close}</button>

      </div>}
      {!modal && <div className='feedback-background'>

        <div className='feedback-info'>
          <div className='feedback-logo'>
            <img src='logo-dark.svg' />
          </div>
          <>
            {
              info.text?.split("\n").map((el, i) => {
                if (el === '') {
                  return <br />
                }
                return <p key={i + Math.random()}>{el}</p>;
              })
            }
          </>
        </div>

        <div className='feedback-inputs'>
          <p className='feedback-title'>{!isTeamFeedback ? info.title : info.team_title}</p>
          <div className='feedback-form'>
            {!isTeamFeedback && <><input type="text" className='feedback-input' placeholder={info.input_1} ref={name} />
              <input type="text" className='feedback-input' placeholder={info.input_2} ref={phone} />
              <input type="text" className='feedback-input' placeholder={info.input_3} ref={company} />
              <input type="text" className='feedback-input' placeholder={info.input_4} ref={email} />
              <textarea className='feedback-textarea' placeholder={info.textarea} ref={message} />
              <div className='feedback-button' onClick={() => { send('normal') }}>
                <p >{info.button}</p>
              </div>
              {validateError && <p className='feedback-error-normal'>{info.validation_text}</p>}



            </>}
            {isTeamFeedback && <><input type="text" className='feedback-input' placeholder={info.team_input_1} ref={to} />
              <input type="text" className='feedback-input' placeholder={info.team_input_2} ref={from} />
              <input type="text" className='feedback-input' placeholder={info.team_input_3} ref={contacts} />
              <textarea className='feedback-textarea' placeholder={info.team_textarea} ref={message} />
              <div className='feedback-button' onClick={() => { send('team') }}>
                <p >{info.team_button}</p>
              </div>
              {validateError && <p className='feedback-error'>{info.validation_text}</p>}




            </>}
          </div>
        </div>

        <img className="feedback-close" src="close.svg" alt="X" onClick={toggleFeedback} />

      </div>}
    </div>
  )
}
