import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './contacts.module.css';
import { ContactsHeader } from './ContactsHeader';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { contactsThunks } from '../state/contacts.slice.ts';
import { ContactsMap } from './ContactsMap';

export const Contacts = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { text } = useAppSelector(state => state.contactsData);
    useEffect(() => {
        if (!text) {
            dispatch(contactsThunks.fetchInfo());
        }
    }, []);

    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                <ContactsHeader />
                <div className={styles.card}>
                    {
                        text?.split("\n").map((el, i) => {
                            if (el === '') {
                                return <br key={Math.random()} />
                            }
                            return <p key={i + Math.random()}>{el}</p>;
                        })
                    }
                </div>

            </div>
            <ContactsMap />

        </div>
    )
}
