import { createSlice } from "@reduxjs/toolkit";
import { areasReducers } from "./areas.actions.ts";
import { areasInitialState } from "./areas.state.ts";
import { areasThunksCallbacksAsync } from "../logic/areas.thunks.async.ts";
import { areasThunksCallbacksSync } from "../logic/areas.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const areasThunks: any = createThunksForExport({
  async: areasThunksCallbacksAsync,
  sync: areasThunksCallbacksSync,
});

export const areasDataSlice = createSlice({
  name: "areasData",
  initialState: areasInitialState,
  reducers: areasReducers,
});

export const areasStaticData = (state: RootState) => state.areasData;

export const areasActions = areasDataSlice.actions;

export default areasDataSlice.reducer;
