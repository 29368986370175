import { AppThunk } from "../../../../store/store.ts";
import { projectsActions } from "../state/projects.slice.ts";
import { IProjectsItem } from "../state/projects.types";
import { IProjectsState } from "../state/projects.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(projectsActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(projectsActions.setSlidePrevEvent(false));
};

const openProject =
  (item: IProjectsItem): AppThunk =>
  (dispatch) => {
    dispatch(projectsActions.setOpenProject(true));
    dispatch(projectsActions.setSelectedItem(item));
  };

const closeProject = (): AppThunk => (dispatch) => {
  dispatch(projectsActions.setOpenProject(false));
  dispatch(projectsActions.setSelectedItem({} as IProjectsItem));
};

export const projectsThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
  openProject,
  closeProject,
};
