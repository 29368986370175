import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import { IMainConfig, IMainState } from "../state/main.types.ts";
import { mainActions } from "./../state/main.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let mainConfig: IMainConfig = require("./../state/main.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().mainData.fetched) return;
  thunkApi.dispatch(mainActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(mainConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  thunkApi.dispatch(mainActions.setTitle(info.title));
  thunkApi.dispatch(mainActions.setFirstInfo(info.firstInfo));
  thunkApi.dispatch(mainActions.setSecondInfo(info.secondInfo));
  thunkApi.dispatch(mainActions.setTag(info.tag));
};

export const mainExtraReducers = {
  "thunk/fetchInfo/pending": (state: IMainState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: IMainState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: IMainState) => {
    console.log("rejected");
  },
};

export const mainThunksCallbacksAsync = {
  fetchInfo,
};
