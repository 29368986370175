import React from "react";
import styles from "./warningHeader.module.css";

export const WarningHeader = () => {
  return (
    <>
      <div className={styles.title}>
        <p>Уважаемые коллеги</p>
        {/* <p>Команда</p> */}
        <p className={styles.dark}> и партнёры</p>
        {/* <p className={styles.dark}>Профессионалов</p> */}
      </div>
    </>
  );
};
