import {React, useRef, useState, useEffect} from 'react'
import './LamaMap.css'
import { LamaPoint } from './LamaPoint';

export const LamaMap = ({l_title, type, getInfo, setPoints}) => {


  const points = useRef([])
  const map = useRef();

  const editTitle = useRef();
  const editDescription = useRef();
  const editContainer = useRef();

  const [refresh, setRefresh] = useState([]);

  const [selectedId, setSelectedId] = useState();


  useEffect(()=>{
    setPoints.current[type] = function(p){
        points.current = p;
        setRefresh(prev=>[...prev, 0]);
    };
    getInfo.current[type] = function(){
        return points.current
    };
  },[])

  useEffect(()=>{
    if (!selectedId) return;
    const info = points.current.find(el=>el.id === selectedId);
    // editContainer.current.style.left = `calc(${info.x} - 15px)`;
    // editContainer.current.style.top = `calc(${info.y} - 15px)`;
    editTitle.current.value = info.title ? info.title : '';
    editDescription.current.value = info.description ? info.description : '';
  },[selectedId])

  const edit = (id) => {
    // console.log(opened);
    if (!opened) return;
    setSelectedId(id);
  } 

  const save = (title, description) => {
    const info = points.current.find(el=>el.id === selectedId);
    info.title = editTitle.current.value;
    info.description = editDescription.current.value;
    setSelectedId(null);
  }

  const cancel = () => {
    setSelectedId(null);
  }

  const remove = () => {
    points.current = points.current.filter(el=>el.id !== selectedId);
    setRefresh(prev=>[...prev, 0]);
    cancel();
  }

  const click = (e) => {
    // console.log(opened);
    if (!opened) return;
    const x = e.pageX - e.target.parentNode.offsetLeft;
    const y = e.pageY - e.target.parentNode.offsetTop;
    const height = e.target.height;
    const width = e.target.width;
    // console.log({x,y,height,width});

    const top = (y - 10) / (height * 0.01) + '%'; 
    const left = (x - 10) / (width * 0.01) + '%'; 
    
    points.current.push({
        id: Math.random() * Math.random(),
        x: left,
        y: top,
        info: {
            title: '',
            description: '',
        }
    })

    setRefresh(prev=>[...prev, 0]);
    

  }

    const [opened, setOpened] = useState(false);
    const mapContainer = useRef();
    const open = (e) => {
        e.stopPropagation();
        setOpened(true);
    }
    const close = (e) => {
        e.stopPropagation();
        setOpened(false);
    }


  return (
    <>
        <p className='l-map-title'>{l_title}:</p>
        <div className={`l-map ${opened ? 'l-map--selected' : ''}`} ref={mapContainer} onClick={open}>
            <div ref={map} className='l-map-img'>
                <img className='l-map-img' src='Admin/map.svg' onClick={click}/>
                {refresh && points.current.map(el=><LamaPoint key={el.id} x={el.x} y={el.y} id={el.id} info={el.info} edit={edit} opened={opened} type={type}/>) }

            </div>
            
        </div>
        
        {
            selectedId && 
            <div className='l-edit-background' 
            onClick={(e) => {
                if (e.target.className !== 'l-edit-background') return;
                else cancel();
                }}>
                    <div className='l-map-edit' ref={editContainer}>
                <input type="text" ref={editTitle} placeholder='Заголовок точки'/>
                <textarea ref={editDescription} placeholder='Описание точки'/>
                <button style={{backgroundColor: '#c6ffc6'}} onClick={save}>Сохранить</button>
                <button style={{backgroundColor: '#fcfffc'}} onClick={cancel}>Закрыть</button>
                <button style={{backgroundColor: '#ffc6c6'}} onClick={remove}>Удалить</button>
            </div>
                    
                    
        </div>
        }

        {opened && <button className='l-map-close' onClick={close}>Закрыть редактор</button>}
    </>
  )
}
