import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './team.module.css';
import Rive from '@rive-app/react-canvas';
import { TeamHeader } from './TeamHeader';
import { TeamSlider } from './TeamSlider';
// import { ServiceHeader } from './ServiceHeader';
// import { Feedback } from '../../../../components/Feedback/Feedback';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { teamThunks } from '../state/team.slice.ts';
import { Feedback } from '../../../../components/Feedback/Feedback';
import { sliderThunks } from './../../../../plugins/slider/state/slider.slice.ts';
import { feedbackThunks } from '../../../../components/Feedback/state/feedback.slice.ts';
// import { mainSlider } from '../../../../slider/MainSlider';

export const Team = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { teamPageDescription } = useAppSelector(state => state.teamData);
    const { contact } = useAppSelector(state => state.teamData.teamPageDescription);
    useEffect(() => {
        if (!teamPageDescription?.slider[0]) {
            dispatch(teamThunks.fetchInfo());
        }
    }, []);

    const [showFeedback, setShowFeedback] = useState(false);
    const toggleFeedback = () => {
        dispatch(feedbackThunks.openTeamFeedback());
    }


    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>

                <div className={styles.human_container}>
                    <div className={styles.human}>
                        <Rive src="/desktop/team/human.riv" />
                    </div>
                </div>

                <div className={styles.road_container}>
                    <div className={styles.road}>
                        <Rive src="/desktop/team/road.riv" />
                    </div>
                </div>

                <div className={styles.hand_container}>
                    <div className={styles.hand}>
                        <Rive src="/desktop/team/hand.riv" />
                    </div>
                </div>

                <img className={styles.shelves_back} src='./desktop/team/shelves-back.svg' />
                <img className={styles.shelves_front} src='./desktop/team/shelves-front.svg' />
                <img className={styles.table} src='./desktop/team/table.svg' />

                <TeamHeader />
                <TeamSlider />
                <button className={styles.button} onClick={toggleFeedback}>{contact}</button>
            </div>


            <img className={styles.cloud_3} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_4} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_1} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_2} src='./desktop/services/cloud.svg' />

            {/* {showFeedback && <Feedback toggleFeedback={toggleFeedback} isTeamFeedback={true} />} */}
        </div>
    )
}
