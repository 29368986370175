import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './china.module.css';
import { ChinaHeader } from './ChinaHeader';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { chinaThunks } from '../state/china.slice.ts';

export const China = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { items, address_title, address_description } = useAppSelector(state => state.chinaData);
    useEffect(() => {
        if (!items?.[0]) {
            dispatch(chinaThunks.fetchInfo());
        }
    }, []);

    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                <img className={styles.map} src='desktop/china/map.svg' />
                <div className={styles.map_text}>
                    <p className={styles.address_text}>{address_title}</p>
                    <p className={styles.address_description}>{address_description}</p>
                </div>
                <ChinaHeader />
                <div className={styles.items}>
                    {items ? items.map(({ src, description }) => (<div className={styles.item}>
                        <img className={styles.icon} src={src} />
                        <p className={styles.description}>{description}</p>
                    </div>
                    )) : null}
                </div>
            </div>

        </div >
    )
}
