import { AppThunk } from "../../../../store/store.ts";
import { geoActions } from "../state/geo.slice.ts";
import { IGeoState } from "../state/geo.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(geoActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(geoActions.setSlidePrevEvent(false));
};

const selectSection = (name: string): AppThunk => (dispatch) => {
  dispatch(geoActions.setSelectedSectionName(name));
}

export const geoThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
  selectSection,
};
