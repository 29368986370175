import { AppThunk } from "src/plugins/store/store.ts";
import { adaptiveActions } from "../state/adaptive.slice.ts";
import { BreakpointsEnum } from "../state/adaptive.types.ts";

const calculateBreakpoints =
  (width: number, height: number): AppThunk =>
  (dispatch, getState) => {
    const { maxWidths, maxHeights, widthBreakpoint, heightBreakpoint } =
      getState().adaptiveData;

    const calculatedWidthBreakpoint: BreakpointsEnum =
      width < maxWidths.xs
        ? BreakpointsEnum.xs
        : width < maxWidths.sm
        ? BreakpointsEnum.sm
        : width < maxWidths.md
        ? BreakpointsEnum.md
        : BreakpointsEnum.lg;

    if (calculatedWidthBreakpoint !== widthBreakpoint) {
      dispatch(adaptiveActions.setWidthBreakpoint(calculatedWidthBreakpoint));
    }

    const calculatedHeightBreakpoint: BreakpointsEnum =
      height < maxHeights.xs
        ? BreakpointsEnum.xs
        : height < maxHeights.sm
        ? BreakpointsEnum.sm
        : height < maxHeights.md
        ? BreakpointsEnum.md
        : BreakpointsEnum.lg;

    if (calculatedHeightBreakpoint !== heightBreakpoint) {
      dispatch(adaptiveActions.setHeightBreakpoint(calculatedHeightBreakpoint));
    }
  };

export const adaptiveThunksCallbacksSync = {
  calculateBreakpoints,
};
