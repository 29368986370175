import React from 'react';
import './MobileAgencyItem.css';

export const MobileAgencyItem = ({type, content}) => {
  return (
    <>
      <img className='m-agency-icon' src={content.iconSrc}/>
      <div className='m-agency-item'>
        <div className='m-agency-bg' style={{left: type === 'left' ? '-14vw' : '', right: type === 'right' ? '-14vw' : ''}}></div>
        <div className='m-agency-text'>
          <h3>{content.title}</h3>
          <p className='m-agency-subtitle'>{content.subtitle}</p>
          <p>{content.text}</p>
        </div>
      </div>
    </>
  )
}
