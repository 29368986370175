import React from 'react';
import styles from './chinaHeader.module.css';
import { useAppSelector } from '../../../../hooks/hooksRedux.ts';

export const ChinaHeader = () => {
    const { title_color, title_dark, subtitle } = useAppSelector(state => state.chinaData);
    return (
        <>
            <div className={styles.title}>
                <p>{title_color}</p>
                <p className={styles.dark}>{title_dark}</p>
            </div>
            <div className={styles.subtitle}>
                <p>{subtitle}</p>
            </div>
        </>
    )
}
