import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import { IAgencyConfig, IAgencyState } from "../state/agency.types.ts";
import { agencyActions } from "../state/agency.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let agencyConfig: IAgencyConfig = require("./../state/agency.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().agencyData.fetched) return;
  thunkApi.dispatch(agencyActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(agencyConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  console.log({ info });

  const agencyState: IAgencyState = {
    title_color: info.title_color,
    title_dark: info.title_dark,
    description: info.description,
    items: [
      {
        title: info.title_1,
        subtitle: info.subtitle_1,
        description: info.description_1,
      },
      {
        title: info.title_2,
        subtitle: info.subtitle_2,
        description: info.description_2,
      },
      {
        title: info.title_3,
        subtitle: info.subtitle_3,
        description: info.description_3,
      },
      {
        title: info.title_4,
        subtitle: info.subtitle_4,
        description: info.description_4,
      },
    ],
  };

  thunkApi.dispatch(agencyActions.setAgencyState(agencyState));
};

const emitSlideNext = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(agencyActions.setSlideNextEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(agencyActions.setSlideNextEvent(false));
  // }, 500);
};

const emitSlidePrev = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(agencyActions.setSlidePrevEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(agencyActions.setSlidePrevEvent(false));
  // }, 500);
};

export const agencyExtraReducers = {
  "thunk/fetchInfo/pending": (state: IAgencyState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: IAgencyState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: IAgencyState) => {
    console.log("rejected");
  },
};

export const agencyThunksCallbacksAsync = {
  fetchInfo,
  emitSlideNext,
  emitSlidePrev,
};
