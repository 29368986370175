import React, { useEffect, useMemo, useRef, useState } from 'react';
import './MobileCustoms.css';
import { MobileCustomsItem } from './MobileCustomsItem';
import axios from 'axios';
import { MobileTitle } from '../Title/MobileTitle';
import laptopData from './laptop.json';
import folderData from './folder.json';
import manData from './man.json';

export const MobileCustoms = () => {

  const laptop = useRef();
  const folder = useRef();
  const man = useRef();
  const style = { width: '80vw' };

  const [DBInfo, setDBInfo] = useState({});

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Clearance`)
      .then(res => {
        const info = {};
        // console.log(res?.data?.[0])
        if (!res?.data?.[0]) return;

        Object.keys(res?.data?.[0]).forEach(el => {
          if (el === '_id') return;
          info[el] = res.data[0][el];
        })
        // console.log({info});
        setDBInfo(info);
      })

  }, [])

  const items = useMemo(() => {
    return [{
      title: DBInfo.title_1,
      subtitle: DBInfo.subtitle_1,
      description: DBInfo.description_1,
      animData: laptopData,
    },
    {
      title: DBInfo.title_2,
      subtitle: DBInfo.subtitle_2,
      description: DBInfo.description_2,
      animData: folderData,
    },
    {
      title: DBInfo.title_3,
      subtitle: DBInfo.subtitle_3,
      description: DBInfo.description_3,
      animData: manData,
    }
    ]
  }, [DBInfo])

  return (
    <div className='m-customs'>
      <img className='m-customs-bg' src='Mobile/Customs/bg.svg' />
      <div className='m-customs-title'>
        <MobileTitle type='two_lines' first={{ text: 'ТАМОЖЕННОЕ', color: '#62929E' }} second={{ text: 'ОФОРМЛЕНИЕ', color: '#49525e' }} />
      </div>
      {items.map((item, i) => (<MobileCustomsItem key={Math.random()} content={item} animData={item.animData} />))}
    </div>
  )
}
