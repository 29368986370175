import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminMain.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";

export const AdminMain = () => {
  const tag = useRef();
  const title = useRef();
  const description = useRef();
  const firstName = useRef();
  const firstPosition = useRef();
  const firstPhone = useRef();
  const secondName = useRef();
  const secondPosition = useRef();
  const secondPhone = useRef();

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Main`).then((res) => {
      // console.log(res?.data?.[0]?.description);
      tag.current.value = res?.data?.[0]?.tag;
      title.current.value = res?.data?.[0]?.title;
      description.current.value = res?.data?.[0]?.description;
      firstName.current.value = res?.data?.[0]?.firstInfo?.name;
      firstPosition.current.value = res?.data?.[0]?.firstInfo?.position;
      firstPhone.current.value = res?.data?.[0]?.firstInfo?.phone;
      secondName.current.value = res?.data?.[0]?.secondInfo?.name;
      secondPosition.current.value = res?.data?.[0]?.secondInfo?.position;
      secondPhone.current.value = res?.data?.[0]?.secondInfo?.phone;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Main",
        content: JSON.stringify({
          description: description.current.value,
          tag: tag.current.value,
          title: title.current.value,
          firstInfo: {
            name: firstName.current.value,
            position: firstPosition.current.value,
            phone: firstPhone.current.value,
          },
          secondInfo: {
            name: secondName.current.value,
            position: secondPosition.current.value,
            phone: secondPhone.current.value,
          },
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };


  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  return (
    <AdminContentPage
      title="Главная страница"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={tag} l_title={"Тэг над названием"} />
      <LamaText l_ref={title} l_title={"Название"} />
      <LamaTextarea l_ref={description} l_title={"Описание под названием"} />
      <LamaText l_ref={firstName} l_title={"Имя первого менеджера"} />
      <LamaText l_ref={firstPosition} l_title={"Должность первого менеджера"} />
      <LamaText l_ref={firstPhone} l_title={"Телефон первого менеджера"} />
      <LamaText l_ref={secondName} l_title={"Имя второго менеджера"} />
      <LamaText
        l_ref={secondPosition}
        l_title={"Должность второго менеджера"}
      />
      <LamaText l_ref={secondPhone} l_title={"Телефон второго менеджера"} />
    </AdminContentPage>
  );
};
