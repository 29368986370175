import { useEffect, useState, useRef } from "react";
import styles from './main.module.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { mainThunks } from "../state/main.slice.ts";


const Main = ({ sid }) => {

  const dispatch = useAppDispatch();
  const { title, tag, secondInfo } = useAppSelector(state => state.mainData);
  useEffect(() => {
    if (!title) {
      dispatch(mainThunks.fetchInfo());
    }
    videoRef.current.play()
  }, []);

  const main = useRef();

  const handleProgress = (e) => {
    var range = 0;
    var bf = e.target.buffered;
    var time = e.target.currentTime;
    // console.log(bf, time);

    // while (!(bf.start(range) <= time && time <= bf.end(range))) {
    // range += 1;
    // }
    // var loadStartPercentage = bf.start(range) / e.target.duration;
    // var loadEndPercentage = bf.end(range) / e.target.duration;
    // var loadPercentage = loadEndPercentage - loadStartPercentage;
    // console.log(loadPercentage);
  }

  const videoRef = useRef();

  const [videoWidth, setVideoWidth] = useState(0)
  const changeVideoWidth = () => {
    let width = window.innerHeight * 1.8;
    if (width < window.innerWidth) width = window.innerWidth;
    setVideoWidth(width);
  }
  useEffect(() => {
    changeVideoWidth();
    window.addEventListener('resize', changeVideoWidth);
  }, [])


  return (<>
    <div className={styles.main} s-id={sid}>
      <div ref={main} className={styles.main_video}>
        <video ref={videoRef} autoPlay loop muted="muted" id="video" playsInline
          width={videoWidth} onProgress={handleProgress}>
          <source
            src="mainvideo_desktop.mp4"
            type="video/mp4"
          />
          <source
            src="test.mp4"
            type="video/mp4"
          />
          Ваш браузер устарел и не поддерживает наше видео
        </video>
      </div>

      <div className={styles.main_layout}>
        <img className={styles.main_title} src="desktop/main/title.svg" />

        <div className={styles.main_tag}>
          <p>{tag}</p>
        </div>


      </div>
    </div>
  </>
  )
}


export default Main;