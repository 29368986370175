import { React, useRef, useEffect, useState } from 'react'
import LamaImage from './LamaImage';
import './LamaProject.css'
import { LamaText } from './LamaText';
import { LamaTextarea } from './LamaTextarea';

export const LamaProject = ({ id, remove, values, info }) => {
  const titleRef = useRef();
  const descriptionRef = useRef();

  const [project, setProject] = useState();

  useEffect(() => {
    values.current.push(() => {
        return collectInfo.call(this, id);
    });

    if (info) {
      titleRef.current.value = info.title;
      descriptionRef.current.value = info.description;
      info.content.forEach(el=>{

        if (!el) return;

        if (el.slice(0,10) === 'data:image') {
          addImg(el);
        } else {
          addText(el);
        }
      })

      setProject(info.description.split('\n').map(line=>{
        let element = <></>;
        if (line === '') {
          element = <br/>
        } else if (line.slice(0,4) === 'img[') {
          const images = line.slice(5,-2).split('/').map(image=>{
            return <img src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image}/>
          })
          element = <div className='a-project-preview-images'>
            {images}
          </div>
        } else if (line.slice(0,3) === 'img') {
          const image = line.slice(4,-1);
          element = <div className='a-project-preview-image'><img src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + image}/></div>
        } else {
          element = <p>{line}</p>
        }
        return element
      }))
    }

    // titleRef.current.value = title ? title : '';
    // textRef.current.value = text ? text : '';

  }, [])

  const elements = useRef([]);

  const [_, setElements] = useState([]);

  const projectValues = useRef({});

  const removeEl = (id) => {
    delete projectValues.current[id];
    const newElements = elements.current.filter(el=>{
      return el.props.children[0].props.id !== id; 
    });
    elements.current = newElements;
    setElements(prev=>[...prev, 1]);
  }

  const addText = (prevValue) => {
    const id = (Math.random() * Math.random() * Math.random()).toString();
    projectValues.current[id] = prevValue ?? '';
    elements.current.push(<div key={id} className='l-project-element l-project-text'><LamaTextarea id={id} values={projectValues} l_title={`Текст`} prevValue={prevValue}/><button onClick={()=>{removeEl(id)}}>Удалить</button></div>)
    setElements(prev=>[...prev, 1]);
  }

  const addImg = (prevValue) => {
    const id = (Math.random() * Math.random() * Math.random()).toString();
    projectValues.current[id] = prevValue ?? '';
    elements.current.push(<div key={id} className='l-project-element l-project-img'><LamaImage id={id} values={projectValues} prevValue={prevValue}/><button onClick={()=>{removeEl(id)}}>Удалить</button></div>)
    setElements(prev=>[...prev, 1]);
  }

  const collectInfo = (id) => {
    if (!titleRef?.current?.value) return {id, result: []};
    if (!descriptionRef?.current?.value) return {id, result: []};
    const result = Object.keys(projectValues.current).map(key=>{
      return projectValues.current[key]});
    result.unshift(descriptionRef.current.value);
    result.unshift(titleRef.current.value);
    return {id, result};
  }

  return (
    <div className='l-project'>
       <LamaText l_ref={titleRef} l_title={`Заголовок`} />
       <LamaTextarea l_ref={descriptionRef} l_title={`Описание`} />
      {/* {elements.current.map(el=>el)}
      <button onClick={()=>{addText()}}>Добавить текст</button>
      <button onClick={()=>{addImg()}}>Добавить картинку</button> */}
      {project}
      <button onClick={remove}>Удалить</button>
    </div>
  )
}
