import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminGeo.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";
import { LamaMap } from "../components/Inputs/LamaMap";

export const AdminGeo = () => {
  const title_color = useRef();
  const title_dark = useRef();

  const subtitle = useRef();
  
  const title_1 = useRef();
  const src_1 = useRef();
  const description_1 = useRef();
  const title_2 = useRef();
  const src_2 = useRef();
  const description_2 = useRef();
  const title_3 = useRef();
  const src_3 = useRef();
  const description_3 = useRef();
  const title_4 = useRef();
  const src_4 = useRef();
  const description_4 = useRef();




  useEffect(() => {
    // console.log(setPoints.current);
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Geo`).then((res) => {
      title_color.current.value = res?.data?.[0]?.title_color;
      title_dark.current.value = res?.data?.[0]?.title_dark;
      subtitle.current.value = res?.data?.[0]?.subtitle;

      title_1.current.value = res?.data?.[0]?.title_1;
      src_1.current.value = res?.data?.[0]?.src_1;
      description_1.current.value = res?.data?.[0]?.description_1;
      setPoints.current.rail(res?.data?.[0]?.points_1);

      title_2.current.value = res?.data?.[0]?.title_2;
      src_2.current.value = res?.data?.[0]?.src_2;
      description_2.current.value = res?.data?.[0]?.description_2;
      setPoints.current.ship(res?.data?.[0]?.points_2);

      title_3.current.value = res?.data?.[0]?.title_3;
      src_3.current.value = res?.data?.[0]?.src_3;
      description_3.current.value = res?.data?.[0]?.description_3;
      setPoints.current.avia(res?.data?.[0]?.points_3);

      title_4.current.value = res?.data?.[0]?.title_4;
      src_4.current.value = res?.data?.[0]?.src_4;
      description_4.current.value = res?.data?.[0]?.description_4;
      setPoints.current.auto(res?.data?.[0]?.points_4);
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    const data = {
      title_color: title_color.current.value,
      title_dark: title_dark.current.value,
      subtitle: subtitle.current.value,

      title_1: title_1.current.value,
      src_1: src_1.current.value,
      description_1: description_1.current.value,
      points_1: getInfo.current.rail(),

      title_2: title_2.current.value,
      src_2: src_2.current.value,
      description_2: description_2.current.value,
      points_2: getInfo.current.ship(),

      title_3: title_3.current.value,
      src_3: src_3.current.value,
      description_3: description_3.current.value,
      points_3: getInfo.current.avia(),

      title_4: title_4.current.value,
      src_4: src_4.current.value,
      description_4: description_4.current.value,
      points_4: getInfo.current.auto(),
    };

    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Geo",
        content: JSON.stringify(data),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const getInfo = useRef([]);
  const setPoints = useRef([]);

  return (
    <AdminContentPage
      title="География деятельности"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={title_color} l_title={"Цветная часть заголовка"} />
      <LamaText l_ref={title_dark} l_title={"Темная часть заголовка"} />
      <LamaText l_ref={subtitle} l_title={"Подзаголовок"} />

      <LamaText l_ref={title_1} l_title={"Название 1"} />
      <LamaTextarea l_ref={description_1} l_title={"Описание 1"} />
      <LamaText l_ref={src_1} l_title={"Путь к файлу 1"} l_disabled={true}/>
      <LamaMap  l_title={"Настройка иконок на карте 1"} type={'rail'} getInfo={getInfo} setPoints={setPoints}/>

      <LamaText l_ref={title_2} l_title={"Название 2"} />
      <LamaTextarea l_ref={description_2} l_title={"Описание 2"} />
      <LamaText l_ref={src_2} l_title={"Путь к файлу 2"}  l_disabled={true}/>
      <LamaMap l_title={"Настройка иконок на карте 2"} type={'ship'} getInfo={getInfo} setPoints={setPoints}/>

      <LamaText l_ref={title_3} l_title={"Название 3"} />
      <LamaTextarea l_ref={description_3} l_title={"Описание 3"} />
      <LamaText l_ref={src_3} l_title={"Путь к файлу 3"}  l_disabled={true}/>
      <LamaMap l_title={"Настройка иконок на карте 3"} type={'avia'} getInfo={getInfo} setPoints={setPoints}/>

      <LamaText l_ref={title_4} l_title={"Название 4"} />
      <LamaTextarea l_ref={description_4} l_title={"Описание 4"} />
      <LamaText l_ref={src_4} l_title={"Путь к файлу 4"}  l_disabled={true}/>
      <LamaMap l_title={"Настройка иконок на карте 4"} type={'auto'} getInfo={getInfo} setPoints={setPoints}/>

    </AdminContentPage>
  );
};
