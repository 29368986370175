import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import {
  IServiceDescriptions,
  IServicePageDescription,
  IAreasConfig,
  IAreasPageDescription,
  IAreasState,
  ISliderItem,
} from "../state/areas.types";
import { areasActions } from "../state/areas.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let areasConfig: IAreasConfig = require("./../state/areas.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().areasData.fetched) return;
  thunkApi.dispatch(areasActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(areasConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  console.log({ info });

  const areasPageDescription: IAreasPageDescription = {
    title_color: info.title_color,
    title_dark: info.title_dark,
    slider: [
      [
        {
          title: info.title_1,
          description: info.description_1,
          src: info.src_1,
        },
        {
          title: info.title_6,
          description: info.description_6,
          src: info.src_6,
        },
      ],
      [
        {
          title: info.title_2,
          description: info.description_2,
          src: info.src_2,
        },
        {
          title: info.title_7,
          description: info.description_7,
          src: info.src_7,
        },
      ],
      [
        {
          title: info.title_3,
          description: info.description_3,
          src: info.src_3,
        },
        {
          title: info.title_8,
          description: info.description_8,
          src: info.src_8,
        },
      ],
      [
        {
          title: info.title_4,
          description: info.description_4,
          src: info.src_4,
        },
        {
          title: info.title_9,
          description: info.description_9,
          src: info.src_9,
        },
      ],
      [
        {
          title: info.title_5,
          description: info.description_5,
          src: info.src_5,
        },
        {
          title: info.title_10,
          description: info.description_10,
          src: info.src_10,
        },
      ],
    ],
  };

  // areasPageDescription.slider = info.sliderInfo.map((item) => ({
  //   name: item.title,
  //   position: item.position,
  //   bio: item.text,
  //   src: item.image,
  // }));

  thunkApi.dispatch(areasActions.setAreasDescriptions(areasPageDescription));
};

const emitSlideNext = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(areasActions.setSlideNextEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(areasActions.setSlideNextEvent(false));
  // }, 500);
};

const emitSlidePrev = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(areasActions.setSlidePrevEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(areasActions.setSlidePrevEvent(false));
  // }, 500);
};

export const areasExtraReducers = {
  "thunk/fetchInfo/pending": (state: IAreasState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: IAreasState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: IAreasState) => {
    console.log("rejected");
  },
};

export const areasThunksCallbacksAsync = {
  fetchInfo,
  emitSlideNext,
  emitSlidePrev,
};
