import axios from "axios";

export async function fetchDataFromDB(moduleName) {
    return new Promise(res => {
        try {
            axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=${moduleName}`)
                .then(result => {
                    const info = {};
                    if (!result?.data?.[0]) throw 'no data';
                    Object.keys(result?.data?.[0]).forEach(el => {
                        if (el === '_id') return;
                        info[el] = result.data[0][el];
                    })
                    return res({ status: true, info });
                })
        } catch (e) {
            return res({ status: false, error: e });
        }
    })
}
