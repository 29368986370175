import { createSlice } from "@reduxjs/toolkit";
import { sliderReducers } from "./slider.actions.ts";
import { sliderInitialState } from "./slider.state.ts";
import { sliderThunksCallbacksAsync } from "./../logic/slider.thunks.async.ts";
import { sliderThunksCallbacksSync } from "./../logic/slider.thunks.sync.ts";
import { createThunksForExport } from "./../../../store/store.services.ts";
import { RootState } from "./../../../store/store.ts";

export const sliderThunks: any = createThunksForExport({
  async: sliderThunksCallbacksAsync,
  sync: sliderThunksCallbacksSync,
});

export const sliderDataSlice = createSlice({
  name: "sliderData",
  initialState: sliderInitialState,
  reducers: sliderReducers,
});

export const sliderStaticData = (state: RootState) => state.sliderData;

export const sliderActions = sliderDataSlice.actions;

export default sliderDataSlice.reducer;
