import React from 'react'
import './AdminContentPage.css'

export const AdminContentPage = ({children, title, success, error, warning, progress, save}) => {
  return (
    <>
        <div className='a-top-menu'>
            <p className='a-title'>{title}</p>
            <div className="a-save">
                <div className="a-button" onClick={save}>
                    <p>Сохранить</p>
                </div>
                {success && <p className="a-info a-success">Изменения сохранены</p>}
                {error && <p className="a-info a-error">Ошибка</p>}
                {warning && <p className="a-info a-warning">Есть несохраненные изменения</p>}
                {progress && <p className="a-info a-progress">Отправка данных на сервер...</p>}
            </div>
        </div>
        <div className='a-inputs'>{children}</div>
    </>
  )
}
