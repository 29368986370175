import "./Header.css";
import HeaderButton from "../HeaderButton/HeaderButton";
import { useState } from "react";
import { Feedback } from "../Feedback/Feedback";
import { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { useAppSelector, useAppDispatch } from "./../../hooks/hooksRedux.ts";
import styles from "./header.module.css";
import { sliderThunks } from "./../../plugins/slider/state/slider.slice.ts";
import { feedbackThunks } from "../Feedback/state/feedback.slice.ts";
import HeaderLinkButton from "../HeaderLinkButton/HeaderLinkButton.jsx";

export const DEFAULT_HEADER_NEAR_BACKGROUND = "transparent";
export const MAIN_HEADER_NEAR_BACKGROUND = "#35363a";
export const DEFAULT_HEADER_FURTHER_BACKGROUND =
  "linear-gradient(90deg,rgba(255, 255, 255, 1) 21%,rgba(235, 245, 255, 1) 32%,rgba(235, 245, 255, 1) 62%,rgba(219, 233, 247, 1) 72%)";
export const AREAS_HEADER_FURTHER_BACKGROUND = "transparent";
export const MAIN_INFO_COLOR = "var(--text-bright)";
export const DEFAULT_INFO_COLOR = "var(--text-dark-lighten2)";

const Header = ({ redirect }) => {
  const dispatch = useAppDispatch();
  const [style, setStyle] = useState({
    top: 0,
  });

  const block = useRef(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showHideIcon, setShowHideIcon] = useState(false);

  const [headerNearBackground, setHeaderNearBackground] = useState(
    DEFAULT_HEADER_NEAR_BACKGROUND
  );
  const [headerFurtherBackground, setHeaderFurtherBackground] = useState(
    DEFAULT_HEADER_FURTHER_BACKGROUND
  );

  const [DBInfo, setDBInfo] = useState({});

  useEffect(() => {
    axios
      .get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Header`)
      .then((res) => {
        const info = {};

        if (!res?.data?.[0]) return;

        Object.keys(res?.data?.[0]).forEach((el) => {
          if (el === "_id") return;
          info[el] = res.data[0][el];
        });

        setDBInfo(info);
      });
  }, []);

  useEffect(() => {
    if (!DBInfo.main) return;
    setMenuButtons([
      {
        text: DBInfo.main ?? "Главная",
        link: "main",
      },
      {
        text: DBInfo.keys ?? "Услуги",
        link: "key",
      },
      // {
      //   text: DBInfo.team,
      //   link: 'team',
      // },
      {
        text: DBInfo.areas ?? "Деятельность",
        link: "areas",
      },
      {
        text: DBInfo.projects ?? "Проекты",
        link: "projects",
      },
      {
        text: "Контакты",
        link: "contacts",
      },
    ]);
    setFeedbackText(DBInfo.feedback);
  }, [DBInfo]);

  const showHeader = () => {
    setStyle({ top: 0 });
    setShowIcon(false);
  };
  const hideHeader = () => {
    setStyle({ top: "-60px" });
    setShowIcon(true);
  };

  const manualShowHeader = () => {
    block.current = true;
    showHeader();
    setShowHideIcon(true);
  };
  const manualHideHeader = () => {
    block.current = false;
    hideHeader();
    setShowHideIcon(false);
  };

  const [menuButtons, setMenuButtons] = useState([]);

  const [active, setActive] = useState(window.location.hash.slice(1));

  const { getInKey, currentKey } = useAppSelector((state) => state.sliderData);
  useEffect(() => {
    if (getInKey === "main") {
      setHeaderNearBackground(MAIN_HEADER_NEAR_BACKGROUND);
      setInfoColor(MAIN_INFO_COLOR);
    } else if (getInKey === "team" && currentKey !== "areas") {
    } else if (getInKey === "team") {
    } else if (getInKey === "areas" && currentKey === "team") {
    } else if (getInKey === "areas" && currentKey === "geo") {
    } else if (getInKey === "geo" && currentKey === "areas") {
    } else if (getInKey === "division" && currentKey === "agency") {
    } else if (getInKey === "agency" && currentKey === "division") {
    } else if (getInKey === "china" && currentKey === "division") {
    } else if (getInKey === "division" && currentKey === "china") {
    } else if (getInKey === "contacts") {
      setHeaderNearBackground(MAIN_HEADER_NEAR_BACKGROUND);
      setInfoColor(MAIN_INFO_COLOR);
    } else {
      setInfoColor(DEFAULT_INFO_COLOR);
      setHeaderNearBackground(DEFAULT_HEADER_NEAR_BACKGROUND);
      setHeaderFurtherBackground(DEFAULT_HEADER_FURTHER_BACKGROUND);
    }
    setActive(getInKey);
  }, [getInKey]);

  useEffect(() => {
    if (currentKey === "main") {
      setHeaderNearBackground(MAIN_HEADER_NEAR_BACKGROUND);
      setInfoColor(MAIN_INFO_COLOR);
    } else if (currentKey === "areas") {
      setHeaderFurtherBackground(AREAS_HEADER_FURTHER_BACKGROUND);
    } else if (currentKey === "team") {
      setHeaderFurtherBackground(AREAS_HEADER_FURTHER_BACKGROUND);
    } else if (currentKey === "geo") {
      setHeaderFurtherBackground(AREAS_HEADER_FURTHER_BACKGROUND);
    } else if (currentKey === "division") {
      setHeaderFurtherBackground(AREAS_HEADER_FURTHER_BACKGROUND);
    } else if (currentKey === "agency") {
      setHeaderFurtherBackground(AREAS_HEADER_FURTHER_BACKGROUND);
    } else if (currentKey === "china") {
      setHeaderFurtherBackground(AREAS_HEADER_FURTHER_BACKGROUND);
    } else if (currentKey === "contacts") {
      setHeaderNearBackground(MAIN_HEADER_NEAR_BACKGROUND);
      setInfoColor(MAIN_INFO_COLOR);
    } else {
      setHeaderNearBackground(DEFAULT_HEADER_NEAR_BACKGROUND);
    }
    setActive(currentKey);
  }, [currentKey]);

  const [showFeedback, setShowFeedback] = useState();
  const toggleFeedback = () => {
    dispatch(feedbackThunks.openFeedback());
  };

  useEffect(() => {
    if (showFeedback === undefined) return;

    if (showFeedback) {
      dispatch(sliderThunks.blockPreviousSlide());
      dispatch(sliderThunks.blockNextSlide());
    } else {
      dispatch(sliderThunks.unblockPreviousSlide());
      dispatch(sliderThunks.unblockNextSlide());
    }
  }, [showFeedback]);

  const [feedbackText, setFeedbackText] = useState("");

  const { secondInfo } = useAppSelector((state) => state.mainData);

  const [infoColor, setInfoColor] = useState({ color: "var(--text-bright)" });

  return (
    <div className="header-bg" style={{ background: headerFurtherBackground }}>
      <div className="header" style={{ background: headerNearBackground }}>
        <div className="header-layout">
          {showIcon && (
            <img
              className="header-icon"
              src="header-menu-icon.svg"
              alt="Раскрыть меню"
              onClick={manualShowHeader}
            />
          )}
          <div style={style} className="header-buttons">
            <HeaderButton
              type="feedback"
              text={feedbackText}
              toggleFeedback={toggleFeedback}
              style={{
                marginLeft: showHideIcon ? "20px" : "",
                filter: showHideIcon ? "drop-shadow(0px 0px 2px #3a3a3a)" : "",
              }}
            />

            <div className={styles.main_info} style={{ color: infoColor }}>
              <p className={styles.main_name}>{secondInfo.name}:</p>
              <p className={styles.main_phone}>{secondInfo.phone}</p>
            </div>

            {menuButtons.map((el) => (
              <HeaderButton
                text={el.text}
                key={el.text}
                link={el.link}
                redirect={redirect}
                active={active}
                setActive={setActive}
                setBackground={setHeaderNearBackground}
              ></HeaderButton>
            ))}
            <HeaderLinkButton text={"Информация"} link={"/info"} />

            {showHideIcon && (
              <img
                className="header-hide-icon"
                src="header-hide-icon.svg"
                alt="Раскрыть меню"
                onClick={manualHideHeader}
              />
            )}
          </div>
        </div>
        {/* {showFeedback && <Feedback toggleFeedback={toggleFeedback} />} */}
      </div>
    </div>
  );
};

export default Header;
