import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminTeam.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";
import { LamaSliderList } from "../components/Inputs/LamaSliderList";

export const AdminTeam = () => {
  const title_color = useRef();
  const title_dark = useRef();
  const description = useRef();
  const contact = useRef();
  const sliderInfo = useRef();

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Team`).then((res) => {
      title_color.current.value = res?.data?.[0]?.title_color;
      title_dark.current.value = res?.data?.[0]?.title_dark;
      description.current.value = res?.data?.[0]?.description;
      contact.current.value = res?.data?.[0]?.contact;
      sliderInfo.current = res?.data?.[0]?.sliderInfo;
      setSliderInfo.current();
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Team",
        content: JSON.stringify({
          title_color: title_color.current.value,
          title_dark: title_dark.current.value,
          description: description.current.value,
          contact: contact.current.value,
          sliderInfo: getResults.current()
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  const setSliderInfo = useRef();

  const getResults = useRef({current: null});
  return (
    <AdminContentPage
      title="Наша команда"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={title_color} l_title={"Цветная часть заголовка"} />
      <LamaText l_ref={title_dark} l_title={"Темная часть заголовка"} />
      <LamaTextarea l_ref={description} l_title={"Описание под заголовком"} />
      <LamaText l_ref={contact} l_title={"Текст на кнопке"} />
      <LamaSliderList getResults={getResults} sliderInfo={sliderInfo} setSliderInfo={setSliderInfo}/>
    </AdminContentPage>
  );
};
