import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminAcc.css";
import { LamaText } from "../components/Inputs/LamaText";
import { AdminContentPage } from "./AdminContentPage";

export const AdminAcc = () => {
  const pass = useRef();


  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Acc`).then((res) => {
      pass.current.value = res?.data?.[0]?.pass;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Acc",
        content: JSON.stringify({
          pass: pass.current.value,
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  return (
    <AdminContentPage
      title="Настройка почтового сервера"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={pass} l_title={"Пароль для входа в админку"} l_type={"password"}/>

    </AdminContentPage>
  );
};