import React, { useEffect, useState } from 'react';
import './MobileContacts.css';
import { MobileTitle } from '../Title/MobileTitle';
import axios from 'axios';
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { MobileModal } from '../Modal/MobileModal';
import { MobileFeedback } from '../MobileFeedback/MobileFeedback';

export const MobileContacts = () => {

  const [DBInfo, setDBInfo] = useState({});

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Contacts`)
      .then(res => {
        const info = {};
        if (!res?.data?.[0]) return;

        Object.keys(res?.data?.[0]).forEach(el => {
          if (el === '_id') return;
          info[el] = res.data[0][el];
        })
        console.log(info);
        setDBInfo(info);
      })

  }, [])

  useEffect(() => {
    if (!DBInfo.coordinates) return;
    setRefreshMaps(null);
  }, [DBInfo])
  const [refreshMaps, setRefreshMaps] = useState(false);

  useEffect(() => {
    if (refreshMaps === null) {
      setRefreshMaps(true)
    }
  }, [refreshMaps])
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <div className='m-contacts'>
        <img className='m-contacts-bg' src='Mobile/Contacts/bg.svg' />
        <div className='m-contacts-title'>
          <MobileTitle first={{ text: 'НАШИ', color: '#fff' }} second={{ text: 'КОНТАКТЫ', color: '#49525e' }} />
        </div>
        <div className='m-contacts-info'>
          <>
            {
              DBInfo.text?.split("\n").map((el, i) => {
                if (el === '') {
                  return <br key={Math.random()} />
                }
                return <p key={Math.random()}>{el}</p>;
              })
            }
          </>

        </div>
        <div className='m-contacts-map'>
          <img src="Mobile/Contacts/map.jpg" />

          {
            refreshMaps &&
            <YMaps>
              <div>
                <Map defaultState={{ center: DBInfo.coordinates, zoom: DBInfo.zoom }} width='600px' height='238px'>
                  <Placemark geometry={{
                    type: "Point",
                    coordinates: DBInfo.point_coordinates
                  }} properties={{
                    preset: 'islands#icon',
                    iconCaption: DBInfo.point_title
                  }}></Placemark>
                </Map>

              </div>
            </YMaps>
          }
        </div>

        <div className='m-contacts-footer'>
          <button onClick={() => setIsShowModal(true)}>
            {DBInfo.contact}
          </button>
        </div>

        <div className='m-contacts-logo'>
          <img src='Mobile/Main/logo.svg' alt='' />
        </div>

      </div>
      {isShowModal && <MobileModal>
        <MobileFeedback toggleMobileFeedback={() => { setIsShowModal(false) }} />
      </MobileModal>}
    </>
  )
}
