import { PayloadAction } from "@reduxjs/toolkit";
import { IFeedbackState } from "./feedback.types";

function setFeedbackState(
  state: IFeedbackState,
  { payload: newState }: PayloadAction<IFeedbackState>
) {
  return newState;
}

function setFetched(
  state: IFeedbackState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

function setShow(
  state: IFeedbackState,
  { payload: show }: PayloadAction<boolean>
) {
  state.show = show;
}

function setVariant(
  state: IFeedbackState,
  { payload: variant }: PayloadAction<"normal" | "team">
) {
  state.variant = variant;
}

export const feedbackReducers = {
  setFeedbackState,
  setFetched,
  setShow,
  setVariant,
};
