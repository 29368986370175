import {React, useState, useEffect, useRef} from 'react';
import './MobileFeedback.css';
import axios from 'axios';

export const MobileFeedback = ({toggleMobileFeedback, isTeamMobileFeedback}) => {

  console.log({isTeamMobileFeedback})

  const name = useRef();
  const phone = useRef();
  const company = useRef();
  const email = useRef();
  const message = useRef();
  const to = useRef();
  const from = useRef();
  const contacts = useRef();
    
  const [DBInfo, setDBInfo] = useState({});
  const [emailInfo, setEmailInfo] = useState({});

  useEffect(()=>{

    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=FeedbackModal`)
    .then(res => {
      const info = {};
      if (!res?.data?.[0]) return;

      Object.keys(res?.data?.[0]).forEach(el=>{
        if (el === '_id') return;
        info[el] = res.data[0][el];
      })
    //   console.log(info);
      setDBInfo(info);
    })

    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Email`)
    .then(res => {
      const info = {};
      if (!res?.data?.[0]) return;

      Object.keys(res?.data?.[0]).forEach(el=>{
        if (el === '_id') return;
        info[el] = res.data[0][el];
      })
      setEmailInfo(info);
    })

  },[])
  useEffect(()=>{
    if (!DBInfo.coordinates) return;
    
  },[DBInfo])

  const send = (type) => {

    if (type === 'normal' && (
        name.current.value === '' ||
        phone.current.value === ''
    )) {
        showValidationError();
        return;
    }

    if (type === 'team' && (
        to.current.value === '' ||
        from.current.value === '' ||
        contacts.current.value === ''
    )) {
        showValidationError();
        return;
    }
    setValidateError(false);


    axios
    .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/mail`, {
      data: JSON.stringify({
        host: emailInfo.host,
        port: emailInfo.port,
        from: emailInfo.from,
        type,
        client: type === 'normal' ?
         {
            name: name.current.value,
            phone: phone.current.value,
            company: company.current.value,
            email: email.current.value,
            message: message.current.value,
         } :
         {
            to: to.current.value,
            from: from.current.value,
            contacts: contacts.current.value,
            message: message.current.value,
         }
      }),
    })
    .then(({ data }) => {
      if (data === true) {
        showSuccess();
      } else {
        showError();
      }
    })
    .catch((e) => {
      showError();
    });
  }

  const showSuccess = () => {
    setModal(true);
}
  const showError = () => {
    setError(true);
    setModal(true);
}

  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);


  const showValidationError = () => {
    setValidateError(true);
  }

  const [validateError, setValidateError] = useState(false);

  return (
    <div className='mobile-feedback-modal' onClick={(e)=>{if(e.target !== e.currentTarget) return; toggleMobileFeedback()}}>
        {modal && <div className='mobile-feedback-success-background'>

                {!error && <h3>{DBInfo.success_title}</h3>}
                {!error && <p>{DBInfo.success_text}</p>}
                {error && <h3>{DBInfo.error_title}</h3>}
                {error && <p>{DBInfo.error_text}</p>}
                <button onClick={()=>{setModal(false);toggleMobileFeedback()}}>{DBInfo.modal_close}</button>

            </div>}
        {!modal && <div className='mobile-feedback-background'>

            <div className='mobile-feedback-inputs'>
                <p className='mobile-feedback-title'>{!isTeamMobileFeedback ? DBInfo.title : DBInfo.team_title }</p>
                <div className='mobile-feedback-form'>
                    {!isTeamMobileFeedback && <><input type="text" className='mobile-feedback-input' placeholder={DBInfo.input_1} ref={name}/>
                    <input type="text" className='mobile-feedback-input' placeholder={DBInfo.input_2} ref={phone}/>
                    <input type="text" className='mobile-feedback-input' placeholder={DBInfo.input_3} ref={company}/>
                    <input type="text" className='mobile-feedback-input' placeholder={DBInfo.input_4} ref={email}/>
                    <textarea className='mobile-feedback-textarea' placeholder={DBInfo.textarea} ref={message}/>
                    <div className='mobile-feedback-button' onClick={()=>{send('normal')}}>
                    <p >{DBInfo.button}</p>
                    </div>
                    {validateError && <p className='mobile-feedback-error-normal'>{DBInfo.validation_text}</p>}
                    
                    
                    
                    </>}
                    {isTeamMobileFeedback && <><input type="text" className='mobile-feedback-input' placeholder={DBInfo.team_input_1} ref={to}/>
                    <input type="text" className='mobile-feedback-input' placeholder={DBInfo.team_input_2} ref={from}/>
                    <input type="text" className='mobile-feedback-input' placeholder={DBInfo.team_input_3} ref={contacts}/>
                    <textarea className='mobile-feedback-textarea' placeholder={DBInfo.team_textarea} ref={message}/>
                    <div className='mobile-feedback-button' onClick={()=>{send('team')}}>
                    <p >{DBInfo.team_button}</p>
                    </div>
                    {validateError && <p className='mobile-feedback-error'>{DBInfo.validation_text}</p>}

                    
                    
                    
                    </>}
                </div>
            </div>

            <img className="mobile-feedback-close" src="close.svg" alt="X" onClick={toggleMobileFeedback}/>

        </div>}
    </div>
  )
}
