import { createSlice } from "@reduxjs/toolkit";
import { agencyReducers } from "./agency.actions.ts";
import { agencyInitialState } from "./agency.state.ts";
import { agencyThunksCallbacksAsync } from "../logic/agency.thunks.async.ts";
import { agencyThunksCallbacksSync } from "../logic/agency.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const agencyThunks: any = createThunksForExport({
  async: agencyThunksCallbacksAsync,
  sync: agencyThunksCallbacksSync,
});

export const agencyDataSlice = createSlice({
  name: "agencyData",
  initialState: agencyInitialState,
  reducers: agencyReducers,
});

export const agencyStaticData = (state: RootState) => state.agencyData;

export const agencyActions = agencyDataSlice.actions;

export default agencyDataSlice.reducer;
