import { IChinaState, IChinaItem } from "./china.types.ts";

export const chinaInitialState: IChinaState = {
  title_color: "",
  title_dark: "",
  subtitle: "",
  address_title: "",
  address_description: "",
  items: [] as IChinaItem[],
};
