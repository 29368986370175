import React, { useEffect, useState } from "react";
import { classes } from "../../../../libs/classname";
import styles from "./warning.module.css";
import { WarningHeader } from "./WarningHeader";
import { warningThunks } from "../state/warning.slice.ts";

export const Warning = ({ sid }) => {
  return (
    <div s-id={sid} className={styles.container}>
      <div className={styles.background_layout}>
        <WarningHeader />
        <div className={styles.card}>
          <p>Участились случаи мошеннических действий.</p>
          <br />
          <p>Прошу обратить внимание</p>
          <br />
          <p>
            Наш официальный сайт{" "}
            <a href="http://prologix.ru">http://prologix.ru</a>
          </p>
          <br />
          <p>
            Наши банковские реквизиты не могут быть легко изменены. После
            получения электронного письма для изменения информации о наших
            банковских реквизитах, пожалуйста, сообщите нам тел. +7 499 110 12
            22 или email info@prologix.su, igor.ustinov@prologix.ru и уточните
            всю информацию!
          </p>
          <br />
          <p>
            Изменение реквизитов в контрактах и договорах возможно путем
            официального согласования сторонами и подписанием соглашения.
          </p>
          <br />
          <i>
            Юридическое уведомление: Информация, содержащаяся в данном
            сообщении, является конфиденциальной и/или охраняется законом. Если
            Вы не являетесь адресатом данного сообщения, пожалуйста, свяжитесь с
            отправителем и удалите это сообщение. Любое несанкционированное
            копирование данного сообщения или несанкционированное
            распространение содержащейся в нем информации запрещено.
            Ответственность за открытие данного письма в соответствии с
            принципом необходимости получения информации лежит на Вас. В случае
            нарушения этой ответственности наша компания может предъявить Вам
            иск о возмещении всех прямых и косвенных убытков. В случае получения
            вашего запроса на фрахт и иные услуги, и акцепта с нашей стороны, в
            виде выставленного счета, данные действия будут считаться
            конклюдентными, и что все условия подробно изучены и согласованы по
            итогам переговоров.
          </i>
        </div>
      </div>
    </div>
  );
};
