import { createSlice } from '@reduxjs/toolkit';
import { mainReducers } from './main.actions.ts';
import { mainInitialState } from './main.state.ts';
import { mainThunksCallbacksAsync } from './../logic/main.thunks.async.ts';
import { mainThunksCallbacksSync } from './../logic/main.thunks.sync.ts';
import { createThunksForExport } from '../../../../store/store.services.ts';
import { RootState } from '../../../../store/store.ts';

export const mainThunks: any = createThunksForExport({
  async: mainThunksCallbacksAsync,
  sync: mainThunksCallbacksSync
})

export const mainDataSlice = createSlice({
  name: 'mainData',
  initialState: mainInitialState,
  reducers: mainReducers
})

export const mainStaticData = (state: RootState) =>
  state.mainData;

export const mainActions = mainDataSlice.actions;

export default mainDataSlice.reducer;
