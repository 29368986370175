import React, { useEffect, useMemo, useState } from 'react';
import './MobileHeader.css';
import { MobileHeaderMenu } from './MobileHeaderMenu';
import axios from 'axios';

export const MobileHeader = () => {

  const [DBInfo, setDBInfo] = useState({});

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Header`)
      .then(res => {
        const info = {};
        if (!res?.data?.[0]) return;
        Object.keys(res?.data?.[0]).forEach(el => {
          if (el === '_id') return;
          info[el] = res.data[0][el];
        });
        setDBInfo(info);
      })
  }, [])

  const menuItems = useMemo(() => {
    return [
      { key: 0, title: DBInfo.main, scroll: 0 },
      { key: 1, title: DBInfo.keys, scroll: 910 },
      // {key: 2, title: DBInfo.team, scroll: 910 + 800},
      { key: 3, title: DBInfo.areas, scroll: 910 + 960 },
      { key: 4, title: DBInfo.projects, scroll: 910 + 940 + 1200 },
      { key: 5, title: 'Контакты', scroll: 10000 },
    ]
  }, [DBInfo]);

  const [isShowMenu, setIsShowMenu] = useState(false);
  const toggleMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  return (
    <>
      <div className='m-header'>
        <h3>PROLOGIX</h3>
        <img src='Mobile/Header/burger-icon.svg' alt='=' onClick={toggleMenu} />
      </div>
      {
        isShowMenu &&
        <div className='m-header-menu-container'>
          <MobileHeaderMenu items={menuItems} setIsShowMenu={setIsShowMenu} />
        </div>
      }
    </>
  )
}
