import { createSlice } from "@reduxjs/toolkit";
import { teamReducers } from "./team.actions.ts";
import { teamInitialState } from "./team.state.ts";
import { teamThunksCallbacksAsync } from "./../logic/team.thunks.async.ts";
import { teamThunksCallbacksSync } from "./../logic/team.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const teamThunks: any = createThunksForExport({
  async: teamThunksCallbacksAsync,
  sync: teamThunksCallbacksSync,
});

export const teamDataSlice = createSlice({
  name: "teamData",
  initialState: teamInitialState,
  reducers: teamReducers,
});

export const teamStaticData = (state: RootState) => state.teamData;

export const teamActions = teamDataSlice.actions;

export default teamDataSlice.reducer;
