import React from 'react';
import './MobileGeoItem.css';

export const MobileGeoItem = ({type, content}) => {
  return (
    <>
      <div className='m-geo-item'>
        <div className='m-geo-bg' style={{left: type === 'left' ? '-14vw' : '', right: type === 'right' ? '-14vw' : ''}}></div>
        <div className='m-geo-image'>
          <img src={content.iconSrc}/>
        </div>
        <div className='m-geo-text'>
          <h3>{content.title}</h3>
          <p>{content.text}</p>
        </div>
      </div>
    </>
  )
}
