import { PayloadAction } from "@reduxjs/toolkit";
import { IChinaState } from "./china.types";

function setChinaState(
  state: IChinaState,
  { payload: newState }: PayloadAction<IChinaState>
) {
  return newState;
}

function setFetched(
  state: IChinaState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const chinaReducers = {
  setChinaState,
  setFetched
};
