import React, { useEffect, useState, useMemo } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './customs.module.css';
import { CustomsHeader } from './CustomsHeader';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { customsThunks } from '../state/customs.slice.ts';
import Rive from '@rive-app/react-canvas';

export const Customs = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { customsData } = useAppSelector(state => state);
    useEffect(() => {
        if (!customsData?.items?.[0]?.title) {
            dispatch(customsThunks.fetchInfo());
        }
    }, []);

    const cards = useMemo(() => {
        const items = customsData.items;
        if (!items) {
            return [{}, {}, {}];
        }
        return [{
            title: items?.[0]?.title,
            subtitle: items?.[0]?.subtitle,
            description: items?.[0]?.description
        },
        {
            title: items?.[1]?.title,
            subtitle: items?.[1]?.subtitle,
            description: items?.[1]?.description
        },
        {
            title: items?.[2]?.title,
            subtitle: items?.[2]?.subtitle,
            description: items?.[2]?.description
        }
        ]
    }, [customsData?.items?.[0]?.title])


    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                <CustomsHeader />
                <div className={styles.icons_container}>
                    <div className={styles.icons}>
                        <Rive src="/desktop/customs/icons.riv" />
                    </div>
                </div>

                <div className={styles.cards}>
                    {Array.isArray(cards) && cards.map((card, i) => {
                        return (
                            <div key={i + Math.random()} className={styles.card}>
                                <div className={styles.main}>
                                    <p className={styles.subtitle}>{card.title}</p>
                                    <p className={styles.subtitle_second}>{card.subtitle}</p>
                                </div>
                                <div className={styles.sub}>
                                    <p>{card.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>

        </div>
    )
}
