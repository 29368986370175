import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminFeedbackModal.css";
import { LamaText } from "../components/Inputs/LamaText";
import { LamaTextarea } from "../components/Inputs/LamaTextarea";
import { AdminContentPage } from "./AdminContentPage";

export const AdminFeedbackModal = () => {
  const logo = useRef();
  const text = useRef();
  const title = useRef();
  const input_1 = useRef();
  const input_2 = useRef();
  const input_3 = useRef();
  const input_4 = useRef();
  const textarea = useRef();
  const button = useRef();
  const team_title = useRef();
  const team_input_1 = useRef();
  const team_input_2 = useRef();
  const team_input_3 = useRef();
  const team_textarea = useRef();
  const team_button = useRef();
  const validation_text = useRef();
  const success_title = useRef();
  const success_text = useRef();
  const error_title = useRef();
  const error_text = useRef();
  const modal_close = useRef();

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=FeedbackModal`).then((res) => {
      logo.current.value = res?.data?.[0]?.logo;
      text.current.value = res?.data?.[0]?.text;
      title.current.value = res?.data?.[0]?.title;
      input_1.current.value = res?.data?.[0]?.input_1;
      input_2.current.value = res?.data?.[0]?.input_2;
      input_3.current.value = res?.data?.[0]?.input_3;
      input_4.current.value = res?.data?.[0]?.input_4;
      textarea.current.value = res?.data?.[0]?.textarea;
      button.current.value = res?.data?.[0]?.button;
      team_title.current.value = res?.data?.[0]?.team_title;
      team_input_1.current.value = res?.data?.[0]?.team_input_1;
      team_input_2.current.value = res?.data?.[0]?.team_input_2;
      team_input_3.current.value = res?.data?.[0]?.team_input_3;
      team_textarea.current.value = res?.data?.[0]?.team_textarea;
      team_button.current.value = res?.data?.[0]?.team_button;
      validation_text.current.value = res?.data?.[0]?.validation_text;
      success_title.current.value = res?.data?.[0]?.success_title;
      success_text.current.value = res?.data?.[0]?.success_text;
      error_title.current.value = res?.data?.[0]?.error_title;
      error_text.current.value = res?.data?.[0]?.error_text;
      modal_close.current.value = res?.data?.[0]?.modal_close;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "FeedbackModal",
        content: JSON.stringify({
          logo: logo.current.value,
          text: text.current.value,
          title: title.current.value,
          input_1: input_1.current.value,
          input_2: input_2.current.value,
          input_3: input_3.current.value,
          input_4: input_4.current.value,
          textarea: textarea.current.value,
          button: button.current.value,
          team_title: team_title.current.value,
          team_input_1: team_input_1.current.value,
          team_input_2: team_input_2.current.value,
          team_input_3: team_input_3.current.value,
          team_textarea: team_textarea.current.value,
          team_button: team_button.current.value,
          validation_text: validation_text.current.value,
          success_title: success_title.current.value,
          success_text: success_text.current.value,
          error_title: error_title.current.value,
          error_text: error_text.current.value,
          modal_close: modal_close.current.value,
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  return (
    <AdminContentPage
      title="Модальные окна обратной связи"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={logo} l_title={"Путь к файлу логотипа"} l_disabled={true} />
      <LamaTextarea l_ref={text} l_title={"Адрес"} />
      <LamaText l_ref={title} l_title={"Заголовок формы"} />
      <LamaText l_ref={input_1} l_title={"Надпись при пустом окне ввода имени"} />
      <LamaText l_ref={input_2} l_title={"Надпись при пустом окне ввода телефона"} />
      <LamaText l_ref={input_3} l_title={"Надпись при пустом окне ввода компании"} />
      <LamaText l_ref={input_4} l_title={"Надпись при пустом окне ввода почты"} />
      <LamaText l_ref={textarea} l_title={"Надпись при пустом окне ввода сообщения"} />
      <LamaText l_ref={button} l_title={"Надпись на кнопке отправки"} />
      <LamaText l_ref={team_title} l_title={"Заголовок формы заказа расчета"} />
      <LamaText l_ref={team_input_1} l_title={"Надпись при пустом окне ввода 'от кого' при расчете"} />
      <LamaText l_ref={team_input_2} l_title={"Надпись при пустом окне ввода 'кому' при расчете"} />
      <LamaText l_ref={team_input_3} l_title={"Надпись при пустом окне ввода 'контакты' при расчете"} />
      <LamaText l_ref={team_textarea} l_title={"Надпись при пустом окне ввода 'сообщение' при расчете"} />
      <LamaText l_ref={team_button} l_title={"Надпись на кнопке отправки при расчете"} />
      <LamaText l_ref={validation_text} l_title={"Текст ошибки валидации"} />
      <LamaText l_ref={success_title} l_title={"Заголовок при успешной отправке"} />
      <LamaText l_ref={success_text} l_title={"Текст при успешной отправке"} />
      <LamaText l_ref={error_title} l_title={"Заголовок при ошибке отправки"} />
      <LamaText l_ref={error_text} l_title={"Текст при ошибке отправки"} />
      <LamaText l_ref={modal_close} l_title={"Надпись на кнопке окна после отправки"} />

    </AdminContentPage>
  );
};