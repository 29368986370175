import { IMainState } from "./main.types.ts";

export const mainInitialState: IMainState = {
  tag: "",
  title: "",
  firstInfo: {
    name: "",
    position: "",
    phone: "",
  },
  secondInfo: {
    name: "",
    position: "",
    phone: "",
  },
};
