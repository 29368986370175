import React, { useEffect, useState } from 'react';
import { Map, Placemark, YMaps } from 'react-yandex-maps';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import styles from './contactsMap.module.css';
import { sliderThunks } from './../../../../plugins/slider/state/slider.slice.ts';

export const ContactsMap = () => {

    useEffect(() => {
        setRefreshMaps(null);
        return () => {
            dispatch(sliderThunks.unblockPreviousSlide());
            dispatch(sliderThunks.unblockNextSlide());
        }
    }, [])

    const dispatch = useAppDispatch();
    const {
        coordinates,
        zoom,
        point_coordinates,
        point_title,
    } = useAppSelector(state => state.contactsData);

    useEffect(() => {
        setRefreshMaps(null);
    }, [coordinates])

    const [refreshMaps, setRefreshMaps] = useState(false);

    useEffect(() => {
        if (refreshMaps === null) {
            setRefreshMaps(true)
        }
    }, [refreshMaps])

    const [loaded, setLoaded] = useState(false);
    const [blockSlider, setBlockSlider] = useState(false);

    useEffect(() => {
        if (blockSlider) {
            dispatch(sliderThunks.blockPreviousSlide());
            dispatch(sliderThunks.blockNextSlide());
        } else {
            dispatch(sliderThunks.unblockPreviousSlide());
            dispatch(sliderThunks.unblockNextSlide());
        }
    }, [blockSlider])

    return (
        <div className={styles.map}
            onMouseEnter={() => { setBlockSlider(true) }}
            onMouseLeave={() => { setBlockSlider(false) }}
        >
            {refreshMaps &&
                <YMaps>
                    <div>
                        <Map onLoad={() => { setLoaded(true) }} defaultState={{ center: coordinates, zoom: zoom }} width={window.innerWidth + 'px'} height='50vh'>
                            <Placemark geometry={{
                                type: "Point",
                                coordinates: point_coordinates
                            }} properties={{
                                preset: 'islands#icon',
                                iconCaption: point_title
                            }}></Placemark>
                        </Map>

                    </div>
                </YMaps>
            }
            {
                !loaded ?
                    <div className={styles.loader}>
                        {/* <img className={styles.image} src='desktop/contacts/map.jpg' /> */}
                        <p className={styles.text}>Карта загружается</p>
                    </div> : null
            }
        </div>
    )
}
