import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './areas.module.css';
import { AreasHeader } from './AreasHeader';
import { AreasSlider } from './AreasSlider';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { areasThunks } from '../state/areas.slice.ts';

export const Areas = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { areasPageDescription } = useAppSelector(state => state.areasData);
    const { contact } = useAppSelector(state => state.areasData.areasPageDescription);
    useEffect(() => {
        if (!areasPageDescription?.slider[0]) {
            dispatch(areasThunks.fetchInfo());
        }
    }, []);

    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                <AreasHeader />
                <AreasSlider />
            </div>

        </div>
    )
}
