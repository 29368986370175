import { PayloadAction } from "@reduxjs/toolkit";
import { IGeoState } from "./geo.types";

function setGeoState(
  state: IGeoState,
  { payload: newState }: PayloadAction<IGeoState>
) {
  return newState;
}

function setSelectedSectionName(
  state: IGeoState,
  { payload: selectedSectionName }: PayloadAction<string>
) {
  state.selectedSectionName = selectedSectionName;
}

function setFetched(
  state: IGeoState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const geoReducers = {
  setGeoState,
  setSelectedSectionName,
  setFetched,
};
