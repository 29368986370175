import { DEFAULT_HEADER_BACKGROUND } from '../Header/Header';
import './HeaderButton.css'
import { useAppSelector, useAppDispatch } from './../../hooks/hooksRedux.ts';
import { sliderThunks } from '../../plugins/slider/state/slider.slice.ts';

const HeaderButton = ({ text, link, redirect, active, setActive, setBackground, type, toggleFeedback, style }) => {

  const dispatch = useAppDispatch();
  const onclick = () => {
    if (type === 'feedback') {
      toggleFeedback();
      return;
    }
    dispatch(sliderThunks.slide({ direction: 'next', key: link, button: true }));
  }
  return (
    <div style={style ? style : {}} className={`header-button ${active === link ? 'header-button--active' : ''} ${type === 'feedback' ? 'header-button--feedback' : ''}`} onClick={onclick}>
      <p>{text}</p>
    </div>
  )
}


export default HeaderButton