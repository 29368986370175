import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/hooksRedux.ts";
import { ServicesThunks } from "./../state/Services.slice.ts";
import styles from "./servicesList.module.css";
import { classes } from "./../../../../libs/classname.js";
import { feedbackThunks } from "../../../../components/Feedback/state/feedback.slice.ts";

export const ServicesList = () => {
  const dispatch = useAppDispatch();
  const { serviceDescriptions, servicePageDescription } = useAppSelector(
    (state) => state.ServicesData
  );

  const [selectedService, setSelectedService] = useState();

  const toggleFeedback = () => {
    dispatch(feedbackThunks.openFeedback());
  };

  // Function to convert text with <a> tags into clickable elements
  const parseTextWithLinks = (text) => {
    // This regex will match <a> tags and capture their href and text
    const regex = /<a href="([^"]+)">([^<]+)<\/a>/g;
    let parts = [];
    let lastIndex = 0; // Keep track of the last index we processed

    // Use replace to find <a> tags and keep track of the text before and after
    text.replace(regex, (match, href, linkText, offset) => {
      // Add text before the match
      if (offset > lastIndex) {
        parts.push(text.substring(lastIndex, offset));
      }
      // Add the link as a React element
      parts.push(
        <a href={href} key={href + parts.length}>
          {linkText}
        </a>
      );
      // Update lastIndex to the end of the current match
      lastIndex = offset + match.length;
    });

    // Add any remaining text after the last match
    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts;
  };

  return (
    <>
      <div className={styles.container}>
        {selectedService && (
          <div className={styles.modal}>
            <h3>{serviceDescriptions[selectedService]?.title}</h3>
            <div className={styles.description}>
              <>
                {serviceDescriptions[selectedService]?.text
                  .split("\n")
                  .map((el, i) => {
                    if (el === "") {
                      return <br key={i} />;
                    }
                    const content = parseTextWithLinks(el);
                    return (
                      <p key={i}>
                        {content.map((part, index) =>
                          // If part is a string, wrap it with React.Fragment to avoid key warnings
                          // Strings do not need keys, but React elements do
                          typeof part === "string" ? (
                            <React.Fragment key={index}>{part}</React.Fragment>
                          ) : (
                            part
                          )
                        )}
                      </p>
                    );
                  })}
              </>
            </div>
            <img
              className={styles.close}
              src="close.svg"
              alt="X"
              onClick={() => {
                setSelectedService(null);
              }}
            />
          </div>
        )}
        <div className={styles.services}>
          <div
            key="auto"
            className={classes(
              styles.service,
              selectedService === "auto" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("auto");
            }}
          >
            <p>{serviceDescriptions.auto.name}</p>
          </div>
          <div
            key="air"
            className={classes(
              styles.service,
              selectedService === "air" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("air");
            }}
          >
            <p>{serviceDescriptions.air.name}</p>
          </div>
          <div
            key="ship"
            className={classes(
              styles.service,
              selectedService === "ship" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("ship");
            }}
          >
            <p>{serviceDescriptions.ship.name}</p>
          </div>
          <div
            key="rail"
            className={classes(
              styles.service,
              selectedService === "rail" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("rail");
            }}
          >
            <p>{serviceDescriptions.rail.name}</p>
          </div>
          <div
            key="multi"
            className={classes(
              styles.service,
              selectedService === "multi" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("multi");
            }}
          >
            <p>{serviceDescriptions.multi.name}</p>
          </div>
          <div
            key="projects"
            className={classes(
              styles.service,
              selectedService === "projects" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("projects");
            }}
          >
            <p>{serviceDescriptions.projects.name}</p>
          </div>
          <div
            key="customs"
            className={classes(
              styles.service,
              selectedService === "customs" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("customs");
            }}
          >
            <p>{serviceDescriptions.customs.name}</p>
          </div>
          <div
            key="warehouse"
            className={classes(
              styles.service,
              selectedService === "warehouse" ? styles.selected : ""
            )}
            onClick={() => {
              setSelectedService("warehouse");
            }}
          >
            <p>{serviceDescriptions.warehouse.name}</p>
          </div>
        </div>

        {/* <button className={styles.button} onClick={toggleFeedback}>Связаться с нами</button> */}
        <button className={styles.button} onClick={toggleFeedback}>
          {servicePageDescription.contact}
        </button>
      </div>
    </>
  );
};
