import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
// import { teamThunks } from '../state/team.slice.ts';
import styles from './teamSlider.module.css';
import { classes } from '../../../../libs/classname.js'

const [LEFT_SHIFT, RIGHT_SHIFT, TRANSITION_DURATION] = ['-600px', '600px', '400ms']
let autoSlideInterval;

export const TeamSlider = () => {
    const { slider } = useAppSelector(state => state.teamData.teamPageDescription);

    const [autoSlide, setAutoSlide] = useState(false);
    const [autoSlideIncrement, setAutoSlideIncrement] = useState(-1);

    const refA = useRef();
    const refB = useRef();

    const [srcA, setSrcA] = useState('');
    const [srcB, setSrcB] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        console.log({ slider });
        if (!slider || slider.length === 0) return;

        setSrcA(slider[currentIndex].src);

        refA.current.onload = () => { setAutoSlide(true) }
    }, [slider])

    useEffect(() => {
        if (!autoSlide) return;
        setStylesA({ ...stylesA, ...{ display: '' } });
        callAutoSlideInterval()();
    }, [autoSlide])

    const [stylesChanged, setStylesChanged] = useState(0);
    useEffect(() => {
        const observer = new MutationObserver(function () {
            let increment = 0;
            return (mutations) => {
                console.log({ increment });
                increment += 1
                setStylesChanged(increment);
            };
        }());
        setObserver(observer);
    }, [])

    const callAutoSlideInterval = () => {
        let increment = 0;
        return function () {
            autoSlideInterval = setInterval(() => {
                increment += 1
                setAutoSlideIncrement(increment);
            }, 5000)
        };
    }

    const callAutoSlide = () => {
        if (!autoSlide) {
            clearAutoSlide();
            return;
        }
        slideLeft({ auto: true });
    }

    useEffect(() => {
        if (autoSlideIncrement === -1) return;
        callAutoSlide()
    }, [autoSlideIncrement])

    const clearAutoSlide = () => {
        if (!autoSlideInterval) return;
        clearInterval(autoSlideInterval);
    }

    const [observer, setObserver] = useState();

    useEffect(() => {
        if (!observer) return;
        observer.observe(refA.current, { attributes: true, attributeFilter: ['style'] });
        // observer.observe(refB.current, { attributes: true, attributeFilter: ['style'] });
    }, [observer])

    const [stylesA, setStylesA] = useState({ transitionDuration: '0ms', display: 'none' });
    const [stylesB, setStylesB] = useState({ transitionDuration: '0ms', display: 'none' });

    const [activateName, setActiveName] = useState('x');

    const slideLeft = ({ auto }) => {
        if (!auto) clearAutoSlide();
        if (progress) return;
        if (currentIndex === 0) {
            const nextIndex = slider.length - 1
            setCurrentIndex(nextIndex);
            if (activateName === 'x') {
                setSrcB(slider[nextIndex].src)
            } else {
                setSrcA(slider[nextIndex].src)
            }
        } else {
            const nextIndex = currentIndex - 1;
            setCurrentIndex(nextIndex);
            if (activateName === 'x') {
                setSrcB(slider[nextIndex].src)
            } else {
                setSrcA(slider[nextIndex].src)
            }
        }
        setDirection('left');
        const stylesIn = {
            left: LEFT_SHIFT,
            opacity: 0,
        }

        const stylesOut = {
            transitionDuration: '400ms'
        }

        if (activateName === 'x') {
            setStylesB(stylesIn)
            setStylesA(stylesOut)
        } else {
            setStylesA(stylesIn)
            setStylesB(stylesOut)
        }
    }

    const slideRight = ({ auto }) => {
        if (!auto) clearAutoSlide();
        if (progress) return;
        if (currentIndex === slider.length - 1) {
            const nextIndex = 0
            setCurrentIndex(nextIndex);
            if (activateName === 'x') {
                setSrcB(slider[nextIndex].src)
            } else {
                setSrcA(slider[nextIndex].src)
            }
        } else {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
            if (activateName === 'x') {
                setSrcB(slider[nextIndex].src)
            } else {
                setSrcA(slider[nextIndex].src)
            }
        }
        setDirection('right');
        const stylesIn = {
            left: RIGHT_SHIFT,
            opacity: 0,
        }

        const stylesOut = {
            transitionDuration: TRANSITION_DURATION
        }

        if (activateName === 'x') {
            setStylesB(stylesIn)
            setStylesA(stylesOut)
        } else {
            setStylesA(stylesIn)
            setStylesB(stylesOut)
        }

    }

    useEffect(() => {
        if (progress) return;

        if (activateName === 'x' && [LEFT_SHIFT, RIGHT_SHIFT].includes(getComputedStyle(refB.current).left)) {
            setProgress(true);
            setReadyToEnter(true);
        } else if (activateName === 'y' && [LEFT_SHIFT, RIGHT_SHIFT].includes(getComputedStyle(refA.current).left)) {
            setProgress(true);
            setReadyToEnter(true);
        }
    }, [stylesChanged])

    const [readyToEnter, setReadyToEnter] = useState(false);
    const [progress, setProgress] = useState(false);
    const [direction, setDirection] = useState('left');

    useEffect(() => {
        if (progress && !readyToEnter) {
            setProgress(false);
            return;
        }
        if (!readyToEnter) return;
        console.log('ready to enter!');

        const stylesIn = {
            left: '110px',
            opacity: 1,
            transitionDuration: TRANSITION_DURATION
        }
        const stylesOut = {
            left: direction === 'left' ? RIGHT_SHIFT : LEFT_SHIFT,
            opacity: 0,
            transitionDuration: TRANSITION_DURATION
        }

        if (activateName === 'x') {
            setStylesA(stylesOut);
            setStylesB(stylesIn);
        } else {
            setStylesB(stylesOut);
            setStylesA(stylesIn);
        }

        setTimeout(() => {
            if (activateName === 'x') {
                transitionEndA()
            } else {
                transitionEndB()
            }
        }, 400)
    }, [readyToEnter])

    const transitionEndA = () => {
        setReadyToEnter(false);
        setStylesB({ transitionDuration: '0ms' });
        setStylesA({ transitionDuration: '0ms', display: 'none' });
        setActiveName('y');
    }

    const transitionEndB = () => {
        setReadyToEnter(false);
        setStylesA({ transitionDuration: '0ms' });
        setStylesB({ transitionDuration: '0ms', display: 'none' });
        setActiveName('x');
    }

    return (
        <>
            <div className={styles.container}>
                <img className={styles.photo} ref={refA} style={stylesA} src={srcA} alt="" />
                <img className={styles.photo} ref={refB} style={stylesB} src={srcB} alt="" />
            </div>
            {slider[currentIndex] ? <><button className={styles.left} onMouseEnter={clearAutoSlide} onClick={slideLeft}>&#8249;</button>
                <button className={styles.right} onMouseEnter={clearAutoSlide} onClick={slideRight}>&#8250;</button>
                <div className={styles.description} onMouseEnter={clearAutoSlide}>
                    <h3 className={styles.name}>{slider[currentIndex]?.name}</h3>
                    <strong className={styles.position}>{slider[currentIndex]?.position}</strong>
                    <p className={styles.bio}>{slider[currentIndex]?.bio}</p>
                </div></> : null}
        </>
    )
}
