import { AppThunk } from "../../../../store/store.ts";
import { areasActions } from "../state/areas.slice.ts";
import { IAreasState } from "../state/areas.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(areasActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(areasActions.setSlidePrevEvent(false));
};

export const areasThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
};
