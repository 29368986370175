import './App.css';
import Main from './views/desktop/main/view/Main';
import Header from './components/Header/Header';
import { AdminPanel } from './admin/AdminPanel';
import { useEffect, useState } from 'react';
import { MobileMain } from './views/mobile/Main/MobileMain';
import { MobileContainer } from './views/mobile/Container/MobileContainer';
import { MobileHeader } from './views/mobile/Header/MobileHeader';
import { MobileKeyServices } from './views/mobile/KeyServices/MobileKeyServices';
import { MobileTeam } from './views/mobile/Team/MobileTeam';
import { MobileGeo } from './views/mobile/Geo/MobileGeo';
import { MobileProjects } from './views/mobile/Projects/MobileProjects';
import { MobileCustoms } from './views/mobile/Customs/MobileCustoms';
import { MobileAgency } from './views/mobile/Agency/MobileAgency';
import { MobileDivision } from './views/mobile/Division/MobileDivision';
import { MobileContacts } from './views/mobile/Contacts/MobileContacts';
import { Services } from './views/desktop/services/view/Services';
import { Slider } from './plugins/slider/wrappers/Slider';
import { Team } from './views/desktop/team/view/Team';
import { Areas } from './views/desktop/areas/view/Areas';
import { sliderInitialState } from './plugins/slider/state/slider.state.ts';
import { Geo } from './views/desktop/geo/view/Geo';
import { Projects } from './views/desktop/projects/view/Projects';
import { Customs } from './views/desktop/customs/view/Customs';
import { Agency } from './views/desktop/agency/view/Agency';
import { Division } from './views/desktop/division/view/Division';
import { China } from './views/desktop/china/view/China';
import { Contacts } from './views/desktop/contacts/view/Contacts';
import TestMap from './views/desktop/testMap/TestMap';
import { Feedback } from './components/Feedback/Feedback';
import { useAppSelector, useAppDispatch } from './hooks/hooksRedux.ts';
import { adaptiveThunks } from './plugins/adaptive/state/adaptive.slice.ts';
import { Warning } from './views/desktop/warning/view/Warning';

function App() {

  const checkBreakpoints = () => {
    dispatch(adaptiveThunks.calculateBreakpoints(window.innerWidth, window.innerHeight));
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    window.addEventListener('resize', checkBreakpoints)
    return () => {
      window.removeEventListener('resize', checkBreakpoints)
    }
  }, [])

  const feedback = useAppSelector(state => state.feedbackData.show);

  const [admin, setAdmin] = useState(window.location.hash.slice(1) === 'admin');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  const [initKey, setInitKey] = useState(window.location.hash.slice(1))

  useEffect(() => {
    if (!sliderInitialState.keys.includes(initKey) && !admin) {
      console.log(initKey)
      setInitKey('main');
      window.location.hash = '#main';
    }
  }, [])

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>

      {admin && <AdminPanel />}

      {!admin && isMobile &&
        <MobileContainer>
          <MobileHeader />
          <MobileMain />
          <MobileKeyServices />
          {/* <MobileTeam /> */}
          <MobileGeo />
          <MobileProjects />
          <MobileCustoms />
          <MobileAgency />
          <MobileDivision />
          <MobileContacts />
        </MobileContainer>
      }

      {!admin && !isMobile &&
        <>
          <Header></Header>
          {/* <img className={'background'} src="background.svg"></img> */}
          <Slider initKey={initKey}>
            <Main className="page" sid={'main'} />
            <Services className="page" sid={'key'} />
            <Team className="page" sid={'team'} />
            <Areas className="page" sid={'areas'} />
            <Geo className="page" sid={'geo'} />
            <Projects className="page" sid={'projects'} />
            <Customs className="page" sid={'customs'} />
            <Agency className="page" sid={'agency'} />
            <Division className="page" sid={'division'} />
            <China className="page" sid={'china'} />
            <Contacts className="page" sid={'contacts'} />
            <Warning className="page" sid={'warning'}/>
            {/* <TestMap className="page" sid={'test-map'} /> */}
          </Slider>

          {feedback ? <Feedback /> : null}
          {/* <MainSlider>
            <Main className="page" sid={'main'} />
            <Services className="page" sid={'key'} />
            <Services2 className="page" sid={'key2'} />
            <Services3 className="page" sid={'key3'} />
            <TeamPage className="page" sid={'team'} />
            <AreasOfActivityPage className="page" sid={'areas'} />
            <GeographyOfActivityPage className="page" sid={'geo'} />

            <OurProjectsPage className="page" sid={'projects'} />
            <CustomsClearancePage className="page" sid={'clearance'} />

            <TradeAgencyPage className="page" sid={'agency'} />
            <SeparateDivisionInChinaPage className="page" sid={'division'} />
            <TradingCompanyInChinaPage className="page" sid={'china'} />
            <WriteToUsPage className="page" sid={'contacts'} />
          </MainSlider> */}
        </>
      }

    </div>
  );
}

export default App;