import { IProjectsState, IProjectsItem } from "./projects.types.ts";

export const projectsInitialState: IProjectsState = {
  title_color: "",
  title_dark: "",
  button_text: "",
  open_text: "",
  items: [] as IProjectsItem[],
  slideNextEvent: false,
  slidePrevEvent: false,

  selectedItem: {} as IProjectsItem,
  openProject: false
};
