import { IFeedbackState } from "./feedback.types";

export const feedbackInitialState: IFeedbackState = {
  button: "",
  error_text: "",
  error_title: "",
  input_1: "",
  input_2: "",
  input_3: "",
  input_4: "",
  logo: "",
  modal_close: "",
  success_text: "",
  success_title: "",
  team_button: "",
  team_input_1: "",
  team_input_2: "",
  team_input_3: "",
  team_textarea: "",
  team_title: "",
  text: "",
  textarea: "",
  title: "",
  validation_text: "",
  fetched: false,
  show: false,
  variant: "normal",
};
