import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './division.module.css';
import { DivisionHeader } from './DivisionHeader';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { divisionThunks } from '../state/division.slice.ts';

export const Division = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { items } = useAppSelector(state => state.divisionData);
    useEffect(() => {
        if (!items?.[0]) {
            dispatch(divisionThunks.fetchInfo());
        }
    }, []);

    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                <img className={styles.map} src='desktop/division/map.svg' />
                <p className={styles.map_text}>Подразделение Забайкальска на границе РФ</p>
                <DivisionHeader />
                <div className={styles.items}>
                    {items ? items.map(({ src, description }) => (<div className={styles.item}>
                        <img className={styles.icon} src={src} />
                        <p className={styles.description}>{description}</p>
                    </div>
                    )) : null}
                </div>
            </div>

        </div>
    )
}
