import { BreakpointsEnum, IAdaptiveState } from "./adaptive.types.ts";

export const adaptiveInitialState: IAdaptiveState = {
  widthBreakpoint: BreakpointsEnum.lg,
  maxWidths: {
    lg: Infinity,
    md: 1200,
    sm: 992,
    xs: 768,
  },
  heightBreakpoint: BreakpointsEnum.lg,
  maxHeights: {
    lg: Infinity,
    md: 800,
    sm: 700,
    xs: 600,
  },
};
