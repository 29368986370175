import axios from "axios";
import { areasThunks } from "../../../views/desktop/areas/state/areas.slice.ts";
import { projectsThunks } from "../../../views/desktop/projects/state/projects.slice.ts";
import { agencyThunks } from "../../../views/desktop/agency/state/agency.slice.ts";
import { sliderThunks } from "../state/slider.slice.ts";
import { sliderActions } from "../state/slider.slice.ts";
import { ISliderState, ISliderRule } from "../state/slider.types.ts";

const slide = async (
  {
    direction,
    key,
    button,
  }: { direction: "next" | "prev"; key?: string; button?: boolean },
  thunkApi: any
) => {
  const {
    previousKey,
    currentKey,
    nextKey,
    keys,
    lastEvent,
    rules,
    blocked,
    progress,
    readyToEnter,
    previousBlocked,
    nextBlocked,
    enteredFromContacts,
  } = thunkApi.getState().sliderData as ISliderState;

  if (direction === "prev" && currentKey === "main" && !enteredFromContacts) {
    return;
  }

  if (direction === "next" && currentKey === "contacts") {
    thunkApi.dispatch(sliderActions.setEnteredFromContacts(true));
  }

  if (!button) {
    if (direction === "next" && nextBlocked) {
      thunkApi.dispatch(areasThunks.emitSlideNext());
      thunkApi.dispatch(projectsThunks.emitSlideNext());
      thunkApi.dispatch(agencyThunks.emitSlideNext());
    } else if (direction === "prev" && previousBlocked) {
      thunkApi.dispatch(areasThunks.emitSlidePrev());
      thunkApi.dispatch(projectsThunks.emitSlidePrev());
      thunkApi.dispatch(agencyThunks.emitSlidePrev());
    } else if (direction === "prev" && !previousBlocked) {
      thunkApi.dispatch(agencyThunks.emitSlidePrev());
    }

    if (direction === "next" && nextBlocked) return;
    if (direction === "prev" && previousBlocked) return;
  }

  if (key === currentKey) return;
  if (blocked) return;
  if (progress) return;

  await thunkApi.dispatch(sliderActions.setReadyToEnter(false));
  await thunkApi.dispatch(sliderActions.setTransitionStart(false));
  await thunkApi.dispatch(sliderActions.setTransitionEnd(false));
  await thunkApi.dispatch(sliderActions.setProgress(true));

  const getInKey = key ? key : direction === "next" ? nextKey : previousKey;
  await thunkApi.dispatch(sliderActions.setDirection(direction));
  await thunkApi.dispatch(sliderActions.setGetInKey(getInKey));

  await new Promise((res) => {
    const i = setInterval(() => {
      const { readyToEnter } = thunkApi.getState().sliderData as ISliderState;
      console.log({ readyToEnter });
      if (readyToEnter) {
        clearInterval(i);
        res(true);
      }
    }, 100);
  });

  await thunkApi.dispatch(sliderActions.setTransitionStart(true));

  await new Promise((res) => {
    const i = setInterval(() => {
      const { transitionEnd } = thunkApi.getState().sliderData as ISliderState;
      // console.log({ transitionEnd });
      if (transitionEnd) {
        clearInterval(i);
        res(true);
      }
    }, 100);
  });

  await thunkApi.dispatch(sliderActions.setBlocked(true));
  const rule: ISliderRule = rules[getInKey];
  const { blockMS, durationMS } = rule;
  setTimeout(() => {
    thunkApi.dispatch(sliderActions.setBlocked(false));
  }, blockMS);

  await thunkApi.dispatch(sliderActions.setProgress(false));
  thunkApi.dispatch(sliderThunks.changeKeys(getInKey));
};

export const sliderExtraReducers = {
  "thunk/slide/pending": (state: ISliderState) => {
    console.log("slide in progress");
  },
  "thunk/slide/fulfilled": (state: ISliderState) => {
    console.log("slide successful");
  },
  "thunk/slide/rejected": (state: ISliderState) => {
    console.log("slide rejected");
  },
};

export const sliderThunksCallbacksAsync = {
  slide,
};
