import React, { useEffect, useRef, useState } from 'react';
import './MobileMain.css';
import axios from 'axios';

export const MobileMain = () => {

  const [info, setInfo] = useState({
    firstInfo: {},
    secondInfo: {},
    description: '',
  })

  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Main`)
      .then(res => {
        setInfo(
          {
            firstInfo: res?.data?.[0]?.firstInfo,
            secondInfo: res?.data?.[0]?.secondInfo,
            description: res?.data?.[0]?.description,
          }
        );
      })
  }, [])


  const video = useRef();
  useEffect(() => {
    video.current.play();
    const v = document.getElementById('m-video');
    if (v) v.play();
  }, [])

  return (
    <div id='main' className='m-main'>
      <div className="m-video">
        {/* <img src='Mobile/Main/bg.gif'/> */}
        <video ref={video} autoPlay loop muted id="video" playsInline onLoadedData={() => {
          video.current.play()
        }}
          width="100%">
          <source
            src="mainvideo.mp4"
            type="video/mp4"
          />
          <source
            src="mainvideo_desktop.mp4"
            type="video/mp4"
          />
          Ваш браузер устарел и не поддерживает наше видео :(
        </video>


        {/* <video loop muted autoplay ref={video} id='m-video'
          height="786px">
          <source
            src="test.mp4"
            type="video/mp4"
          />
        </video>  */}
      </div>

      <div className='m-main-logo'>
        <img src='Mobile/Main/logo.svg' alt='' />
      </div>

      {/* <div className='m-main-description'>
        <p>
          {info.description}
        </p>
      </div> */}

      <div className='m-main-contacts'>
        <div className='m-main-contact'>
          <p className='m-main-name'>{info.firstInfo?.name}</p>
          <p className='m-main-position'>{info.firstInfo?.position}</p>
          <p className='m-main-phone'>{info.firstInfo?.phone}</p>
        </div>
        <div className='m-main-contact'>
          <p className='m-main-name'>{info.secondInfo?.name}</p>
          <p className='m-main-position'>{info.secondInfo?.position}</p>
          <p className='m-main-phone'>{info.secondInfo?.phone}</p>
        </div>
      </div>

    </div>
  )
}
