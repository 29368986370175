import { IDivisionState, IDivisionItem } from "./division.types.ts";

export const divisionInitialState: IDivisionState = {
  title_color: "",
  title_dark: "",
  subtitle: "",
  address_title: "",
  address_description: "",
  items: [] as IDivisionItem[],
};
