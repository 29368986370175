import React, { useEffect, useState } from 'react';
import { classes } from '../../../../libs/classname';
import styles from './geo.module.css';
import { GeoHeader } from './GeoHeader';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { geoThunks } from '../state/geo.slice.ts';
import { GeoMenu } from './GeoMenu';
import Rive from '@rive-app/react-canvas';

export const Geo = ({ sid }) => {
    const dispatch = useAppDispatch();
    const { items, selectedSectionName } = useAppSelector(state => state.geoData);
    useEffect(() => {
        if (!items?.[0]) {
            dispatch(geoThunks.fetchInfo());
        }
        return () => {
            dispatch(geoThunks.selectSection('train'));
        }
    }, []);

    return (
        <div s-id={sid} className={styles.container}>

            <div className={styles.background_layout}>
                {/* <GeoHeader /> */}
                <img className={classes([styles.map, styles.map_main])} src='desktop/geo/map.svg' />
                {selectedSectionName === 'train' ? <img className={classes([styles.map, styles.map_train])} src='desktop/geo/train.svg' /> : null}
                {selectedSectionName === 'fly' ? <img className={classes([styles.map, styles.map_fly])} src='desktop/geo/fly.svg' /> : null}
                {selectedSectionName === 'ship' ? <img className={classes([styles.map, styles.map_ship])} src='desktop/geo/ship.svg' /> : null}
                {selectedSectionName === 'auto' ? <img className={classes([styles.map, styles.map_auto])} src='desktop/geo/auto.svg' /> : null}
                <GeoMenu />


            </div>

            {/* <img className={styles.plane} src='./desktop/geo/plane-img.svg' />

            <div className={styles.ship_container}>
                <img className={styles.ship} src='./desktop/geo/ship-img.svg' />
            </div>

            <div className={styles.ship_environment}>
                <div className={styles.ship_sea} />
            </div>

            <img className={styles.tree_1} src='./desktop/services/tree.svg' />
            <img className={styles.tree_2} src='./desktop/services/tree.svg' />

            <div className={styles.train_container}>
                <div className={styles.train}>
                    <Rive src="/desktop/geo/train.riv" />
                </div>
            </div>
            <div className={styles.train_environment}>
                <div className={styles.train_ground} />
                <div className={styles.train_bottom} />
                <div className={styles.train_top} />
            </div>


            <img className={styles.tree_3} src='./desktop/services/tree.svg' />
            <img className={styles.tree_4} src='./desktop/services/tree.svg' />

            <div className={styles.car_container}>
                <div className={styles.car}>
                    <Rive src="/desktop/geo/car.riv" />
                </div>
            </div>

            <div className={styles.car_environment}>
                <div className={styles.car_ground} />
                <div className={styles.car_bottom} />
                <div className={styles.car_top} />
            </div>
            <img className={styles.cloud_3} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_4} src='./desktop/services/cloud.svg' />

            <img className={styles.cloud_1} src='./desktop/services/cloud.svg' />
            <img className={styles.cloud_2} src='./desktop/services/cloud.svg' /> */}

        </div>
    )
}
