import { PayloadAction } from "@reduxjs/toolkit";
import { IAreasPageDescription, IAreasState } from "./areas.types";

function setAreasDescriptions(
  state: IAreasState,
  { payload: areasPageDescription }: PayloadAction<IAreasPageDescription>
) {
  state.areasPageDescription = areasPageDescription;
}
function setSlideNextEvent(
  state: IAreasState,
  { payload: slideNextEvent }: PayloadAction<boolean>
) {
  state.slideNextEvent = slideNextEvent;
}
function setSlidePrevEvent(
  state: IAreasState,
  { payload: slidePrevEvent }: PayloadAction<boolean>
) {
  state.slidePrevEvent = slidePrevEvent;
}

function setFetched(
  state: IAreasState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const areasReducers = {
  setAreasDescriptions,
  setSlideNextEvent,
  setSlidePrevEvent,
  setFetched
};
