import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminHeader.css";
import { LamaText } from "../components/Inputs/LamaText";
import { AdminContentPage } from "./AdminContentPage";

export const AdminHeader = () => {
  const feedback = useRef();
  const main = useRef();
  const keys = useRef();
  const team = useRef();
  const areas = useRef();
  const projects = useRef();


  useEffect(() => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Header`).then((res) => {
      feedback.current.value = res?.data?.[0]?.feedback;
      main.current.value = res?.data?.[0]?.main;
      keys.current.value = res?.data?.[0]?.keys;
      team.current.value = res?.data?.[0]?.team;
      areas.current.value = res?.data?.[0]?.areas;
      projects.current.value = res?.data?.[0]?.projects;
    });
  }, []);

  const save = () => {
    setWarning(false);
    setProgress(true);
    axios
      .post(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages`, {
        id: "Header",
        content: JSON.stringify({
          feedback: feedback.current.value,
          main: main.current.value,
          keys: keys.current.value,
          team: team.current.value,
          areas: areas.current.value,
          projects: projects.current.value,
        }),
      })
      .then(({ data }) => {
        // console.log(data);
        if (data.acknowledged) {
          showSuccess();
        } else {
          showError();
        }
      })
      .catch((e) => {
        showError();
      });
  };

  
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };


  return (
    <AdminContentPage
      title="Шапка сайта"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
      save={save}
    >
      <LamaText l_ref={feedback} l_title={"Текст на кнопке обратной связи"}/>
      <LamaText l_ref={main} l_title={"Текст на кнопке перехода на главную"}/>
      <LamaText l_ref={keys} l_title={"Текст на кнопке перехода на ключевые услуги"}/>
      <LamaText l_ref={team} l_title={"Текст на кнопке перехода на страницу команды"}/>
      <LamaText l_ref={areas} l_title={"Текст на кнопке перехода к направленностям"}/>
      <LamaText l_ref={projects} l_title={"Текст на кнопке перехода на страницу проектов"}/>

    </AdminContentPage>
  );
};