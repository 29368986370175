import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import { ICustomsConfig, ICustomsState } from "../state/customs.types.ts";
import { customsActions } from "../state/customs.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let customsConfig: ICustomsConfig = require("./../state/customs.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().customsData.fetched) return;
  thunkApi.dispatch(customsActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(customsConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  console.log({ info });

  const customsState: ICustomsState = {
    title_color: info.title_color,
    title_dark: info.title_dark,
    items: [
      {
        title: info.title_1,
        subtitle: info.subtitle_1,
        description: info.description_1,
      },
      {
        title: info.title_2,
        subtitle: info.subtitle_2,
        description: info.description_2,
      },
      {
        title: info.title_3,
        subtitle: info.subtitle_3,
        description: info.description_3,
      },
    ],
  };

  thunkApi.dispatch(customsActions.setCustomsState(customsState));
};

const emitSlideNext = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(customsActions.setSlideNextEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(customsActions.setSlideNextEvent(false));
  // }, 500);
};

const emitSlidePrev = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(customsActions.setSlidePrevEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(customsActions.setSlidePrevEvent(false));
  // }, 500);
};

export const customsExtraReducers = {
  "thunk/fetchInfo/pending": (state: ICustomsState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: ICustomsState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: ICustomsState) => {
    console.log("rejected");
  },
};

export const customsThunksCallbacksAsync = {
  fetchInfo,
  emitSlideNext,
  emitSlidePrev,
};
