export default class LamaScrollEvent {
    static init({element = document} = {element: document}) {
        element.addEventListener('wheel',(e)=>{
            e.preventDefault();
            if(this.block.current)return;
            this.onwheel(e)}, {passive: false});
        element.addEventListener('keydown', (e) => {this.onkeydown(e)}, {passive: false});
        element.addEventListener('mousedown', (e) => {this.onmousedown(e)}, {passive: false});
        element.addEventListener('mouseup', (e) => {this.onmouseup(e)}, {passive: false});
        element.addEventListener('auxclick', (e) => {this.onauxclick(e)}, {passive: false});
        element.addEventListener('mousemove', (e) => {
            if(this.block.current)return; 
            this.onmousemove(e)}, {passive: false});
    }
    static block = {current: false};
    static aux = {current: false};
    static touchpad = {
        wheel: false,
        direction: null,
        timeoutId: null,
    }
    static onnext = () => {
        /* // console.log(.*) */
        return new Promise(res=>{
            setTimeout(()=>{res(200)},500)
        })
    };
    static onprev = () => {
        /* // console.log(.*) */
        return new Promise(res=>{
            setTimeout(()=>{res(200)},500)
        })
    };
    static onwheel = (e) => {
        const direction = e.deltaY > 0 ? 'next' : 'prev';
        if (this.isTouchpad(e)) {
            this.ontouchpadwheel(e);
            return;
        }


        this.executeEventFunc(direction);
    }
    static onkeydown = async (e) => {
        const next = ['ArrowDown','PageDown'];
        const prev = ['ArrowUp','PageUp'];
        const direction = 
            next.includes(e.code) ? 'next' :
            prev.includes(e.code) ? 'prev' : null;
             
        if (!direction) return;
        else e.preventDefault();
        if(this.block.current)return;
        this.executeEventFunc(direction);
    }
    static executeEventFunc = async (direction) => {
        if (direction === 'next' && typeof this.onnext === 'function') {
            this.block.current = true;
            await this.onnext();
            this.block.current = false;
        } else if (typeof this.onprev === 'function') {
            this.block.current = true;
            await this.onprev();
            this.block.current = false;
        }
    }
    static onmousedown = (e) => {
        if (e.button === 1) {
            this.aux.current = true;
            e.preventDefault();
        }
    }
    static onmouseup = (e) => {
        if (e.button === 1) {
            this.aux.current = false;
            e.preventDefault();
        }
    }
    static onmousemove = (e) => {
        if (!this.aux.current) return;
        const direction = 
            e.movementY > 0 ? 'next' : 
            e.movementY < 0 ? 'prev' : null;
        
        if (!direction) return;
        this.executeEventFunc(direction);
    }
    static onauxclick = (e) => {
        e.preventDefault();
    }
    static isTouchpad = (e) => {
        return false;
        return ![100, -100].includes(e?.deltaY);
    }
    static ontouchpadwheel = (e) => {
        if (e.deltaY !== 0 && Math.abs(e.deltaY) <= 3) return;
        // if (e.deltaY !== -0 && this.touchpad.direction !== (e.deltaY > 0 ? 'next' : 'prev')) {
        //     this.touchpad.direction = e.deltaY > 0 ? 'next' : 'prev';
        // }
        if (!this.touchpad.wheel) {
            const direction = e.deltaY > 0 ? 'next' : 'prev';
            this.touchpad.wheel = true;
            this.touchpad.timeoutId = setTimeout(()=>{this.touchpad.wheel = false},500);
            this.touchpad.direction = direction;
            return;
        } else {
            // if (this.touchpad.direction === false) return;
            clearTimeout(this.touchpad.timeoutId);
            this.touchpad.timeoutId = setTimeout(()=>{this.touchpad.wheel = false},500);
            // console.log(e.deltaY, this.touchpad.direction);
            if (e.deltaY === -0) {
                // this.touchpad.direction = false;
                this.executeEventFunc(this.touchpad.direction);
                return;
            }
            return;
        }
    }
}

