import {React, useState, useEffect, useRef} from 'react'
import './LamaList.css'
import { LamaProject } from './LamaProject';

export const LamaList = ({getResults, setProjects, projects}) => {

    useEffect(()=>{
        getResults.current = getValues
        setProjects.current = setProj;

        console.log(projects);
    },[])

    const setProj = () => {
        projects.current.forEach(el=>{
            const id = list.current.length === 0 ? 1 : list.current[list.current.length - 1].props.id + 1;
            list.current.push((
                <LamaProject 
                    key={Math.random() * Math.random()}
                    values={values}
                    info={el}
                    id={id}
                    remove={()=>{remove(id)}}/>
            ))
        })
        setListItems(prev=>[...prev, 1]);
    }

    const values = useRef([]);

    const remove = function(id) {
        // console.log({id});
        list.current = list.current.filter((el)=>el.props.id !== id);
        setListItems(prev=>[...prev, 1]);
    }

    const list = useRef(
        []
    );

    const [listItems, setListItems] = useState([
    ]);

    const [getValuesFuncs, setGetValuesFuncs] = useState([]);



    const add = () => {
        const id = list.current.length === 0 ? 1 : list.current[list.current.length - 1].props.id + 1;
        list.current.push(
            (
                <LamaProject 
                    key={Math.random() * Math.random()}
                    values={values}
                    id={id}
                    remove={()=>{remove(id)}}/>
            ))
            setListItems(prev=>[...prev, 1])
    }

    useEffect(()=>{
        // console.log(listItems);
    },[listItems])

    const getValues = () => {
        const result = [];

        for (let i = 0; i < values.current.length; i++) {
            const el = values.current[i];
            const res = el();

            if (!list.current.find(item=>item.props.id === res.id)) continue;
            

            if (!res.result[0]) {
                continue;
            };

            const title = res.result.shift();
            const description = res.result.shift();

            result.push({title, description, content: res.result});
        }
        
        // console.log(result);
        return result;
    }


  return (
    <>
        <div className='l-list-items'>
            {
               listItems && list.current
            }
        </div>
        <button onClick={add}>Добавить проект</button>
        {/* <button onClick={()=>{// console.log(getValues, getValues())}}>Собрать инфу</button> */}
    
    </>
  )
}
