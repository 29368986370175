import { createSlice } from "@reduxjs/toolkit";
import { customsReducers } from "./customs.actions.ts";
import { customsInitialState } from "./customs.state.ts";
import { customsThunksCallbacksAsync } from "../logic/customs.thunks.async.ts";
import { customsThunksCallbacksSync } from "../logic/customs.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const customsThunks: any = createThunksForExport({
  async: customsThunksCallbacksAsync,
  sync: customsThunksCallbacksSync,
});

export const customsDataSlice = createSlice({
  name: "customsData",
  initialState: customsInitialState,
  reducers: customsReducers,
});

export const customsStaticData = (state: RootState) => state.customsData;

export const customsActions = customsDataSlice.actions;

export default customsDataSlice.reducer;
