import React from 'react';
import './MobileKeyServicesItem.css';

export const MobileKeyServicesItem = ({name, click}) => {
  return (
    <div className='m-key-item' onClick={click}>
      <p>{name}</p>
    </div>
  )
}
