import { createSlice } from "@reduxjs/toolkit";
import { warningReducers } from "./warning.actions.ts";
import { warningInitialState } from "./warning.state.ts";
import { warningThunksCallbacksAsync } from "../logic/warning.thunks.async.ts";
import { warningThunksCallbacksSync } from "../logic/warning.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const warningThunks: any = createThunksForExport({
  async: warningThunksCallbacksAsync,
  sync: warningThunksCallbacksSync,
});

export const warningDataSlice = createSlice({
  name: "warningData",
  initialState: warningInitialState,
  reducers: warningReducers,
});

export const warningStaticData = (state: RootState) => state.warningData;

export const warningActions = warningDataSlice.actions;

export default warningDataSlice.reducer;
