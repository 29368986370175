import { AppThunk } from "../../../../store/store.ts";
import { sliderActions } from "../state/slider.slice.ts";
import { ISliderState } from "../state/slider.types.ts";

const init =
  (initKey: string): AppThunk =>
  (dispatch, getState) => {
    const keys = (getState().sliderData as ISliderState).keys;
    const currentKeyIndex = keys.indexOf(initKey);
    if (currentKeyIndex === -1) return;

    dispatch(sliderActions.setCurrentKey(initKey));

    if (currentKeyIndex === 0) {
      dispatch(sliderActions.setPreviousKey(keys[keys.length - 1]));
    } else {
      dispatch(sliderActions.setPreviousKey(keys[currentKeyIndex - 1]));
    }

    if (currentKeyIndex === keys.length - 1) {
      dispatch(sliderActions.setNextKey(keys[0]));
    } else {
      dispatch(sliderActions.setNextKey(keys[currentKeyIndex + 1]));
    }
  };

const changeKeys =
  (newKey: string): AppThunk =>
  (dispatch, getState) => {
    const keys = (getState().sliderData as ISliderState).keys;
    const currentKeyIndex = keys.indexOf(newKey);
    if (currentKeyIndex === -1) return;

    dispatch(sliderActions.setCurrentKey(newKey));

    if (currentKeyIndex === 0) {
      dispatch(sliderActions.setPreviousKey(keys[keys.length - 1]));
    } else {
      dispatch(sliderActions.setPreviousKey(keys[currentKeyIndex - 1]));
    }

    if (currentKeyIndex === keys.length - 1) {
      dispatch(sliderActions.setNextKey(keys[0]));
    } else {
      dispatch(sliderActions.setNextKey(keys[currentKeyIndex + 1]));
    }
  };

const startProgress =
  (newKey: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(sliderActions.setProgress(true));
  };

const endProgress =
  (newKey: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(sliderActions.setProgress(false));
  };

const emitReadyToEnter = (): AppThunk => (dispatch) => {
  dispatch(sliderActions.setReadyToEnter(true));
};
const emitTransitionEnd = (): AppThunk => (dispatch) => {
  dispatch(sliderActions.setTransitionEnd(true));
};

const blockPreviousSlide = (): AppThunk => (dispatch) => {
  dispatch(sliderActions.setPreviousBlocked(true));
};
const unblockPreviousSlide = (): AppThunk => (dispatch) => {
  dispatch(sliderActions.setPreviousBlocked(false));
};
const blockNextSlide = (): AppThunk => (dispatch) => {
  dispatch(sliderActions.setNextBlocked(true));
};
const unblockNextSlide = (): AppThunk => (dispatch) => {
  dispatch(sliderActions.setNextBlocked(false));
};

const fetchNeighboringPagesInfo =
  (key: string): AppThunk =>
  (dispatch, getState) => {
    const keys = (getState().sliderData as ISliderState).keys;
    const currentKeyIndex = keys.indexOf(key);
    if (currentKeyIndex === -1) return;
    let nextKey, prevKey;

    if (currentKeyIndex === 0) {
      prevKey = keys[keys.length - 1];
    } else {
      prevKey = keys[currentKeyIndex - 1];
    }

    if (currentKeyIndex === keys.length - 1) {
      nextKey = keys[0];
    } else {
      nextKey = keys[currentKeyIndex + 1];
    }

    // mainThunks


    // dispatch([prevKey + 'Thunks'].fetchInfo())

  };

export const sliderThunksCallbacksSync = {
  init,
  changeKeys,
  startProgress,
  endProgress,
  emitReadyToEnter,
  emitTransitionEnd,
  blockPreviousSlide,
  unblockPreviousSlide,
  blockNextSlide,
  unblockNextSlide,
  fetchNeighboringPagesInfo,
};
