import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
// import { areasThunks } from '../state/areas.slice.ts';
import styles from './areasSlider.module.css';
import { classes } from '../../../../libs/classname.js'
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import { sliderThunks } from './../../../../plugins/slider/state/slider.slice.ts';
import { areasThunks } from '../state/areas.slice.ts';

// const [START_SHIFT, FINISH_SHIFT] = ['-9475px', '-12833px']
const [START_SHIFT, FINISH_SHIFT] = ['-9475px', '-11155px']
let progress = false;

export const AreasSlider = () => {
    const dispatch = useAppDispatch();
    const { slider } = useAppSelector(state => state.areasData.areasPageDescription);
    const { slideNextEvent, slidePrevEvent } = useAppSelector(state => state.areasData);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [enter, setEnter] = useState(true);

    useEffect(() => {
        if (!slideNextEvent) return;
        dispatch(areasThunks.resetSlideNextEvent())
        if (enter) return;
        goNext();
    }, [slideNextEvent])

    useEffect(() => {
        if (!slidePrevEvent) return;
        dispatch(areasThunks.resetSlidePrevEvent())
        if (enter) return;
        goPrev();
    }, [slidePrevEvent])

    useEffect(() => {
        setTimeout(() => { setEnter(false); console.log('set to false') }, 1000);
        return () => {
            dispatch(sliderThunks.unblockPreviousSlide());
            dispatch(sliderThunks.unblockNextSlide());

            setLineLeft(START_SHIFT)
            setItems([
                slider[0]?.[0],
                slider[1]?.[0],
                slider[2]?.[0],
                slider[3]?.[0],
                slider[4]?.[0],
            ])
        }
    }, [])

    useEffect(() => {
        if (selectedIndex === 0) {
            dispatch(sliderThunks.blockNextSlide());
            dispatch(sliderThunks.unblockPreviousSlide());
        } else if (selectedIndex === 1) {
            dispatch(sliderThunks.blockPreviousSlide());
            dispatch(sliderThunks.unblockNextSlide());
        }
    }, [selectedIndex])
    useEffect(() => {
        if (!slider || slider.length === 0) return;
        setItems([
            slider[0]?.[0],
            slider[1]?.[0],
            slider[2]?.[0],
            slider[3]?.[0],
            slider[4]?.[0],
        ])
    }, [slider])

    const [lineLeft, setLineLeft] = useState(START_SHIFT)

    const [items, setItems] = useState([])

    const goNext = (e) => {
        if (progress) return;
        progress = true;
        dispatch(sliderThunks.blockPreviousSlide());
        dispatch(sliderThunks.blockNextSlide());

        setStylesTop({ opacity: 0, top: 0 })
        setStylesBottom({ opacity: 0, top: '478px' })

        setTimeout(() => {
            setLineLeft(FINISH_SHIFT)
            setItems([
                slider[0]?.[1],
                slider[1]?.[1],
                slider[2]?.[1],
                slider[3]?.[1],
                slider[4]?.[1],
            ])
        }, 300)

        setTimeout(() => {
            setStylesTop({ opacity: 1 })
            setStylesBottom({ opacity: 1 })
            setTimeout(() => {
                progress = false;
                setSelectedIndex(1);
            }, 400)
        }, 900)
    }

    const goPrev = (e) => {
        if (progress) return;
        progress = true;
        dispatch(sliderThunks.blockPreviousSlide());
        dispatch(sliderThunks.blockNextSlide());

        setStylesTop({ opacity: 0, top: 0 })
        setStylesBottom({ opacity: 0, top: '478px' })

        setTimeout(() => {
            setLineLeft(START_SHIFT)
            setItems([
                slider[0]?.[0],
                slider[1]?.[0],
                slider[2]?.[0],
                slider[3]?.[0],
                slider[4]?.[0],
            ])
        }, 300)

        setTimeout(() => {
            setStylesTop({ opacity: 1 })
            setStylesBottom({ opacity: 1 })
            setTimeout(() => {
                progress = false;
                setSelectedIndex(0);
            }, 400)
        }, 900)
    }

    const [stylesTop, setStylesTop] = useState({});
    const [stylesBottom, setStylesBottom] = useState({});

    return (
        <>
            <img src="desktop/areas/line.svg" className={styles.line} style={{ left: lineLeft }} alt="" />

            <div className={styles.items_top} style={stylesTop} key="1">
                <div className={styles.item} key="1_1">
                    <div className={styles.text}>
                        <div className={styles.title}>{items[1]?.title}</div>
                        <div className={styles.description}>{items[1]?.description}</div>
                    </div>
                    <div className={styles.path} />
                    <div className={styles.img_container}>
                        <img className={styles.img} src={items[1]?.src} alt=""></img>
                    </div>
                </div>
                <div className={styles.item} key="1_2">
                    <div className={styles.text}>
                        <div className={styles.title}>{items[3]?.title}</div>
                        <div className={styles.description}>{items[3]?.description}</div>
                    </div>
                    <div className={styles.path} />
                    <div className={styles.img_container}>
                        <img className={styles.img} src={items[3]?.src} alt=""></img>
                    </div>
                </div>
            </div>


            <div className={styles.items_bottom} style={stylesBottom} key="2">
                <div className={styles.item_bottom} key="2_1">
                    <div className={styles.img_container}>
                        <img className={styles.img} src={items[0]?.src} alt=""></img>
                    </div>
                    <div className={styles.path} />
                    <div className={styles.text}>
                        <div className={styles.title}>{items[0]?.title}</div>
                        <div className={styles.description}>{items[0]?.description}</div>
                    </div>
                </div>
                <div className={styles.item_bottom} key="2_2">
                    <div className={styles.img_container}>
                        <img className={styles.img} src={items[2]?.src} alt=""></img>
                    </div>
                    <div className={styles.path} />
                    <div className={styles.text}>
                        <div className={styles.title}>{items[2]?.title}</div>
                        <div className={styles.description}>{items[2]?.description}</div>
                    </div>
                </div>
                <div className={styles.item_bottom} key="2_3">
                    <div className={styles.img_container}>
                        <img className={styles.img} src={items[4]?.src} alt=""></img>
                    </div>
                    <div className={styles.path} />
                    <div className={styles.text}>
                        <div className={styles.title}>{items[4]?.title}</div>
                        <div className={styles.description}>{items[4]?.description}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
