import { PayloadAction } from "@reduxjs/toolkit";
import { ISliderState } from "./slider.types";

function setCurrentKey(
  state: ISliderState,
  { payload: currentKey }: PayloadAction<string>
) {
  state.currentKey = currentKey;
}

function setPreviousKey(
  state: ISliderState,
  { payload: previousKey }: PayloadAction<string>
) {
  state.previousKey = previousKey;
}

function setNextKey(
  state: ISliderState,
  { payload: nextKey }: PayloadAction<string>
) {
  state.nextKey = nextKey;
}

function setBlocked(
  state: ISliderState,
  { payload: blocked }: PayloadAction<boolean>
) {
  state.blocked = blocked;
}

function setProgress(
  state: ISliderState,
  { payload: progress }: PayloadAction<boolean>
) {
  state.progress = progress;
}

function setGetInKey(
  state: ISliderState,
  { payload: getInKey }: PayloadAction<string>
) {
  state.getInKey = getInKey;
}

function setReadyToEnter(
  state: ISliderState,
  { payload: readyToEnter }: PayloadAction<boolean>
) {
  state.readyToEnter = readyToEnter;
}

function setDirection(
  state: ISliderState,
  { payload: direction }: PayloadAction<string>
) {
  state.direction = direction;
}
function setTransitionStart(
  state: ISliderState,
  { payload: transitionStart }: PayloadAction<boolean>
) {
  state.transitionStart = transitionStart;
}
function setTransitionEnd(
  state: ISliderState,
  { payload: transitionEnd }: PayloadAction<boolean>
) {
  state.transitionEnd = transitionEnd;
}
function setPreviousBlocked(
  state: ISliderState,
  { payload: previousBlocked }: PayloadAction<boolean>
) {
  state.previousBlocked = previousBlocked;
}
function setNextBlocked(
  state: ISliderState,
  { payload: nextBlocked }: PayloadAction<boolean>
) {
  state.nextBlocked = nextBlocked;
}

function setEnteredFromContacts(
  state: ISliderState,
  { payload: enteredFromContacts }: PayloadAction<boolean>
) {
  state.enteredFromContacts = enteredFromContacts;
}

export const sliderReducers = {
  setCurrentKey,
  setPreviousKey,
  setNextKey,
  setBlocked,
  setProgress,
  setGetInKey,
  setReadyToEnter,
  setDirection,
  setTransitionStart,
  setTransitionEnd,
  setPreviousBlocked,
  setNextBlocked,
  setEnteredFromContacts,
};
