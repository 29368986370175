import React, { useEffect, useMemo, useState } from 'react';
import { MobileTitle } from '../Title/MobileTitle';
import './MobileAgency.css';
import axios from 'axios';
import { MobileAgencyItem } from './MobileAgencyItem';

export const MobileAgency = () => {
  const [DBInfo, setDBInfo] = useState({});

  useEffect(()=>{
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/pages?id=Agency`)
    .then(res => {
      const info = {};
      if (!res?.data?.[0]) return;

      Object.keys(res?.data?.[0]).forEach(el=>{
        if (el === '_id') return;
        info[el] = res.data[0][el];
      })
      setDBInfo(info);
    })

  },[])

  const items = useMemo(()=>{
    return [
      {iconSrc: 'Mobile/Agency/icon_1.svg',title:DBInfo.title_1,subtitle:DBInfo.subtitle_1,text:DBInfo.description_1},
      {iconSrc: 'Mobile/Agency/icon_2.svg',title:DBInfo.title_2,subtitle:DBInfo.subtitle_2,text:DBInfo.description_2},
      {iconSrc: 'Mobile/Agency/icon_3.svg',title:DBInfo.title_3,subtitle:DBInfo.subtitle_3,text:DBInfo.description_3},
      {iconSrc: 'Mobile/Agency/icon_4.svg',title:DBInfo.title_4,subtitle:DBInfo.subtitle_4,text:DBInfo.description_4},
    ]
  },[DBInfo])

  return (
    <>
    <div className='m-agency-road'>
      <img className='m-agency-clouds-back' src='Mobile/Agency/clouds_back.svg'/>
      <img className='m-agency-clouds-back-2' src='Mobile/Agency/clouds_back.svg'/>
      <img className='m-agency-plane' src='Mobile/Agency/plane.svg'/>
      <img className='m-agency-clouds-front' src='Mobile/Agency/clouds_front.svg'/>
      <img className='m-agency-clouds-front-2' src='Mobile/Agency/clouds_front.svg'/>
    </div>
    <div className='m-agency-content'>
      <img className='m-agency-bg-img' src='Mobile/Agency/bg.svg'/>
      <MobileTitle type='two_lines' first={{text: 'ТОРГОВОЕ', color: '#fff'}} second={{text: 'АГЕНТИРОВАНИЕ', color: '#49525e'}}/>
      
      <div className='m-agency-items'>
        <MobileAgencyItem type='right' content={items[0]}/>
        <MobileAgencyItem type='left' content={items[1]}/>
        <MobileAgencyItem type='right' content={items[2]}/>
        <MobileAgencyItem type='left' content={items[3]}/>
      </div>
    </div>
    </>
  )
}
