import React, { useEffect, useRef, useState } from 'react';
import './LamaImage.css';

const LamaImage = ({id, values, prevValue, setFile}) => {

const [img, setImg] = useState({src: prevValue ?? ''});
const imageRef = useRef();
    
  const onload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (!['image/png','image/jpg','image/jpeg'].includes(file.type)) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg({ src: reader.result });
      // values.current[id] = reader.result;
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }


  return (
    <label className='l-image-input' htmlFor={'l-file-input_' + id}>
      <div>
        <input style={{ position: 'absolute', width: '0', height: '0', opacity: '0' }} id={'l-file-input_' + id} type="file" onChange={onload} />
        {img.src === '' && <p className='l-image-label-p'>Загрузить изображение в текст (только .jpg, .jpeg и .png)</p>}
        <img ref={imageRef} src={img.src} alt="" />
      </div>
    </label>
  )
}

export default LamaImage