import { PayloadAction } from "@reduxjs/toolkit";
import { ITeamPageDescription, ITeamState } from "./team.types";

function setTeamDescriptions(
  state: ITeamState,
  { payload: teamPageDescription }: PayloadAction<ITeamPageDescription>
) {
  state.teamPageDescription = teamPageDescription;
}

function setFetched(
  state: ITeamState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const teamReducers = {
  setTeamDescriptions,
  setFetched
};
