import React from 'react';
import styles from './contactsHeader.module.css';
import { useAppSelector } from '../../../../hooks/hooksRedux.ts';

export const ContactsHeader = () => {
    const { title_color, title_dark, subtitle } = useAppSelector(state => state.contactsData);
    return (
        <>
            <div className={styles.title}>
                <p>{title_color}</p>
                {/* <p>Команда</p> */}
                <p className={styles.dark}>{title_dark}</p>
                {/* <p className={styles.dark}>Профессионалов</p> */}
            </div>
        </>
    )
}
