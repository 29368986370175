import React, { useEffect, useRef, useState } from 'react';
import styles from './slider.module.css';
import { useAppSelector, useAppDispatch } from './../../../hooks/hooksRedux.ts';
import LamaScrollEvent from '../../../libs/LamaScrollEvent';
import { sliderThunks } from '../state/slider.slice.ts';
import { sliderInitialState } from '../state/slider.state.ts';

import { mainThunks } from './../../../views/desktop/main/state/main.slice.ts';
import { ServicesThunks } from '../../../views/desktop/services/state/Services.slice.ts';
import { teamThunks } from './../../../views/desktop/team/state/team.slice.ts';
import { areasThunks } from './../../../views/desktop/areas/state/areas.slice.ts';
import { geoThunks } from './../../../views/desktop/geo/state/geo.slice.ts';
import { projectsThunks } from './../../../views/desktop/projects/state/projects.slice.ts';
import { customsThunks } from './../../../views/desktop/customs/state/customs.slice.ts';
import { agencyThunks } from './../../../views/desktop/agency/state/agency.slice.ts';
import { divisionThunks } from './../../../views/desktop/division/state/division.slice.ts';
import { chinaThunks } from './../../../views/desktop/china/state/china.slice.ts';
import { contactsThunks } from './../../../views/desktop/contacts/state/contacts.slice.ts';
import { feedbackThunks } from '../../../components/Feedback/state/feedback.slice.ts';

const thunks = {
  main: mainThunks,
  key: ServicesThunks,
  team: teamThunks,
  areas: areasThunks,
  geo: geoThunks,
  projects: projectsThunks,
  customs: customsThunks,
  agency: agencyThunks,
  division: divisionThunks,
  china: chinaThunks,
  contacts: contactsThunks,
  feedback: feedbackThunks,
}

export const Slider = ({ children, initKey }) => {
  const { getInKey, direction, rules, readyToEnter, transitionStart, previousKey, nextKey, currentKey } = useAppSelector(state => state.sliderData);
  const [currentContainerName, setCurrentContainerName] = useState('a');

  const [_initKey, setInitKey] = useState('');

  const [componentA, setComponentA] = useState(null);
  const [componentB, setComponentB] = useState(null);

  const [stylesA, setStylesA] = useState({});
  const [stylesB, setStylesB] = useState({});

  const refA = useRef(document.createElement('div'));
  const refB = useRef(document.createElement('div'));

  const [observer, setObserver] = useState();

  useEffect(() => {
    if (!sliderInitialState.keys.includes(initKey)) {
      setInitKey('main');
    } else {
      setInitKey(initKey);
    }

    dispatch(thunks.main.fetchInfo());
    dispatch(thunks.feedback.fetchInfo());
  }, [])

  useEffect(() => {
    if (previousKey && thunks[previousKey]) dispatch(thunks[previousKey].fetchInfo());
    if (nextKey && thunks[nextKey]) dispatch(thunks[nextKey].fetchInfo());
  }, [
    previousKey,
    nextKey
  ]);

  useEffect(() => {
    window.location.hash = `#${currentKey}`;
  }, [currentKey]);

  useEffect(() => {
    setComponentA(children.find(child => child?.props?.sid === _initKey));
    dispatch(sliderThunks.init(_initKey));
    LamaScrollEvent.init();
    LamaScrollEvent.onnext = next.bind(Slider);
    LamaScrollEvent.onprev = prev.bind(Slider);
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        dispatch(sliderThunks.emitReadyToEnter());
      });
    });
    setObserver(observer);
  }, [_initKey])

  useEffect(() => {
    if (!observer) return;
    observer.observe(refA.current, { attributes: true, attributeFilter: ['style'] });
    observer.observe(refB.current, { attributes: true, attributeFilter: ['style'] });
  }, [observer])

  useEffect(() => {
    if (!getInKey) return;
    prepareTransition(getInKey);
  }, [getInKey])

  useEffect(() => {
    if (!transitionStart) return;
    startExit();
    startEnter();
  }, [transitionStart])

  const dispatch = useAppDispatch();
  const next = () => {
    dispatch(sliderThunks.slide({ direction: 'next' }));
  }
  const prev = () => {
    dispatch(sliderThunks.slide({ direction: 'prev' }));
  }

  const prepareTransition = (getInKey) => {
    const rule = rules[getInKey];
    const child = children.find(child => child?.props?.sid === getInKey) || null;
    if (currentContainerName === 'a') {
      setComponentB(child);
    } else {
      setComponentA(child);
    }
    const styles = {
      transitionDuration: '0ms',
    };
    switch (rule.incomeDirection[direction]) {
      case 'fromTop': {
        styles.transform = 'translateY(-100vh)';
        styles.zIndex = '1';
        break;
      }
      case 'fromBottom': {
        styles.transform = 'translateY(100vh)';
        styles.zIndex = '1';
        break;
      }
      case 'fromLeft': {
        styles.transform = 'translateX(-100vw)';
        styles.zIndex = '1';
        break;
      }
      case 'fromRight': {
        styles.transform = 'translateX(100vw)';
        styles.zIndex = '1';
        break;
      }
      default: {
        return;
      }
    }

    if (currentContainerName === 'a') {
      setStylesB(styles);
    } else {
      setStylesA(styles);
    }
  }

  const startExit = () => {
    const rule = rules[getInKey];
    console.log({ rule });
    const styles = {
      transitionDuration: rule.durationMS + 'ms',
    };

    switch (rule.incomeDirection[direction]) {
      case 'fromTop': {
        styles.transform = 'translateY(100vh)';
        styles.zIndex = '2';
        break;
      }
      case 'fromBottom': {
        styles.transform = 'translateY(-100vh)';
        styles.zIndex = '2';
        break;
      }
      case 'fromLeft': {
        styles.transform = 'translateX(100vw)';
        styles.zIndex = '2';
        break;
      }
      case 'fromRight': {
        styles.transform = 'translateX(-100vw)';
        styles.zIndex = '2';
        break;
      }
      default: {
        return;
      }
    }

    if (currentContainerName === 'a') {
      setStylesA(styles);
    } else {
      setStylesB(styles);
    }
  }

  const startEnter = () => {
    const rule = rules[getInKey];
    const styles = {
      transitionDuration: rule.durationMS + 'ms',
    };

    switch (rule.incomeDirection[direction]) {
      case 'fromTop': {
        styles.transform = 'translateY(0)';
        styles.zIndex = '1';
        break;
      }
      case 'fromBottom': {
        styles.transform = 'translateY(0)';
        styles.zIndex = '1';
        break;
      }
      case 'fromLeft': {
        styles.transform = 'translateX(0)';
        styles.zIndex = '1';
        break;
      }
      case 'fromRight': {
        styles.transform = 'translateX(0)';
        styles.zIndex = '1';
        break;
      }
      default: {
        return;
      }
    }

    if (currentContainerName === 'a') {
      setStylesB(styles);
    } else {
      setStylesA(styles);
    }
  }

  const transitionEndB = (e) => {
    if (e.target.id !== 'slider-container-b') return;
    if (currentContainerName === 'b') {
      completeEnter();
    } else {
      completeExit();
    }
  };

  const transitionEndA = (e) => {
    if (e.target.id !== 'slider-container-a') return;
    if (currentContainerName === 'a') {
      completeEnter();
    } else {
      completeExit();
    }
  };

  const completeEnter = () => {
    if (currentContainerName === 'a') {
      setCurrentContainerName('b');
    } else {
      setCurrentContainerName('a');
    }
    dispatch(sliderThunks.emitTransitionEnd());
  }

  const completeExit = () => {
    if (currentContainerName === 'a') {
      setComponentA(null);
      setStylesA(null);
      setStylesB({ ...stylesB, ...{ transitionDuration: '0ms' } });
    } else {
      setComponentB(null);
      setStylesB(null);
      setStylesA({ ...stylesA, ...{ transitionDuration: '0ms' } });
    }
  }


  return (
    <div className={styles.container}>
      <div ref={refB} id="slider-container-b" className={styles.b} style={stylesB} onTransitionEnd={transitionEndB}>{componentB}</div>
      <div ref={refA} id="slider-container-a" className={styles.a} style={stylesA} onTransitionEnd={transitionEndA}>{componentA}</div>
    </div>
  )
}
