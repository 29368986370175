import { IContactsState, IContactsItem } from "./contacts.types.ts";

export const contactsInitialState: IContactsState = {
  title_color: "",
  title_dark: "",
  contact: "",
  zoom: "",
  point_title: "",
  text: "",
  coordinates: [] as string[],
  point_coordinates: [] as string[],
};
