import { createSlice } from '@reduxjs/toolkit';
import { ServicesReducers } from './Services.actions.ts';
import { ServicesInitialState } from './Services.state.ts';
import { ServicesThunksCallbacksAsync } from './../logic/Services.thunks.async.ts';
import { ServicesThunksCallbacksSync } from './../logic/Services.thunks.sync.ts';
import { createThunksForExport } from '../../../../store/store.services.ts';
import { RootState } from '../../../../store/store.ts';

export const ServicesThunks: any = createThunksForExport({
  async: ServicesThunksCallbacksAsync,
  sync: ServicesThunksCallbacksSync
})

export const ServicesDataSlice = createSlice({
  name: 'ServicesData',
  initialState: ServicesInitialState,
  reducers: ServicesReducers
})

export const ServicesStaticData = (state: RootState) =>
  state.ServicesData;

export const ServicesActions = ServicesDataSlice.actions;

export default ServicesDataSlice.reducer;
