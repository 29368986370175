import React from 'react';
import styles from './teamHeader.module.css';
import { useAppSelector } from '../../../../hooks/hooksRedux.ts';

const DBInfo = {
    description: `Наши специалисты являются главной причиной почему нам
    доверяют грузы и рекомендуют нашу компанию`
}
export const TeamHeader = () => {
    const { title_color, title_dark, description } = useAppSelector(state => state.teamData.teamPageDescription);
    return (
        <>
            <div className={styles.title}>
                <p>{title_color}</p>
                {/* <p>Команда</p> */}
                <p className={styles.dark}>{title_dark}</p>
                {/* <p className={styles.dark}>Профессионалов</p> */}
            </div>

            <div className={styles.team_description}>
                {
                    <>{description?.split("\n").map((el, i) => {
                        if (el === '') {
                            return <br />
                        }
                        return <p key={i + Math.random()}>{el}</p>;
                    })}
                    </>
                }
            </div>
            {/* <div className={styles.tag}>
                <p>{servicePageDescription.tag}</p>
            </div> */}
        </>
    )
}
