import { PayloadAction } from "@reduxjs/toolkit";
import { IProjectsItem, IProjectsState } from "./projects.types.ts";

function setProjectsState(
  state: IProjectsState,
  { payload: newState }: PayloadAction<IProjectsState>
) {
  return newState;
}
function setSlideNextEvent(
  state: IProjectsState,
  { payload: slideNextEvent }: PayloadAction<boolean>
) {
  state.slideNextEvent = slideNextEvent;
}
function setSlidePrevEvent(
  state: IProjectsState,
  { payload: slidePrevEvent }: PayloadAction<boolean>
) {
  state.slidePrevEvent = slidePrevEvent;
}

function setFetched(
  state: IProjectsState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

function setSelectedItem(
  state: IProjectsState,
  { payload: selectedItem }: PayloadAction<IProjectsItem>
) {
  state.selectedItem = selectedItem;
}

function setOpenProject(
  state: IProjectsState,
  { payload: openProject }: PayloadAction<boolean>
) {
  state.openProject = openProject;
}

export const projectsReducers = {
  setProjectsState,
  setSlideNextEvent,
  setSlidePrevEvent,
  setFetched,
  setSelectedItem,
  setOpenProject,
};
