import { PayloadAction } from "@reduxjs/toolkit";
import { ICustomsState } from "./customs.types";

function setCustomsState(
  state: ICustomsState,
  { payload: newState }: PayloadAction<ICustomsState>
) {
  return newState;
}

function setFetched(
  state: ICustomsState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const customsReducers = {
  setCustomsState,
  setFetched,
};
