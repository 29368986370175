import { PayloadAction } from "@reduxjs/toolkit";
import { IAgencyState } from "./agency.types";

function setAgencyState(
  state: IAgencyState,
  { payload: newState }: PayloadAction<IAgencyState>
) {
  return newState;
}
function setSlideNextEvent(
  state: IAgencyState,
  { payload: slideNextEvent }: PayloadAction<boolean>
) {
  state.slideNextEvent = slideNextEvent;
}
function setSlidePrevEvent(
  state: IAgencyState,
  { payload: slidePrevEvent }: PayloadAction<boolean>
) {
  state.slidePrevEvent = slidePrevEvent;
}

function setFetched(
  state: IAgencyState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}


export const agencyReducers = {
  setAgencyState,
  setSlideNextEvent,
  setSlidePrevEvent,
  setFetched
};
