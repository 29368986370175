import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import { IContactsConfig, IContactsState } from "../state/contacts.types.ts";
import { contactsActions } from "../state/contacts.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let contactsConfig: IContactsConfig = require("./../state/contacts.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().contactsData.fetched) return;
  thunkApi.dispatch(contactsActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(contactsConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  console.log({ info });

  const contactsState: IContactsState = {
    title_color: info.title_color,
    title_dark: info.title_dark,
    contact: info.contact,
    zoom: info.zoom,
    point_title: info.point_title,
    text: info.text,
    coordinates: info.coordinates,
    point_coordinates: info.point_coordinates,
  };

  thunkApi.dispatch(contactsActions.setContactsState(contactsState));
};

const emitSlideNext = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(contactsActions.setSlideNextEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(contactsActions.setSlideNextEvent(false));
  // }, 500);
};

const emitSlidePrev = async (_: any, thunkApi: any) => {
  await thunkApi.dispatch(contactsActions.setSlidePrevEvent(true));
  // setTimeout(() => {
  //   thunkApi.dispatch(contactsActions.setSlidePrevEvent(false));
  // }, 500);
};

export const contactsExtraReducers = {
  "thunk/fetchInfo/pending": (state: IContactsState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: IContactsState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: IContactsState) => {
    console.log("rejected");
  },
};

export const contactsThunksCallbacksAsync = {
  fetchInfo,
  emitSlideNext,
  emitSlidePrev,
};
