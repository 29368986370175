import { AppThunk } from "../../../../store/store.ts";
import { contactsActions } from "../state/contacts.slice.ts";
import { IContactsState } from "../state/contacts.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(contactsActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(contactsActions.setSlidePrevEvent(false));
};

export const contactsThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
};
