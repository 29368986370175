import { React, useRef } from "react";
import "./LamaText.css";

export const LamaText = (
  { l_ref, l_style, l_title, l_placeholder, l_type, l_disabled} = {
    l_ref: {current: null},
    l_style: {},
    l_title: "",
    l_placeholder: "",
    l_type: "text",
    l_disabled: false,
  }
) => {

  return (
    <main className="l-text">
      <p>{l_title}:</p>
      <input
        type={l_type}
        ref={l_ref}
        style={l_style}
        placeholder={l_placeholder}
        disabled={l_disabled}
      />
    </main>
  );
};
