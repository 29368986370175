import { AppThunk } from "../../../../store/store.ts";
import { agencyActions } from "../state/agency.slice.ts";
import { IAgencyState } from "../state/agency.types.ts";

const resetSlideNextEvent = (): AppThunk => (dispatch) => {
  dispatch(agencyActions.setSlideNextEvent(false));
};
const resetSlidePrevEvent = (): AppThunk => (dispatch) => {
  dispatch(agencyActions.setSlidePrevEvent(false));
};

export const agencyThunksCallbacksSync = {
  resetSlideNextEvent,
  resetSlidePrevEvent,
};
