import "./HeaderLinkButton.css";
import { useAppDispatch } from "./../../hooks/hooksRedux.ts";
import { sliderThunks } from "../../plugins/slider/state/slider.slice.ts";

const HeaderLinkButton = ({
  text,
  link,
  redirect,
  active,
  setActive,
  setBackground,
  type,
  toggleFeedback,
  style,
}) => {
  const dispatch = useAppDispatch();
  // const onclick = () => {
  //   if (type === 'feedback') {
  //     toggleFeedback();
  //     return;
  //   }
  //   dispatch(sliderThunks.slide({ direction: 'next', key: link, button: true }));
  // }
  return (
    <a href={link}>
      <div
        style={style ? style : {}}
        className={`header-button ${
          active === link ? "header-button--active" : ""
        } ${type === "feedback" ? "header-button--feedback" : ""}`}
      >
        <p>{text}</p>
      </div>
    </a>
  );
};

export default HeaderLinkButton;
