import axios from "axios";
import { fetchDataFromDB } from "./../../../../api/api.js";
import {
  IServiceDescriptions,
  IServicePageDescription,
  IServicesConfig,
  IServicesState,
} from "../state/Services.types";
import { ServicesActions } from "./../state/Services.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let ServicesConfig: IServicesConfig = require("./../state/Services.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().ServicesData.fetched) return;
  thunkApi.dispatch(ServicesActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(ServicesConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  const serviceDescriptions: IServiceDescriptions = {
    auto: {
      name: info.name_1,
      title: info.title_1,
      text: info.description_1,
    },
    air: {
      name: info.name_2,
      title: info.title_2,
      text: info.description_2,
    },
    ship: {
      name: info.name_3,
      title: info.title_3,
      text: info.description_3,
    },
    rail: {
      name: info.name_4,
      title: info.title_4,
      text: info.description_4,
    },
    multi: {
      name: info.name_5,
      title: info.title_5,
      text: info.description_5,
    },
    projects: {
      name: info.name_6,
      title: info.title_6,
      text: info.description_6,
    },
    customs: {
      name: info.name_7,
      title: info.title_7,
      text: info.description_7,
    },
    warehouse: {
      name: info.name_8,
      title: info.title_8,
      text: info.description_8,
    },
  };

  thunkApi.dispatch(
    ServicesActions.setServiceDescriptions(serviceDescriptions)
  );

  const servicePageDescription: IServicePageDescription = {
    tag: info.tag,
    title_color: info.title_color,
    title_dark: info.title_dark,
    contact: info.contact,
  };

  console.log({ servicePageDescription });
  thunkApi.dispatch(
    ServicesActions.setServicePageDescription(servicePageDescription)
  );
};

export const ServicesExtraReducers = {
  "thunk/fetchInfo/pending": (state: IServicesState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: IServicesState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: IServicesState) => {
    console.log("rejected");
  },
};

export const ServicesThunksCallbacksAsync = {
  fetchInfo,
};
