import { createSlice } from "@reduxjs/toolkit";
import { geoReducers } from "./geo.actions.ts";
import { geoInitialState } from "./geo.state.ts";
import { geoThunksCallbacksAsync } from "../logic/geo.thunks.async.ts";
import { geoThunksCallbacksSync } from "../logic/geo.thunks.sync.ts";
import { createThunksForExport } from "../../../../store/store.services.ts";
import { RootState } from "../../../../store/store.ts";

export const geoThunks: any = createThunksForExport({
  async: geoThunksCallbacksAsync,
  sync: geoThunksCallbacksSync,
});

export const geoDataSlice = createSlice({
  name: "geoData",
  initialState: geoInitialState,
  reducers: geoReducers,
});

export const geoStaticData = (state: RootState) => state.geoData;

export const geoActions = geoDataSlice.actions;

export default geoDataSlice.reducer;
