import React from 'react'
import './LamaPoint.css'


export const LamaPoint = ({edit, x, y, id, opened, type}) => {
  return (
    <div style={{left: x, top: y, width: opened ? '20px' : '10px', height: opened ? '20px' : '10px'}} onClick={()=>{edit(id)}} className='l-map-point'>
        <img src={`Geography/${type}-point.svg`} alt="" />
    </div>
  )
}
