export interface IAdaptiveState {
  widthBreakpoint: Breakpoint;
  heightBreakpoint: Breakpoint;
  maxWidths: IMaxWidths;
  maxHeight: IMaxHeights;
}

export interface IMaxWidths {
  [BreakpointsEnum.lg]: number;
  [BreakpointsEnum.md]: number;
  [BreakpointsEnum.sm]: number;
  [BreakpointsEnum.xs]: number;
}
export interface IMaxHeights {
  [BreakpointsEnum.lg]: number;
  [BreakpointsEnum.md]: number;
  [BreakpointsEnum.sm]: number;
  [BreakpointsEnum.xs]: number;
}

export interface IHeightWidths {
  [BreakpointsEnum.lg]: number;
  [BreakpointsEnum.md]: number;
  [BreakpointsEnum.sm]: number;
  [BreakpointsEnum.xs]: number;
}

export type Breakpoint =
  | BreakpointsEnum.lg
  | BreakpointsEnum.md
  | BreakpointsEnum.sm
  | BreakpointsEnum.xs;

export enum BreakpointsEnum {
  lg = "lg",
  md = "md",
  sm = "sm",
  xs = "xs",
}
