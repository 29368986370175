import React from 'react';
import styles from './geoMenu.module.css';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooksRedux.ts';
import { geoThunks } from '../state/geo.slice.ts';
import { classes } from '../../../../libs/classname';

export const GeoMenu = () => {
    const dispatch = useAppDispatch();

    const items = useAppSelector(state => state.geoData.items);

    const selected = useAppSelector(state => state.geoData.selectedSectionName);

    return (
        <div className={styles.buttons}>
            <div className={styles.tabs}>
                <button key='1' className={classes([styles.tab, selected === 'train' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('train')) }}>
                    <img className={styles.icon} src={`desktop/geo/train-icon${selected === 'train' ? '-selected' : ''}.svg`} />
                </button>
                <button key='2' className={classes([styles.tab, selected === 'ship' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('ship')) }}>
                    <img className={styles.icon} src={`desktop/geo/ship-icon${selected === 'ship' ? '-selected' : ''}.svg`} />
                </button>
                <button key='4' className={classes([styles.tab, selected === 'fly' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('fly')) }}>
                    <img className={styles.icon} src={`desktop/geo/fly-icon${selected === 'fly' ? '-selected' : ''}.svg`} />
                </button>
                <button key='3' className={classes([styles.tab, selected === 'auto' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('auto')) }}>
                    <img className={styles.icon} src={`desktop/geo/auto-icon${selected === 'auto' ? '-selected' : ''}.svg`} />
                </button>
            </div>
            <div className={styles.content}>
                <div className={styles.text}>
                    <p className={styles.title}>{items[selected]?.title}</p>
                    <p className={styles.description}>{items[selected]?.description}</p>
                </div>
            </div>
            {/* <button className={classes([styles.button, selected === 'train' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('train')) }}>

                <div className={styles.card}>
                    <img className={styles.icon} src={`desktop/geo/train-icon${selected === 'train' ? '-selected' : ''}.svg`} />
                    <div className={styles.text}>
                        <p className={styles.title}>{train?.title}</p>
                        {selected === 'train' ? <p className={styles.description}>{train?.description}</p> : null}
                    </div>
                </div>

            </button>
            <button className={classes([styles.button, selected === 'ship' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('ship')) }}>
                <div className={styles.card}>
                    <img className={styles.icon} src={`desktop/geo/ship-icon${selected === 'ship' ? '-selected' : ''}.svg`} />
                    <div className={styles.text}>
                        <p className={styles.title}>{ship?.title}</p>
                        {selected === 'ship' ? <p className={styles.description}>{ship?.description}</p> : null}
                    </div>
                </div>

            </button>
            <button className={classes([styles.button, selected === 'fly' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('fly')) }}>
                <div className={styles.card}>
                    <img className={styles.icon} src={`desktop/geo/fly-icon${selected === 'fly' ? '-selected' : ''}.svg`} />
                    <div className={styles.text}>
                        <p className={styles.title}>{fly?.title}</p>
                        {selected === 'fly' ? <p className={styles.description}>{fly?.description}</p> : null}
                    </div>
                </div>

            </button>
            <button className={classes([styles.button, selected === 'auto' ? styles.selected : ''])} onClick={() => { dispatch(geoThunks.selectSection('auto')) }}>
                <div className={styles.card}>
                    <img className={styles.icon} src={`desktop/geo/auto-icon${selected === 'auto' ? '-selected' : ''}.svg`} />
                    <div className={styles.text}>
                        <p className={styles.title}>{auto?.title}</p>
                        {selected === 'auto' ? <p className={styles.description}>{auto?.description}</p> : null}
                    </div>
                </div>

            </button> */}
        </div>
    )
}
