import { PayloadAction } from "@reduxjs/toolkit";
import { IMainState, IMainInfo } from "./main.types.ts";

function setTag(state: IMainState, { payload: tag }: PayloadAction<string>) {
  state.tag = tag;
}
function setTitle(
  state: IMainState,
  { payload: title }: PayloadAction<string>
) {
  state.title = title;
}
function setFirstInfo(
  state: IMainState,
  { payload: firstInfo }: PayloadAction<IMainInfo>
) {
  state.firstInfo = firstInfo;
}
function setSecondInfo(
  state: IMainState,
  { payload: secondInfo }: PayloadAction<IMainInfo>
) {
  state.secondInfo = secondInfo;
}

function setFetched(
  state: IMainState,
  { payload: fetched }: PayloadAction<boolean>
) {
  state.fetched = fetched;
}

export const mainReducers = {
  setTag,
  setTitle,
  setFirstInfo,
  setSecondInfo,
  setFetched,
};
