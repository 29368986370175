import React, { useMemo } from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./AdminImages.css";
import { LamaText } from "../components/Inputs/LamaText";
import { AdminContentPage } from "./AdminContentPage";
import LamaImage from "../components/Inputs/LamaImage";

export const AdminImages = () => {

  const [file, setFile] = useState();

  const [imageObjects, setImageObjects] = useState([]);

  const fetchImages = () => {
    axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/server_images/all`).then((res) => {
      setImages(res?.data);
    });
  }
  useEffect(() => {
    fetchImages();
  }, []);

  const [images, setImages] = useState([]);
  useEffect(() => {
    if (!images) {
      axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/server_images/all`).then((res) => {
        setImages(res?.data);
      });
      return;
    }
    setImageObjects(images.map((image, index) => {
      const name = image;
      return {
        id: index,
        oldName: name,
        newName: name
      }
    }))
  }, [images])

  const changeName = (id, name) => {
    imageObjects.forEach(image => {
      if (image.id === id) {
        image.newName = name;
      }
    })
  }

  const upload = () => {

    if (!file) return;

    const FormData = require('form-data');
    const data = new FormData();
    data.append('file', file);

    const config = {
      method: 'post',
      url: window?.SERVER_SETTINGS?.SERVER_URL + '/upload',
      headers: {},
      data: data
    };

    setProgress(true)
    axios(config)
      .then(function (response) {
        setProgress(false);
        setSuccess(true)
        setImages([]);
        axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/server_images/all`).then((res) => {
          setImages(res?.data);
        });
      })
      .catch(function (error) {
        setProgress(false);
        setError(true)
      });
  }

  const rename = async (imageObject) => {
    if (imageObject.oldName === '') return;
    const oldName = imageObject.oldName;
    let newNameArray = [...imageObject.newName].filter(char => {
      return 'abcdefghijklmnopqrstuvwxyz0123456789_-.'.includes(char.toLowerCase());
    });
    const newName = newNameArray.join('');
    // await axios.patch(`${window?.SERVER_SETTINGS?.SERVER_URL}/server_images/${oldName}/${newName}`);

    var config = {
      method: 'get',
      url: window?.SERVER_SETTINGS?.SERVER_URL + '/server_images_rename/' + oldName + '/' + newName,
      headers: {}
    };

    setProgress(true);
    axios(config)
      .then(function (response) {
        setProgress(false);
        setSuccess(true)
        setImages();

        // imageObject.oldName = newNameArray.join('');
        // const inputs = document.getElementsByClassName('a-images-image-input');
        // console.log({inputs});
        // if (!inputs) return;
        // [...inputs][imageObject.id].value = newNameArray.join('');
      })
      .catch(function (error) {
        setProgress(false);
        setError(true)
        // imageObject.src = oldName;
        // const inputs = document.getElementsByClassName('a-images-image-input');
        // console.log({inputs});
        // if (!inputs) return;
        // [...inputs][imageObject.id].value = imageValues.current[imageObject.id].src;
      });


  }

  const remove = async (imageObject) => {
    const oldName = imageObject.oldName;
    setProgress(true);
    await axios.get(`${window?.SERVER_SETTINGS?.SERVER_URL}/server_images_delete/${oldName}`)
      .then(function (response) {
        setProgress(false);
        setSuccess(true)
        setImages();
      })
      .catch(function (error) {
        setProgress(false);
        setError(true)
      });
  }




  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const showSuccess = () => {
    setProgress(false);
    if (success) return;
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const showError = () => {
    setProgress(false);
    if (error) return;
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };






  return (
    <AdminContentPage
      title="Шапка сайта"
      progress={progress}
      success={success}
      warning={warning}
      error={error}
    // save={save}
    >
      <div className="a-images-upload">
        <LamaImage setFile={setFile} />
        <button className="a-images-upload-button" onClick={upload}>Загрузить на сервер</button>
      </div>

      {
        imageObjects.map((imageObject) => {
          console.log('remap');
          return (
            <div className="a-images-image-container" key={imageObject.oldName}>
              <div className="a-images-image-img-container">
                <img className="a-images-image-img" src={window?.SERVER_SETTINGS?.SERVER_URL + '/server_images/' + imageObject.oldName} />
              </div>
              <div className="a-images-image-actions">
                <input className="a-images-image-input" type='text' defaultValue={imageObject.oldName} onChange={(e) => { changeName(imageObject.id, e.target.value) }} l_title={"Название изображения"} />
                <button className="a-images-image-button" onClick={rename.bind(null, imageObject)}>Переименовать</button>
                <button className="a-images-image-error-button" onClick={remove.bind(null, imageObject)}>Удалить</button>
              </div>
            </div>)
        })}


    </AdminContentPage>
  );
};