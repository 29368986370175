import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import ServicesDataReducer from "./../views/desktop/services/state/Services.slice.ts";
import sliderDataReducer from "./../plugins/slider/state/slider.slice.ts";
import adaptiveDataReducer from "./../plugins/adaptive/state/adaptive.slice.ts";
import mainDataReducer from "./../views/desktop/main/state/main.slice.ts";
import teamDataReducer from "./../views/desktop/team/state/team.slice.ts";
import areasDataReducer from "./../views/desktop/areas/state/areas.slice.ts";
import geoDataReducer from "./../views/desktop/geo/state/geo.slice.ts";
import projectsDataReducer from "./../views/desktop/projects/state/projects.slice.ts";
import customsDataReducer from "./../views/desktop/customs/state/customs.slice.ts";
import agencyDataReducer from "./../views/desktop/agency/state/agency.slice.ts";
import divisionDataReducer from "./../views/desktop/division/state/division.slice.ts";
import chinaDataReducer from "./../views/desktop/china/state/china.slice.ts";
import contactsDataReducer from "./../views/desktop/contacts/state/contacts.slice.ts";
import feedbackDataReducer from "./../components/Feedback/state/feedback.slice.ts";
import warningDataReducer from "./../views/desktop/warning/state/warning.slice.ts";
//DO_NOT_DELETE_THIS_STRING__IMPORT

export const store = configureStore({
  reducer: {
    ServicesData: ServicesDataReducer,
    sliderData: sliderDataReducer,
    adaptiveData: adaptiveDataReducer,
    mainData: mainDataReducer,
    teamData: teamDataReducer,
    areasData: areasDataReducer,
    geoData: geoDataReducer,
    projectsData: projectsDataReducer,
    customsData: customsDataReducer,
    agencyData: agencyDataReducer,
    divisionData: divisionDataReducer,
    chinaData: chinaDataReducer,
    contactsData: contactsDataReducer,
    feedbackData: feedbackDataReducer,
    warningData: warningDataReducer,
    //DO_NOT_DELETE_THIS_STRING__REDUCER
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
