import React from 'react';
import { MobileHeaderItem } from './MobileHeaderItem';
import './MobileHeaderMenu.css';

export const MobileHeaderMenu = ({ items, setIsShowMenu }) => {
  return (
    <div className='m-header-menu'>
      {items.map(item => <MobileHeaderItem key={item.key} title={item.title} scroll={item.scroll} setIsShowMenu={setIsShowMenu} />)}
    </div>
  )
}
