import { AppThunk } from "../../../../store/store.ts";
import { feedbackActions } from "../state/feedback.slice.ts";
import { IFeedbackState } from "../state/feedback.types.ts";

const openFeedback = (): AppThunk => (dispatch) => {
  dispatch(feedbackActions.setVariant("normal"));
  dispatch(feedbackActions.setShow(true));
};

const openTeamFeedback = (): AppThunk => (dispatch) => {
  dispatch(feedbackActions.setVariant("team"));
  dispatch(feedbackActions.setShow(true));
};

const closeFeedback = (): AppThunk => (dispatch) => {
  dispatch(feedbackActions.setShow(false));
};

export const feedbackThunksCallbacksSync = {
  openFeedback,
  openTeamFeedback,
  closeFeedback,
};
