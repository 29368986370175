import axios from "axios";
import { fetchDataFromDB } from "../../../../api/api.js";
import {
  ITeamConfig,
  ITeamPageDescription,
  ITeamState,
} from "../state/team.types";
import { teamActions } from "./../state/team.slice.ts";
import { DBResponse } from "./../../../../store/store.types.ts";

let teamConfig: ITeamConfig = require("./../state/team.config.json");

const fetchInfo = async (_: any, thunkApi: any) => {
  if (thunkApi.getState().teamData.fetched) return;
  thunkApi.dispatch(teamActions.setFetched(true));

  const response: DBResponse = await fetchDataFromDB(teamConfig.DBKey);
  if (!response.status) {
    console.log(response.error);
    return;
  }
  const info: any = response.info;

  const teamPageDescription: ITeamPageDescription = {
    title_color: info.title_color,
    title_dark: info.title_dark,
    description: info.description,
    slider: info.sliderInfo,
    contact: info.contact,
  };

  teamPageDescription.slider = info.sliderInfo.map((item) => ({
    name: item.title,
    position: item.position,
    bio: item.text,
    src: item.image,
  }));

  thunkApi.dispatch(teamActions.setTeamDescriptions(teamPageDescription));
};

export const teamExtraReducers = {
  "thunk/fetchInfo/pending": (state: ITeamState) => {
    console.log("pending");
  },
  "thunk/fetchInfo/fulfilled": (state: ITeamState) => {
    console.log("fulfilled");
  },
  "thunk/fetchInfo/rejected": (state: ITeamState) => {
    console.log("rejected");
  },
};

export const teamThunksCallbacksAsync = {
  fetchInfo,
};
