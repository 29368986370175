import { createSlice } from "@reduxjs/toolkit";
import { feedbackReducers } from "./feedback.actions.ts";
import { feedbackInitialState } from "./feedback.state.ts";
import { feedbackThunksCallbacksAsync } from "./../logic/feedback.thunks.async.ts";
import { feedbackThunksCallbacksSync } from "./../logic/feedback.thunks.sync.ts";
import { createThunksForExport } from "./../../../store/store.services.ts";
import { RootState } from "./../../../store/store.ts";

export const feedbackThunks: any = createThunksForExport({
  async: feedbackThunksCallbacksAsync,
  sync: feedbackThunksCallbacksSync,
});

export const feedbackDataSlice = createSlice({
  name: "feedbackData",
  initialState: feedbackInitialState,
  reducers: feedbackReducers,
});

export const feedbackStaticData = (state: RootState) => state.feedbackData;

export const feedbackActions = feedbackDataSlice.actions;

export default feedbackDataSlice.reducer;
