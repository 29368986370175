import { AppThunk } from "../../../../store/store.ts";
import { ServicesActions } from "../state/Services.slice.ts";
import { IServicesState } from "../state/Services.types.ts";

const toggleFeedback =
  (messages: any): AppThunk =>
  (dispatch, getState) => {
    const isShowFeedback: boolean = (getState().ServicesData as IServicesState)
      .isShowFeedback;
    dispatch(ServicesActions.setIsShowFeedback(!isShowFeedback));
  };

export const ServicesThunksCallbacksSync = {
  toggleFeedback,
};
