import {React, useState, useEffect, useRef} from 'react'
import { LamaSliderItem } from './LamaSliderItem';

export const LamaSliderList = ({getResults, setSliderInfo, sliderInfo}) => {


    useEffect(()=>{
        getResults.current = getValues
        setSliderInfo.current = setProj;
    },[])

    const setProj = () => {
        // console.log(sliderInfo);
        sliderInfo.current.forEach(el=>{
            const id = list.current.length === 0 ? 1 : list.current[list.current.length - 1].props.id + 1;
            list.current.push((
                <LamaSliderItem
                    key={Math.random() * Math.random()}
                    values={values}
                    title={el.title}
                    position={el.position}
                    image={el.image}
                    text={el.text}
                    id={id}
                    remove={()=>{remove(id)}}/>
            ))
        })
        setListItems(prev=>[...prev, 1]);
    }

    const values = useRef([]);

    const remove = function(id) {
        // console.log({id});
        list.current = list.current.filter((el)=>el.props.id !== id);
        setListItems(prev=>[...prev, 1]);
    }

    const list = useRef(
        []
    );

    const [listItems, setListItems] = useState([
    ]);

    const [getValuesFuncs, setGetValuesFuncs] = useState([]);



    const add = () => {
        const id = list.current.length === 0 ? 1 : list.current[list.current.length - 1].props.id + 1;
        list.current.push(
            (
                <LamaSliderItem 
                    key={Math.random() * Math.random()}
                    values={values}
                    id={id}
                    remove={()=>{remove(id)}}/>
            ))
            setListItems(prev=>[...prev, 1])
    }

    useEffect(()=>{
        // console.log(listItems);
    },[listItems])

    const getValues = () => {
        const result = [];

        for (let i = 0; i < values.current.length; i++) {
            const el = values.current[i];
            const res = el();

            if (res===null) {
                continue;
            };

            result.push(res);
        }
        
        // console.log(result);
        return result;
    }


  return (
    <>
        <div className='l-list-items'>
            {
               listItems && list.current
            }
        </div>
        <button onClick={add}>Добавить сотрудника</button>
        {/* <button onClick={()=>{// console.log(getValues, getValues())}}>Собрать инфу</button> */}
    
    </>
  )
}
