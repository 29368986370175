import React from 'react';
import styles from './serviceHeader.module.css';
import { useAppSelector } from '../../../../hooks/hooksRedux.ts';

export const ServiceHeader = () => {
    const { servicePageDescription } = useAppSelector(state => state.ServicesData);
    return (
        <>
            <div className={styles.tag}>
                <p>{servicePageDescription.tag}</p>
            </div>
            <div className={styles.title}>
                <p>{servicePageDescription.title_color}</p>
                <p className={styles.dark}>{servicePageDescription.title_dark}</p>
            </div>
        </>
    )
}
